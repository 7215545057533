import React from "react";
import HallOfFameCategoryBox from "./hallOfFameCategoryBox";
import HallOfFameUserModal from "./hallOfFameUserModal";

const header = (
  <div
    className="header"
    style={{ background: "radial-gradient(#e1e1e1,#b4aad3)" }}
  >
    OYUN 2022 Şeref Kürsüsü
  </div>
);

const minikler2022 = [
  {
    result: "Minikler Birincisi",
    name: "Zeynep Reyyan BAŞDAŞ",
    medal: 1,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm1%20zeynep%20reyyan.jpeg?alt=media&token=6e89fc7b-aeda-4029-bd0a-a87c69b32995",
    about:
      "8 yaşındayım. İzmir’de yaşıyorum. Bornova Kars Halil Atila ilkokulu 3. Sınıf öğrencisiyim.",
    about2:
      "8 yaşındayım. İzmir’de yaşıyorum. Bornova Kars Halil Atila ilkokulu 3. Sınıf öğrencisiyim. En sevdiğim ders Matematik. Büyüyünce Bilim İnsanı olmak, tüm canlılar için faydalı şeyler üretmek istiyorum. Hobilerim, oyun oynamak, hayal kurmak, paten kaymak, scooter sürmek, bisiklet sürmek, İngilizce kitap okumak. Piyano çalmaktan, doğa yürüyüşleri yapmaktan, yeni yerler görmek için seyahat etmekten hoşlanıyorum. TZV Oyun yarışmalarına her yıl katılacağım. Çok eğleniyorum, çok seviyorum. Bu sorular çok eğlenceli. Bu güzel etkinliği düzenleyen Emrehan HALICI ve ekibine teşekkür ederim. Ayrıca öğretmenim Esra ÇERÇEL KARA’ ya ve okul yöneticilerime teşekkür ederim.",
  },
  {
    result: "Minikler İkincisi",
    name: "Yiğit Ali Dağdelen",
    medal: 2,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm2%20Yi%C4%9Fit%20Ali%20DA%C4%9EDELEN.jpg?alt=media&token=2c7b875a-40bd-406d-94d6-c1a0386c99dd",
    about: `Adım Yiğit Ali DAĞDELEN. 7 Mayıs 2014 tarihinde Bursa’da doğdum. 8 yaşındayım. Bursa’da Potansiyel Yetenekliler...`,
    about2: `Adım Yiğit Ali DAĞDELEN. 7 Mayıs 2014 tarihinde Bursa’da doğdum. 8 yaşındayım. Bursa’da Potansiyel Yetenekliler İlkokulu’nda 2. Sınıfı bitirdim. Aynı zamanda bu yıl genel yetenek alanında Nilüfer Halil İnalcık Bilim ve Sanat Merkezi’ne başlayacağım. Satranç ve futbol oynamayı çok seviyorum. Okuma yazmayı kendi kendime öğrendim. Farklı diller öğrenmeye özellikle Rusça ve Çinceye meraklıyım. Aynı zamanda piyano çalıyorum. Piyano eğitimim devam ediyor. Komik kitaplar okurken çok eğleniyorum. Hobilerim bilgisayarda strateji oyunları oynamak ve arkadaşlarımla dışarıda eğlenmek. Uzayda ne olup bittiğini çok merak ediyorum. Olmak istediğim meslek ise "astronot".`,
  },
  {
    result: "Minikler Üçüncüsü",
    name: "Ali Binboğa",
    medal: 3,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm3%20Ali%20binboga.png?alt=media&token=cf79d6b1-61ab-4d21-920b-eaa1e5a835f1",
    about: `7 Haziran 2016'da Sivas'ta doğdum. Memleketim Kahramanmaraş'tır. Şu an Nevşehir ilinde yaşıyorum. Bu yarışmaya Nevşehir Vilayetler Birliği...`,
    about2: `7 Haziran 2016'da Sivas'ta doğdum. Memleketim Kahramanmaraş'tır. Şu an Nevşehir ilinde yaşıyorum. Bu yarışmaya Nevşehir vilayetler Birliği anaokulunda ki Emine ve Nilgün öğretmenlerimin yönlendirmesi ile katıldım ve derece elde ettim. Kitapların ve oyuncakların en sevdiğim arkadaşlarımdır. Zeka oyunlarına ve bilim teknoloji i alanlarına karşı çok meraklıyım ve zeka oyunlarını oynamayı çok seviyorum. Şuan Hacı Asım Atabilen İlköğretim Okulu 1 sınıf öğrencisiyim. Bundan sonra da yarışmalarınızın takipçisi olacağım ve katılacağım. Her şey için çok teşekkür ederim.`,
  },
  {
    result: "Minikler Dördüncüsü",
    name: "Mazhar Ali TOPÇU",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm4%20Mazhar%20Ali%20Top%C3%A7u.png?alt=media&token=927f7011-8145-42a8-827d-33fc25dcedd2",
    about: `10 Kasım 2014 tarihinde Bursa'da doğdum. Tekirdağ'ın Şarköy ilçesinde yaşıyorum.`,
    about2: `10 Kasım 2014 tarihinde Bursa'da doğdum. Tekirdağ'ın Şarköy ilçesinde yaşıyorum. Bu yıl Şarköy Cumhuriyet İlkokulu'nda 
    3. sınıfı okuyacağım. Annem, babam ve üç kedimle birlikte yaşıyorum.  3 yıldır satranç ile ilgileniyorum, imkan buldukça
    satranç turnuvalarına katılıyorum. Hedefim; milli takıma girmek! Ayrıca 3 yıl kickboks sporuyla ilgilendim, birinci derece
    koyu kahverengi kuşağım var. Bu yaz futbol okuluna da katıldım. Futbol oynamaktan ve izlemekten çok keyif alıyorum. Koyu bir 
    Fenerbahçe taraftarıyım, maçlarını takip ediyorum. Hayvanları seviyor, onların yaşam haklarına saygı duyuyorum ve daha sağlıklı
    bir yaşam sürdürmeleri için annem ve babam ile elimizden geleni sevgiyle yapıyorum. Doğada vakit geçirmeyi, yüzmeyi, kitap okumayı
    ve şarkı dinlemeyi çok seviyorum. En sevdiğim çizgi film: "Kral Şakir". Matematik'e, İngilizce'ye ve Uzay Bilimleri'ne meraklıyım. 
    BİLSEM mülakatlarını geçerek Tekirdağ BİLSEM'e kayıt olma hakkı kazandım. Gelecekte Uluslararası Uzay İstasyonu'nda bir Türk mühendis
    olarak çalışmayı hayal ediyor ve ülkemizi en iyi şekilde temsil etmek istiyorum. 
    27. Zeka Oyunları Yarışması'nın düzenlenmesinde emeği geçen tüm büyüklerime teşekkür ediyorum.`,
  },
  {
    result: "Minikler Dördüncüsü",
    name: "Eylül Öztürk",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm4%20Eyl%C3%BCl%20%C3%96zt%C3%BCrk%20foto.png?alt=media&token=a5f80fc3-9492-4c22-8d86-156aa380a311",
    about:
      "Merhabalar, Adım Eylül ÖZTÜRK. 02 Nisan 2014 tarihinde İstanbul’da doğdum. Akademisyen annem...",
    about2:
      "Merhabalar, Adım Eylül ÖZTÜRK. 02 Nisan 2014 tarihinde İstanbul’da doğdum. Akademisyen annem ve yönetici babam ile birlikte Kadıköy ilçesinde yaşamaktayım. İlkokul eğitimime 2020 yılında Koşuyolu Fidol İlkokulunda başladım ve şu an itibariyle 3. sınıfa geçmiş bulunmaktayım. Okulumu çok seviyorum. Kitap okumak en sevdiğim hobilerimin başında yer alır. Ayrıca zeka oyunları oynamaktan ve akıl-mantık soruları çözmekten çok zevk alırım. Annem ve babam da bu konuda bana çok destek olmaktadırlar ve bu tarz yarışmalara katılım sağlamaya devam edeceğim. Bunun dışında okulumda tiyatro, okçuluk, modern dans ve judo gibi farklı kurslara katıldım ve devam ediyorum. İleride “Bilim İnsanı” olmak istiyorum. Her zaman yaratıcı olmayı tercih ediyorum. Özellikle de pandemi döneminde biz çocuklar oyun oynamada kısıtlı kaldık. Her hafta zoom üzerinden yeni oyunlar yaratmayı ve kurmayı tecrübe ettiğim “Bi Oyun Kur” platformuna 1 yıl katılım gösterip usta belgesini almaya hak kazandım. Türk Zeka Vakfına bu tarz yarışmalar düzenlediği için çok teşekkür ediyorum saygılarımı sunuyorum. ",
  },
  {
    result: "Minikler Dördüncüsü",
    name: "Nadım Inan",
    medal: 4,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Minikler Dördüncüsü",
    name: "Can Tuna Özer",
    medal: 4,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Minikler Beşincisi",
    name: "ALİ KEREM YANAR",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm5%20ali%20kerem%20yanar.jpg?alt=media&token=ad2d9fc7-a8b5-47c9-9664-ba9a86181743",
    about:
      "24 Mayis 2014 Eskişehir doğumluyum.Özel Etkin Kolej Ilkokulu'nda okuyorum. 3. Sınıfa geçtim...",
    about2:
      "24 Mayis 2014 Eskişehir doğumluyum.Özel Etkin Kolej Ilkokulu'nda okuyorum. 3. Sınıfa geçtim. Zeka oyunları,mangala,kutu oyunları oynamayı ve akıl-mantık soruları çözmeyi seviyorum. Ayrıca dışarı çıkmayı,  parka gitmeyi, toprakla oynayıp kazı yapmayı çok seviyorum. Matematik, robotik kodlama ve uzaylı ilgileniyorum. İleride yazılım mühendisi olmak istiyorum.",
  },
  {
    result: "Minikler Altıncısı",
    name: "Zehra Betül Yalman",
    medal: 6,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Minikler Yedincisi",
    name: "Berkay Güner",
    medal: 7,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm7%20Berkay%20G%C3%BCner.jpeg?alt=media&token=3c75998e-efca-4e17-9ecd-a839f678de16",
    about: `27 Temmuz 2016 Nevşehir’de dünyaya gelmiştir.  Ailesiyle birlikte Nevşehir’de yaşıyor.  Eğitim öğretim hayatına Özel Altınyıldız Kreş...`,
    about2: `27 Temmuz 2016 Nevşehir’de dünyaya gelmiştir.  Ailesiyle birlikte Nevşehir’de yaşıyor.  Eğitim öğretim hayatına Özel Altınyıldız Kreş ve Anaokulunda  başladı.  İlköğretime de Altınyıldız Kollejinde devam etmektedir. Satranç oynamaktan, futbol oynamaktan ve izlemekten, kitap okumaktan ve matematiksel hesaplar yapmaktan çok zevk alıyor.`,
  },
  {
    result: "Minikler Sekizincisi",
    name: "Ata kaan şencan",
    medal: 8,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm8%20Ata%20Kaan.jpeg?alt=media&token=6e2c70d2-df58-482f-8733-c548442c3a55",
    about: `16 Mayıs 2014 yılında doğdum. 3.sınıf a geçtim .Bilimi ve deney yapmayı çok seviyorum. Aselsan da Atık Maddelerden Robot Yapma ve...`,
    about2: `16 Mayıs 2014 yılında doğdum. 3.sınıf a geçtim .Bilimi ve deney yapmayı çok seviyorum.Aselsan da Atık Maddelerden Robot Yapma ve Robotik Kol yapma Yarışmalarında 1. oldum . ÇİDÜM de Çocuk Hakları üzerine sertifikalı eğitimim var.
    Hayvanları çok seviyorum ve çok sevdiğim 5 yaşında Pamuk isimli sahiplendiğim bir kedim var.Onunla oynamayı çok seviyorum. Zeka oyunlarında gelecek yıl daha da başarılı olacağıma inanıyorum.`,
  },
  {
    result: "Minikler Dokuzuncusu",
    name: "Pera bozkurt",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm9%20PERA%20BOZKURT%20(1).jpg?alt=media&token=7ab02cba-a462-41cd-bcc0-94e836d70408",
    about: `22.08.2014 tarihinde Kırklareli ilinin Lüleburgaz ilçesinde doğdum. 
    4  yaşıma kadar Tekirdağ ili Saray ilçesinde yaşadım. 4 yaşımda Manisa’ya taşındık.`,
    about2: `22.08.2014 tarihinde Kırklareli ilinin Lüleburgaz ilçesinde doğdum. 
    4  yaşıma kadar Tekirdağ ili Saray ilçesinde yaşadım. 4 yaşımda Manisa’ya taşındık. Okul öncesi eğitimimi 2 yıl boyunca Manisa Helen Doron Gelecek Nesiller Anaokulu’nda aldım.
     İlk okula İzmir Özel Türk Koleji Manisa Kampüsü’nde başladım. Hala devam etmekteyim. 2 sınıf öğrencisiyim. Kitap okumaktan, dans etmekten, keman çalmaktan ve resim yapmaktan hoşlanıyorum.`,
  },
  {
    result: "Minikler Onuncusu",
    name: "FATMA ECE ŞEŞENOĞLU",
    medal: 10,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fm10%20FATMA%20ECE.jpg?alt=media&token=0eb55f07-2bf6-42a0-b543-d2f9d218a933",
    about: `Merhaba; benim adım Fatma Ece, 2014 yılında Ankara’da doğdum. Annem ve babam ile birlikte Ankara’da yaşıyoruz. Anaokulundan bu yana Nesibe Aydın...`,
    about2: `Merhaba; benim adım Fatma Ece, 2014 yılında Ankara’da doğdum. Annem ve babam ile birlikte Ankara’da yaşıyoruz. Anaokulundan bu yana Nesibe Aydın Çankaya İlkokulunda okuyorum. Şu anda İlkokul 3. Sınıf öğrencisiyim. Okulumu, arkadaşlarımı ve derslerimi çok seviyorum. Özellikle ilgi çekici bulduğum derslerim Matematik, Yabancı Dil, Müzik ve Bilişim Teknolojileri dersleri. Ayrıca bir senedir piyano eğitimi alıyorum. Boş zamanlarımda piyano çalmak, dans etmek, yüzmek, kitap okumak, bisiklet sürmek en severek yaptığım aktivitelerin başında geliyor.
Kendimi bildim bileli çok meraklı biriyim😊 Sanırım birden fazla ilgi alanımın olmasını da bu merakıma borçluyum. Tabi ailemin de beni her türlü etkinlik için çok desteklediğini söylememe gerek yok. Heyecanlıyım; görmek, bilmek, anlamak istiyorum, özellikle sıradışı ve beni zorlayacağını düşündüğüm konularla meşgul olmaktan çok büyük keyif alıyorum. 
Kendimle ilgili yazmış olduğum bu yazıyı okumak için vakit ayırdığınız için çok teşekkür ederim. 

Sevgilerimle 

Fatma Ece ☺`,
    whiteSpace: "pre-wrap",
  },
];

const cocuklar2022 = [
  {
    result: "Çocuklar Birincisi",
    name: "Dila Yalçın",
    medal: 1,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc1%20Dila%20Yal%C3%A7%C4%B1n_Foto.jpg?alt=media&token=8f74c50f-ef36-4bb2-a122-b7f9f30a99d6",
    about: `Merhaba, ben Dila Yalçın. 2011 Ankara doğumluyum. Gölbaşı Nesibe Aydın Ortaokulu öğrencisiyim. Aynı zamanda Yasemin Karakaya Bilim Sanat...`,
    about2: `Merhaba, ben Dila Yalçın. 2011 Ankara doğumluyum. Gölbaşı Nesibe Aydın Ortaokulu öğrencisiyim. Aynı zamanda Yasemin Karakaya Bilim Sanat Merkezi ve Çankaya Deneyap Atölyesine devam etmekteyim. Ailemin ve okulumun teşviği ve desteğiyle Kanguru Matematik, Caribou Matematik, Tales gibi yarışmalara birkaç yıldır katılmaktayım. Caribou Matematik dünya birinciliğim ve Kanguru Matematikte madalyam var. 
    Hedeflerim arasında abim Özgür gibi iyi bir LGS derecesi yapmak ve İstanbul Erkek Lisesi’nde okumak var. TZV’nin düzenlediği yarışmaları çok beğeniyorum ve ileriki yıllarda da katılmayı düşünüyorum.`,
  },
  {
    result: "Çocuklar İkincisi",
    name: "Arda Öz",
    medal: 2,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc2_Arda%20%C3%96z.jpg?alt=media&token=2dc9f789-bb3f-41e3-86c9-c192cc093afb",
    about: `Ben Arda. 5 Ocak 2011 Ankara doğumluyum. Özel Nesibe Aydın Yıldızlar Ortaokulu 6. Sınıf öğrencisiyim. Aynı zamanda Etimesgut Bilim...`,
    about2: `Ben Arda. 5 Ocak 2011 Ankara doğumluyum. Özel Nesibe Aydın Yıldızlar Ortaokulu 6. Sınıf öğrencisiyim. Aynı zamanda Etimesgut Bilim ve Sanat Merkezinde 4 senedir eğitim alıyorum. Zeka oyunlarına küçük yaşlardan itibaren merak duymaktayım. Bunun yanında yazılım alanında çalışmalar yapıyorum. 2022 yılında Bilsemdeki 2 arkadaşımla beraber Alzheimer üzerine hazırladığımız bir projeyle Tübitak Ortaokullar Arası Proje Yarışmasında Yazılım alanında Bölge birincisi olduk ve Türkiye Finallerine katılmaya hak kazandık. Türkiye Finalleri için projemizi geliştirmeye devam ediyoruz. 2021 yılında Caribou matematik yarışmasında Dünya, 2020 Kanguru Matematik yarışmasında Türkiye 1.liklerim var. Her sene Teknofest, Urfodu, Tazof, First Lego League ve Tales gibi yarışmalara katılıyorum. Boş zamanlarımda kitap okumayı, müzik dinlemeyi, kardeşimle vakit geçirmeyi ve kodlama yapmayı çok seviyorum. En büyük hayalim iyi bir yazılım mühendisi olmak ve ileride bu alanda insanlara fayda sağlayacak çalışmalara imza atmak.`,
  },
  {
    result: "Çocuklar Üçüncüsü",
    name: "FUAT ALTIOK",
    medal: 3,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc3%20Fuat%20Alt%C4%B1ok%20Foto%C4%9Fraf.jpeg?alt=media&token=f8b13f6b-8373-4c9c-9aca-666c6d415fde",
    about: `2009 Kayseri doğumluyum. Nevşehir/Hacıbektaş ‘ta yaşıyorum. Özel Altınyıldız Kolejinde 8. Sınıf öğrencisiyim. Bunun dışında 1. Sınıfta kazanmış olduğum...`,
    about2: `2009 Kayseri doğumluyum. Nevşehir/Hacıbektaş ‘ta yaşıyorum. Özel Altınyıldız Kolejinde 8. Sınıf öğrencisiyim. Bunun dışında 1. Sınıfta kazanmış olduğum Bilsem eğitimimi de devam ediyorum. “Her şey sevmek ve inanmakla başlar” diye çıktığım bu yolda 3. Sınıftan beri girdiğim bu sınavda bu sene Türkiye Üçüncüsü olmanın gururunu yaşıyorum . Ayrıca okul takımımla birlikte girdiğim Türk Zeka Vakfı Şampiyona yarışmasında da 3.lük alarak çifte derece yapmak bu sene beni ayrıca sevindirdi.
    Bunların dışında Tales Matematik, Kanguru Matematik, Matbeg , Fenbeg ,  Okubeg   ,Oyuncu Beyinler, Türk Beyin Takımı,Urfodu  ve Teknofest yarışmalarında da çeşitli derecelerim var . Gelecekte İzmir Fen Lisesi’nde okumayı ve doktor olmayı hedefliyorum.`,
  },
  {
    result: "Çocuklar Dördüncüsü",
    name: "HATİCE SAADET AYYILDIZ",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc4%20hatice%20saadet.jpeg?alt=media&token=32d04095-4a17-4e4b-a99e-4c86283489fe",
    about: `Ben Hatice Saadet Ayyıldız, 08.07.2011’de Burdur’da doğdum. Burdur Suna Uzal Ortaokulu’nda 6. sınıfa geçtim. 2019 yılından beri Alpaslan...`,
    about2: `Ben Hatice Saadet Ayyıldız, 08.07.2011’de Burdur’da doğdum. Burdur Suna Uzal Ortaokulu’nda 6. sınıfa geçtim. 2019 yılından beri Alpaslan Ali Can Bilim Ve Sanat Merkezi’nde genel yetenek öğrencisiyim. Boş zamanlarımda zeka oyunları bulmacaları çözmekten, kitap okumaktan, gezmekten ve ailemle vakit geçirmekten hoşlanıyorum. Aynı zamanda ulusal ve uluslararası çapta matematik, zeka oyunları alanında düzenlenen yarışmalara katılmaktan da keyif alıyorum. Matematik alanında Aydın Tales Matematik Yarışması’nda Türkiye 9.luğu ve 10.luğu; SASMO’da Dünya 5.liği; URFODU‘da Dünya 1.liği; CRYPTOMATH‘da Türkiye 3.lüğü derecelerini elde ettim. Zeka oyunlarıyla 4.sınıfta ilgilenmeye başladım. TZV tarafından düzenlenen Türkiye Okullar Arası Zeka Oyunları Şampiyonası’nda takım olarak Türkiye 4.lüğü ve 10.luğu başarıları elde ettim. Bireysel anlamda ilk defa katıldığım 27.Zeka Oyunları Yarışması’nda Türkiye 4.sü olmaktan büyük bir mutluluk duydum  ve elde ettiğim başarı sonucunda sizlere kendimi  anlatmaktan onur duydum.  Bu yarışmayı düzenleyen Türkiye Zeka Vakfı’na ve bana her konuda destek olan aileme, matematik öğretmenim Ayşe Şimşek Batar’a çok teşekkür ederim.`,
  },
  {
    result: "Çocuklar Beşincisi",
    name: "GÜVEN ÖZTÜRK",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc5%20g%C3%BCven.jpg?alt=media&token=b28c736a-fa12-4d1f-8085-ce12f6611d1b",
    about: `24/10/2012 İzmit doğumluyum. Anasınıfından beri Ted Kocaeli Koleji'nde okuyorum. 
    Satranç oynamayı, legodan yeni tasarımlar yapmayı ve...`,
    about2: `24/10/2012 İzmit doğumluyum. Anasınıfından beri Ted Kocaeli Koleji'nde okuyorum. 
    Satranç oynamayı, legodan yeni tasarımlar yapmayı ve bilgisayarda kod yazmayı seviyorum.
    Bilim Kahramanları Derneği tarafından 12 Haziran 2022'de gerçekleştirilen, CARGO CONNECT 
    sezon temalı “Minik Bilim Kahramanları Buluşuyor / FIRST® LEGO® League Explore” fuarına 
    katılarak takımım ile birlikte Usta Yazılımcılar ödülünü aldık. Her yıl düzenlenen Cambridge English
    sınavlarına yaş seviyeme uygun olarak katılıyor ve başarı sertifikaları alıyorum. Root 2022 robotik kodlama
    turnuvası yarı finalinde bireysel kategoride Türkiye birincisi oldum.`,
  },
  {
    result: "Çocuklar Beşincisi",
    name: "GÖRKEM ÖZTÜRK",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc5%20g%C3%B6rkem%20%C3%B6zt%C3%BCrk.png?alt=media&token=da5b6197-81ec-4af7-a1cb-023633c56410",
    about: `13/09/2010 İzmit doğumluyum. İzmit Bilim ve Sanat merkezi öğrencisiyim.
    Anasınıfından beri Ted Kocaeli Koleji'nde okuyorum.`,
    about2: `13/09/2010 İzmit doğumluyum. İzmit Bilim ve Sanat merkezi öğrencisiyim.
    Anasınıfından beri Ted Kocaeli Koleji'nde okuyorum. Bugüne kadar çok sayıda 
    ulusal ve uluslararası yarışmalarda derece kazandım. 2022 Stem Olimpiyatları Popular
    Science kategorisinde grade 6'da Dünya 9. su olarak Silver ve Honorable Mention
    madalyası kazandım. Urfodu 2022 XVIII.Uluslararası Bilim Temelleri Bilgi Yarışması Matematik
    kategorisinde 1. derece diploması ile altın madalya kazandım. Her yıl düzenlenen Cambridge 
    English sınavlarına yaş seviyeme uygun olarak katılıyor ve başarı sertifikaları alıyorum. 
    Root 2022 robotik kodlama turnuvasında takım arkadaşımla birlikte Türkiye dokuzuncusu olduk. 
    2021 yılında We code our future kodlama projemiz ile hem ulusal hem de Avrupa kalite etiketi
    aldık. 2021 yılında Pearson English Benchmark sınavına katıldım ve başarı sertifikası kazandım. 
    2021 World Maths Day'de Türkiye birincisi ve Dünya genelinde ilk 500 öğrenciye verilen başarı 
    sertifikası almayı hak ettim. 2021 matbeg uygulamasında üst düzey performans sertifikası
    aldım. 2018-2021 yılları arasında London College of Music piyano sınavlarına katıldım ve 
    başarı sertifikalarım var. Yüzmeyi, bilgisayarda proglama yapmayı, kitap okumayı ve zeka oyunlarını 
    seviyorum.`,
  },
  {
    result: "Çocuklar Altıncısı",
    name: "ÇAĞLAYAN DİNÇTÜRK",
    medal: 6,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2FC6_%C3%87a%C4%9Flayan%20Din%C3%A7t%C3%BCrk.jpg?alt=media&token=ac661926-aa05-45ec-99ae-5a87427cc813",
    about: `Merhaba, ben Çağlayan Dinçtürk. 13 yaşındayım. Burdur’da yaşıyorum ve Şehit Akif Altay Ortaokulu’nda okumaktayım. Ayrıca Alpaslan...`,
    about2: `Merhaba, ben Çağlayan Dinçtürk. 13 yaşındayım. Burdur’da yaşıyorum ve Şehit Akif Altay Ortaokulu’nda okumaktayım. Ayrıca Alpaslan Alican Bilim ve Sanat Merkezi’nde de öğrenim görmekteyim. 4. Sınıftan beri matematik ve zeka oyunlarına özel ilgi duymaktayım. Ayrıca futbol, hentbol, yüzme ve kort tenisi gibi spor dallarına; satranç, mangala ve pentago gibi zeka oyunlarına da meraklıyım. Bu güne kadar matematik alanında 2 kez Türkiye ve 2 kez dünya 1.si, zeka oyunlarında ise Türkiye 1.liği, 4.lüğü, 5.liği, bu sene düzenlenen yarışmada ise Türkiye 6.lığı elde ettim. Bütün bu yarışmalara katılmamı sağlayan, beni yönlendiren ve destekleyen Ayşe Şimşek ve Aybetül Türken öğretmenlerime çok teşekkür ediyorum. Beni hiç yalnız bırakmadılar ve her konuda yardımcı oldular. Bilim ve Sanat Merkezimize de bize bütün imkanlarını sundukları için çok teşekkür ederim. Ayrıca her daim destekçim aileme de sonsuz teşekkür ediyorum. Bu platformda bulunmak ve şeref kürsüsünde yer almaktan çok mutluyum. Teşekkürler TZV ailesi.`,
  },
  {
    result: "Çocuklar Yedincisi",
    name: "YUSUF ÇUKURİL",
    medal: 7,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc7%20yusuf.jpg?alt=media&token=79124bee-3939-46f1-b862-53521854d1fc",
    about: `2011 doğumluyum. İlkokulu Şişli Terakki Levent İlkokulunda bitirdim. Şu anda Vehbi Koç Vakfı Koç Özel Ortaokulu’na devam ediyorum.`,
    about2: `2011 doğumluyum. İlkokulu Şişli Terakki Levent İlkokulunda bitirdim. Şu anda Vehbi Koç Vakfı Koç Özel Ortaokulu’na devam ediyorum. Aynı zamanda ilkokul 2.sınıfta başladığım Beşiktaş Bilsem Genel Yetenek öğrencisiyim. Türkiye Zeka Vakfı’nın yarışmaları ile son bir yıldır ilgilenmeye başladım. En çok sevdiğim ders Matematik. Satranç ve tenis çok sevdiğim ilgi alanlarım. Ayrıca bateri çalıyorum.`,
  },
  {
    result: "Çocuklar Sekizincisi",
    name: "Ali Alper Bilen",
    medal: 8,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc8%20ali%20alper%20bilen.jpg?alt=media&token=39623642-b4e8-4c71-b597-c7e636d40dea",
    about: `Ali Alper Bilen 2010 yılında Isparta'da doğdu. İlkokulu Burdur TOKİ Yahya Kemal Beyatlı İlköğretim okulunda bitirdi. Şu an Burdur Suna UZAL...`,
    about2: `Ali Alper Bilen 2010 yılında Isparta'da doğdu. İlkokulu Burdur TOKİ Yahya Kemal Beyatlı İlköğretim okulunda bitirdi. Şu an Burdur Suna UZAL Ortaokulunda  okumaktadır.
Aynı zamanda Burdur Bilim Sanat Merkezinde 5 yıldır eğitim görmektedir. 
 
Birincilik - Türkiye Okullar Arası Zeka Oyunları Şampiyonası, İlkokul Kategorisi, 2019
Finalist - 4. Ulusal Tales Matematik Yarışması, 2019
Dokuzuncu - Türkiye 25. Zeka Oyunları Yarışması, Oyun 2020, Çocuklar Kategorisi 
Finalist - Türkiye Matematik Yarışması, 2020
Birincilik - Türkiye Matematik Yarışması, 2021
Birincilik -URFODU Uluslar Arası Bilim Temelleri ve Bilgi Yarışması, 2021
Finalist- Türkiye Okullar Arası Zeka Oyunları Şampiyonası, Ortaokul Kategorisi, 2020
Finalist - Türkiye Matematik Yarışması, 2022
İkincilik- URFODU, Uluslar Arası Bilim Temelleri ve Bilgi Yarışması, 2022
Finalist- Türkiye Okullar Arası Zeka Oyunları Şampiyonası, Ortaokul Kategorisi, 2022
    `,

    whiteSpace: "pre-wrap",
  },
  {
    result: "Çocuklar Dokuzuncusu",
    name: "SUDENAZ BÜYÜKTÜRK",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc9%20Sudenaz%20B%C3%BCy%C3%BCkt%C3%BCrk.jpg?alt=media&token=87ccbc34-a3af-400c-9bb2-53304941cd88",
    about: `2009 yılında Ereğli/ Konya da doğdum.Okul öncesi eğitimi mi Balıkesir de tamamladım. İlkokulu Balıkesir Atatürk ilkokulunda okudum`,
    about2: `2009 yılında Ereğli/ Konya da doğdum.Okul öncesi eğitimi mi Balıkesir de tamamladım.İlkokulu Balıkesir Atatürk ilkokulunda okudum.1.sınıfta Genel yetenek dalında Bilsem’ i kazandım. 6.sınıfa kadar Balıkesir Bilsem de eğitim gördüm.Konya’ya taşındığımız için halen Konya Selçuklu Yüksel Bahadır Alaylı Bilsem de eğitim görmekteyim. 5.sınıfa Balıkesir Yarış Ortaokulun da başladım.Şu an Konya Kaşgarlı Mahmut Ortaokulunda 8.sınıfa geçtim. Bilsem de Akıl ve zeka oyunlarına ilgim başladı. 23.TZV oyunlarında final yarışmasına katıldım.Türkiye Okullar Arası Zeka Oyunları Şampiyonasında okulumuzu takım olarak temsil ettik.Uluslararası Bilim Temelleri yarışması (URFODU)  Matematik dalında birinci oldum(2022).  Biyoloji alanın da öğretmenimle Uluslararası Kongrelere makale hazırladık. Hobilerim arasında Robotik kodlama, yüzme, basketbol, kitap okumayı sayabilirim. Yüzme, basketbol ve robotik kodlama kurslarına gittim.İlgi alanım Matematik olduğu için zeka oyunları ve matematik yarışmalarına katılmaya devam edeceğim.`,
  },
  {
    result: "Çocuklar Onuncusu",
    name: "Zeynep Begüm Yalçın",
    medal: 10,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fc10%20Zeynep%20Beg%C3%BCm%20Yal%C3%A7%C4%B1n%20foto%C4%9Fraf.jpeg?alt=media&token=ba76c120-2456-4780-8d47-1b78677ad4e9",
    about: `Ben Zeynep Begüm YALÇIN, 12 yaşındayım. Şehit Akif Altay Ortaokulunda 7. sınıfa geçtim aynı zamanda Burdur Alparslan Ali Can Bilim ve Sanat...`,
    about2: `Ben Zeynep Begüm YALÇIN, 12 yaşındayım. Şehit Akif Altay Ortaokulunda 7. sınıfa geçtim aynı zamanda Burdur Alparslan Ali Can Bilim ve Sanat Merkezinde öğrenim görmekteyim. Zeka oyunları oynamayı, kitap okumayı özellikle matematiği çok seviyorum. Gerek ülke genelinde gerekse uluslararası pek çok başarım var. İlk başarım Türkiye geneli yapılan Kanguru Matematik Yarışması ülke derecesi (2020), sonra Uluslararası Bilim Temelli Bilgi Yarışması (Urfodu) birincilik (2021 ve 2022), Türkiye geneli düzenlenen Yeni Nesil Yarışma 13 Şubat aşaması üçüncülük, yine aynı yarışmanın 15 Mayıs aşamasında ikincilik (2022), TÜBİTAK 2204/B Matematik alanı bölge üçüncülüğü (2022), Türkiye geneli düzenlenen Cryptomath yarışması üçüncülüğü (2022) şeklinde sıralanmaktadır. Kendimi zeka oyunları alanında da geliştirmek istiyorum. Bunun için de çok çaba sarf ediyorum. Bu doğrultuda Türkiye Zeka Vakfının düzenlediği 27. Zeka Oyunları Yarışması’nda Türkiye 10.su olmaktan mutluluk ve gurur duyuyorum. Bana olan desteklerini esirgemeyen öğretmenlerim Ayşe ŞİMŞEK BATAR’a ve Aybetül TÜRKEN’e de saygılarımı ve sevgilerimi sunuyorum.`,
  },
];

const gencler2022 = [
  {
    result: "Gençler Birincisi",
    name: "Hilmi Erdem Kaya",
    medal: 1,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg1%20Hilmi%20Erdem%20Kaya.jpeg?alt=media&token=8ec4479e-eeed-40aa-b33f-1b837a54a9b9",
    about: `18 Ağustos 2004 tarihinde İstanbul'da doğdum. Kocaeli'de yaşıyorum. İzmit Hızır Reis Ortaokulundan mezun oldum. Özel Enka Fen ve Teknoloji...`,
    about2: `18 Ağustos 2004 tarihinde İstanbul'da doğdum. Kocaeli'de yaşıyorum. İzmit Hızır Reis Ortaokulundan mezun oldum. Özel Enka Fen ve Teknoloji Lisesinde 12. sınıfta eğitimime devam etmekteyim. 3 yıl önce arkadaşlarım sayesinde yarışmadan haberim oldu. Küçüklükten beri bulmaca benzeri soruları özellikle kelime içerenleri seviyorum. Müzikle ilgilenmek, okumak, matematik, Formula serisi izlemek, yeni bir şeyler öğrenmek hobilerim arasındadır. `,
  },
  {
    result: "Gençler İkincisi",
    name: "İlter Arda Çetin",
    medal: 2,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg2%20ilter%20arda%20%C3%A7etin%20foto%C4%9Fraf.jpeg?alt=media&token=769a6d36-c7e5-42d2-b296-756f5d919485",
    about: `16 Mart 2004 tarihinde Gebze’de doğdum. İlkokul ve ortaokulu Mustafa Üstündağ Ortaokulunda tamamladım. Şimdi, Dilovası Özel Enka Fen...`,
    about2: `16 Mart 2004 tarihinde Gebze’de doğdum. İlkokul ve ortaokulu Mustafa Üstündağ Ortaokulunda tamamladım. Şimdi, Dilovası Özel Enka Fen Lisesi 12.sınıf öğrencisiyim. Matematiğe özel bir ilgim var. Çeşitli ulusal matematik yarışmlarına katılıp dereceler aldım. Ayrıca bilgisayar oyunları oynamayı, arkadaşlarımla birlikte olmayı, piyano çalmayı ve basketbol oynamayı seviyorum. Geçtiğimiz yıl Okullararası TZV yarışmasında okulumu temsil ettim. 4 yıldır TZV yarışmasına katılmaktayım. Soruları gördükçe kendimi mantık, matematik alanındaki zeka sorularında geliştirdiğimi düşünüyorum.`,
  },
  {
    result: "Gençler Üçüncüsü",
    name: "Can Uçar",
    medal: 3,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Gençler Üçüncüsü",
    name: "Deniz Pala",
    medal: 3,
    photo: "",
    about: `Merhaba, ben Deniz Pala. Türkiye Zeka Vakfı’nın düzenlediği etkinlik ve yarışmalara 2018’den beri her sene katılıyorum.`,
    about2: `Merhaba, ben Deniz Pala. Türkiye Zeka Vakfı’nın düzenlediği etkinlik ve yarışmalara 2018’den beri her sene katılıyorum. Liseyi okuduğum Koç Okulu’ndaki matematik kulübünde de son 4 yıldır çeşitli matematik yarışmaları ve etkinliklere katıldım. Matematik, fizik ve kodlamayla uğraşmayı çok seviyorum ve bu sene de Toronto Üniversitesi’nde bilgisayar mühendisliği bölümünü okumaya başlayacağım. Uzun bir süredir katıldığım TZV Oyun’da bu sene başarılı olmak beni gerçekten çok mutlu etti.`,
  },
  {
    result: "Gençler Dördüncüsü",
    name: "Kerem Deniz Öztürk",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg4%20kerem_deniz_ozturk.jpg?alt=media&token=ab8b8d2b-1d24-49d0-9857-8158a6043cda",
    about: `İsmim Kerem Deniz, 2005 İzmir doğumluyum, İzmir'de yaşıyorum. İlkokulu Yavuz Selim ve Dokuz Eylül İlkokullarında ve ortaokulu da Bornova...`,
    about2: `İsmim Kerem Deniz, 2005 İzmir doğumluyum, İzmir'de yaşıyorum. İlkokulu Yavuz Selim ve Dokuz Eylül İlkokullarında ve ortaokulu da Bornova Doğa Kolejinde okudum. Şu an eğitimime İzmir Amerikan Kolejinde devam etmekteyim. Bu süreçte çeşitli matematik ve zeka oyunları yarışmalarına da katıldım. Son iki senedir okulumla Türk Zeka Vakfı'nın yarışmalarına katılmakla beraber, bu sene ilk kez TZV Oyun'a katıldım ve göstermiş olduğum bu başarı beni çok mutlu etti. Küçük yaşlardan itibaren basketbol oynuyorum. Takımım ile İzmir'de çeşitli başarılar elde ettik. Bu sene okul oryantiring takımında yer almakla birlikte takımımla İzmir ikinciliği elde ettik. Ayrıca uzun yıllardır piyano çalıyorum. Onun dışında son zamanlarda kodlama ile uğraşıyorum. C, Python gibi programlama dilleri bilmekle birlikte oyun geliştirme üzerinde çalışmalar yapıyorum. Vakfın sonraki senelerde gerçekleşecek yarışmalarına da katılmayı planlıyorum. `,
  },
  {
    result: "Gençler Beşincisi",
    name: "Mert Bilgi",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg5%20mert_bilgi%20(1).jpg?alt=media&token=8d55c20e-7932-425e-b7aa-1a70528e1177",
    about: `Ben Mert Bilgi, 12 Temmuz 2007’de Adana'nın Seyhan ilçesinde dünyaya geldim. 5 yaşıma kadar Adana’da yaşadıktan sonra İstanbul'a taşındım.`,
    about2: `Ben Mert Bilgi, 12 Temmuz 2007’de Adana'nın Seyhan ilçesinde dünyaya geldim. 5 yaşıma kadar Adana’da yaşadıktan sonra İstanbul'a taşındım. Anaokulunu, ilkokulu ve ortaokulu İstanbul'daki Büyükçekmece Akademi Özel Okulları'nda okudum. Hayatımın bu dönemi boyunca; matematiğe, zeka oyunlarına ve bulmacalara ilgiliydim. Ortaokulda da bu merakım devam etti ve Türkiye Zeka Vakfı’nın yarışması gibi birçok yarışmaya katıldım. Şu an ise İstanbul ENKA Lisesi’nde 9. sınıf öğrencisiyim. 1 yıldır matematik olimpiyatı yapıyor ve çeşitli yarışmalara katılım göstermeye devam ediyorum. Bunların yanı sıra müziğe ve spora da oldukça değer veren biriyim. Ortaokuldan beri masa tenisi oynuyor ve elektro gitar çalıyorum. Hayatım boyunca öğrenmeyi ve sorgulamayı hiç bırakmadım ve bu huyumun değişeceğini de pek sanmıyorum.`,
  },
  {
    result: "Gençler Beşincisi",
    name: "Can Güray Erkan",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg5%20Can%20G%C3%BCray%20Erkan.jpg?alt=media&token=7e48f3c2-8e4b-48ef-ad12-715ff627bef4",
    about: `Ben Can Güray Erkan. 1 Haziran 2007 Tarihinde doğdum. Doğduğum yer Sakarya’nın Adapazarlı ilçesi idi. İlkokul yıllarımı Adapazarı Enka...`,
    about2: `Ben Can Güray Erkan. 1 Haziran 2007 Tarihinde doğdum. Doğduğum yer Sakarya’nın Adapazarlı ilçesi idi. İlkokul yıllarımı Adapazarı Enka Okullarında geçirdim. Okul değiştirmeden, Ortaokulumu da Adapazarı Enka Okullarında okudum. Liseyi İstanbul Enka okullarında okumayı sürdürmekteyim. Müziğe hayranlıkla büyüdüm. Aktif olarak gitar ve piyano çalmaktayım. Hayatım boyunca matematiğe ve zeka oyunlarına bir ilgim oldu. Lisede yoğunlaşan bu ilgi sonucu 1 yıldır okulun matematik olimpiyat takımı öğrencisiyim. Zeka oyunlarına olan ilgim sayesinde de TZV yarışmasına katıldım ve böyle bir başarı elde ettim. `,
  },
  {
    result: "Gençler Beşincisi",
    name: "Arda Altınkök",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg1%20Arda%20Alt%C4%B1nk%C3%B6k.jpeg?alt=media&token=7380c9e8-f838-459f-b1ae-13ca96209daa",
    about: `Ben Arda ALTINKÖK, 2006 yılında Bolu’da doğdum. İlkokula Bolu’da 50. Yıl İzzet Baysal İlkokulunda başladım sonra Giresun'da...`,
    about2: `Ben Arda ALTINKÖK, 2006 yılında Bolu’da doğdum. İlkokula Bolu’da 50. Yıl İzzet Baysal İlkokulunda başladım sonra Giresun'da 19 Eylül İlkokulunu  bitirdim. Ortaokulu Bahçeşehir kolejinde tamamladım. Halen İstanbul’da bulunan Özel Enka Lisesi’nde öğrenim hayatına devam etmeyim. Enka Lisesi’nde Lise 10. Sınıf Matematik Olimpiyat takımındayım. 
    Uluslararası URFODU 2021 ve 2022 Matematik olimpiyatları, ve yine uluslararası SASMO Matematik Olimpiyatı gibi yarışmalarda derece ve madalyalarım vardır. Her sene düzenli olarak TÜBİTAK Matematik Olimpiyatlarına katılıyorum ayrıca Bilim ve Sanat Merkezi (BİLSEM) öğrencisiyim. Daha önce BİLSEM ile birlikte TÜBİTAK’a Matematik Dalında bir proje ile başvuru yaptım.
    Eğitim hayatımın yanı sıra piyano, tenis gibi aktivitelerle de amatör olarak ilgilenmekteyim.`,
  },
  {
    result: "Gençler Altıncısı",
    name: "Kağan KIRDAR",
    medal: 6,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg6%20Ka%C4%9Fan%20K%C4%B1rdar.jpg?alt=media&token=31e2aabd-0320-45fc-94f0-58e3ff0e5a58",
    about: `2005 yılında Ankara’da doğdum. İlkokul için Cumhuriyet İlkokuluna gittim ve daha sonradan ortaokulda Büyük Koleje geçtim.`,
    about2: `2005 yılında Ankara’da doğdum. İlkokul için Cumhuriyet İlkokuluna gittim ve daha sonradan ortaokulda Büyük Koleje geçtim. Zeka oyunları ile gerçek anlamda ilgilenmeye ortaokulda başladım.  Okuldaki zeka oyunları kulübünde geçirdiğim vakitte zeka oyunlarına olan ilgim iyice arttı ve 7. sınıfta ilk defa TZV Oyun Yarışması’na katıldım. Sonraki yıllarda yarışmaya katılmaya devam ettim. Lise için Zafer Koleji Fen Lisesine başladım ve arkadaşlarımla birlikte TZV Şampiyona Yarışması’na katıldım, bu süreçte kendi aramızda toplanıp sorular üzerine çözüm yöntemleri geliştirdik. Zeka oyunlarının yanında sudoku çözmek ve satranç oynamakla da uğraşıyorum.  TZV yarışmalarının yanında öbür matematik ve akıl oyunları yarışmalarında da yer almaya çalışıyorum. Diğer hobilerimin arasında ise basketbol oynamak ve şiir okumak var. `,
  },
  {
    result: "Gençler Yedincisi",
    name: "Atakan Turan",
    medal: 7,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg7%20atakan%20turan.jpeg?alt=media&token=9f9684b0-ea1d-4f6d-a908-bde098108919",
    about: `2004 yılında İstanbul’da doğdum. 18 yaşındayım ve Avusturya Lisesi’nde eğitim görmekteyim. İlk ve Ortaöğretimimi Alev okulunda tamamladım.`,
    about2: `2004 yılında İstanbul’da doğdum. 18 yaşındayım ve Avusturya Lisesi’nde eğitim görmekteyim. İlk ve Ortaöğretimimi Alev okulunda tamamladım. Alev okulunda okurken Kanguru Matematik sınavına girmem ile zekâ oyunları ve sorularına ilgim başladı. 2018’de 8.sınıfta dershane arkadaşlarımdan biri beni TZV ile tanıştırdı. İlk iki sene sorularda çok zorlanmış olsam da zamanla alıştım. Sonrasında diğer zekâ yarışlarına da katıldım ve soru gördükçe daha doğru ve hızlı çözebilmeye başladım. Şu anki hobilerim: müzik dinlemek, bulmaca çözmek ve bilgisayar oyunları oynamak. Eskiden profesyonel olarak kayak kaymaktaydım. Matematiğe küçüklüğümden beri ilgim var. Sudoku çözmeye başlamam ile bulmacalara da ilgi duymaya başladım. Özellikle karantina döneminde evde kalmamızla zaman geçirmek için zekâ soruları ve bulmacalarla daha fazla ilgilenmeye başladım.`,
  },
  {
    result: "Gençler Sekizincisi",
    name: "Şan Kaner BAYRAK",
    medal: 8,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg8%20%C5%9Ean%20Kaner.jpg?alt=media&token=154c5e09-faf8-454a-8e26-379a285a4e5c",
    about: `2007 senesinin 14 Eylül tarihinde Istanbul'da dünyaya geldim. Eğitim öğretimime Hasan Akan Ilkokulunda başladım ve Emlak Konut Ortaokulu'nda...`,
    about2: `2007 senesinin 14 Eylül tarihinde Istanbul'da dünyaya geldim. Eğitim öğretimime Hasan Akan Ilkokulunda başladım ve Emlak Konut Ortaokulu'nda devam ettim sonra da 2021 senesinde Maltepe Fen Lisesini kazandım ve 10. sınıf öğrencisi olarak devam ediyorum. Zeka oyunlarının yanında mekanik ve kodlama ile ilgileniyorum. Küçüklüğümden beri puzzleları çok severim özellikle rübik küp gibi puzzle'lara karşı ilgim çok. Gelecekteki hedefim mutlu olmak.`,
  },
  {
    result: "Gençler Sekizincisi",
    name: "Yiğit Baklacıoğlu",
    medal: 8,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fg8%20yigit%20baklac%C4%B1oglu.jpeg?alt=media&token=9d6fd3ca-9043-40f9-afe3-0b1c40d3948d",
    about: `Maltepe Fen Lisesi Akıl ve Zeka Oyunları Takımımızın 11. Sınıf öğrencisiyim 2006 yılında İstanbul’da doğdum. 7. Sınıfa kadar satranç...`,
    about2: `Maltepe Fen Lisesi Akıl ve Zeka Oyunları Takımımızın 11. Sınıf öğrencisiyim 2006 yılında İstanbul’da doğdum. 7. Sınıfa kadar satranç alanında birçok turnuvada yer aldım bireysel ve okul takımımızla çeşitli başarılara imza attık. 8. Sınıfta ise hocalarımın yönlendirmesiyle Kulami isimli kutu oyununda Türkiye Akıl Zeka Oyunları Federasyonu’nun il ve ilçe turnuvalarını 1.likle tamamlayarak Türkiye Finallerine katılmaya hak kazandım ne yazık ki pandemi sebebiyle Türkiye finali iptal edildi. Liseye geldiğimdeyse okulumun Akıl ve Zeka oyunları takımına seçildim. Takımımızın önceki senelerde elde ettiği Türkiye çapındaki dereceler ve başarıları  da okulumu seçmemde öncü oldu. Takımımızla bu sene sıkı bir çalışma dönemi geçirdik çokça emek verdik ve sonunda başarıya ulaştık. Gelecek senelerde kendimizi ve derecemizi geliştirmek  dileğiyle…`,
  },
  {
    result: "Gençler Sekizincisi",
    name: "Onur Alp Güngör",
    medal: 8,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Gençler Dokuzuncusu",
    name: "Tarık Yıldız",
    medal: 9,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Gençler Onuncusu",
    name: "Fatma Zehra Yıldız",
    medal: 10,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Gençler Onuncusu",
    name: "Zeynep Duru",
    medal: 10,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Gençler Onuncusu",
    name: "Miray AKGÜN",
    medal: 10,
    photo: "",
    about: ``,
    about2: ``,
  },
];

const yetiskinler2022 = [
  {
    result: "Yetişkinler Birincisi",
    name: "Muharrem ŞEKEROĞLU",
    medal: 1,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy1%20Muharrem%20%C5%9Eekeroglu.jpg?alt=media&token=510616da-d43d-4e00-afd3-f81a4c0d7d80",
    about: `19 Mayıs 1996 tarihinde İstanbul’da doğdum. Ortaokul yıllarımda Matematik Olimpiyatları yarışmalarına katılmaya başladım. Lise yıllarımda...`,
    about2: `19 Mayıs 1996 tarihinde İstanbul’da doğdum. Ortaokul yıllarımda Matematik Olimpiyatları yarışmalarına katılmaya başladım. Lise yıllarımda programlamaya ve akıl oyunlarına ilgi duydum. Ulusal başarılarımın yanında, 2008 yılında Amerika Matematik Olimpiyatları’nda altın madalya, 2011 yılında Romanya bilgisayar projeleri yarışmasında bronz madalya kazandım. 2 sene dördüncü ve beşinci olarak Türk Genç Beyin Takımına girdim. 2012 yılında Dünya Zeka Oyunları yarışmasına Türk Genç Beyin Takımını temsilen katıldım. 2017 yılında YTÜ Yapı Kredi Code Arena yarışmasında üniversite birincisi oldum. Sınav Akıl Oyunları yarışmalarında bireysel olarak 1 kez ikinci, 1 kez dördüncü, 1 kez beşinci, takım halinde ise 2 kez birincilik kazandım. 2020 Türkiye Zeka Oyunları Yarışması’nda 7. Oldum. Şu anda aktif olarak Türkiye’nin önde gelen bazı markalarının mobil uygulamalarını geliştirmekteyim. Spora, Piyano çalmaya, Zeka ve Strateji oyunlarına ilgiliyim.`,
  },
  {
    result: "Yetişkinler Birincisi",
    name: "Fatih Kamer Anda",
    medal: 1,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy1%20fatih%20kamer%20anda.jpg?alt=media&token=f4de83cf-b731-4956-bb3a-af4b768ecd79",
    about: `Zekâ oyunlarını derslerle buluşturan PAKO Yayınları’nda Genel Yayın Yönetmeni olarak çalışmaktayım. 6 defa Türk Beyin Takımı (TBT)...`,
    about2: `Zekâ oyunlarını derslerle buluşturan PAKO Yayınları’nda Genel Yayın Yönetmeni olarak çalışmaktayım. 6 defa Türk Beyin Takımı (TBT) sporcusu olarak Dünya Zeka Oyunları Şampiyonası’nda ülkemizi temsil ettim. 2018 Asya Sudoku (ASC) Şampiyonası, UKPA Open, Birleşik Krallık Zeka Oyunları Takım seçmeleri, 24 Saat Zeka Oyunları yarışması (24 HPC Macaristan) gibi ulusal ve uluslararası 20’ye yakın zeka oyunları yarışmasının zeka oyunları tasarımcısı ve test çözeri olarak görev aldım. Yurtiçinde yayınlanan 25’e yakın zeka oyunları kitabının yazarı veya editörüyüm. Yurt içinde düzenlenen 50’den fazla Zeka Oyunları Yarışmasının Koordinatörü ve tasarımcısı olarak görev aldım. Zeka Oyunları Başarılarım;
Dünya Zeka Oyunları Şampiyonası 36.'lık(2017) - Dünya Sudoku Şampiyonası 53.'lük(2017)- Türk Beyin Takımı Seçmeleri 2017 1.'lik 2022 2.’lik - Genç Türk Beyin Takımı 2011 1.'lik - Türk Sudoku Takımı Seçmeleri 2017 2.lik - TZV Oyun Yetişkinler Kategorisi 2015 9.'luk, 2016 3.'lük, 2019 5.'lik, 2020 7.'lik  2022 1.lik 21 Yaş altı kategori 3.’lük 2014
   `,
  },
  {
    result: "Yetişkinler İkincisi",
    name: "Salih Alan",
    medal: 2,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy2%20Salih%20Alan.jpg?alt=media&token=728d17c0-914f-4b9b-bd73-655934b18ae4",
    about: `1984 Kızılcahamam doğumluyum. Zeka oyunlarına ilgim ortaokul yıllarında başladı. O yıllardan beri Oyun yarışmalarını takip ediyorum.`,
    about2: `1984 Kızılcahamam doğumluyum. Zeka oyunlarına ilgim ortaokul yıllarında başladı. O yıllardan beri Oyun yarışmalarını takip ediyorum. Bu yarışmalarda üç kez birinciliğim ve bunlarla birlikte çok sayıda ilk 10 derecem var.
    2007 yılından beri Türk Beyin Takımı ve Türk Sudoku Takımı üyesi olarak, Dünya Zeka Oyunları Şampiyonası’nda ve Dünya Sudoku Şampiyonası’nda 13’er kez ülkemizi temsil ettim. 2010 yılındaki Dünya Sudoku Şampiyonası’nda bireysel sıralamada 7. oldum. 2008 yılındaki Dünya Sudoku Şampiyonası’nda ve 2010 yılındaki Dünya Zeka Oyunları Şampiyonası’nda 5. olan takımların üyesiydim.
    Zeka oyunları alanında; dergi ve kitap gibi yayınların hazırlanması, öğretmen eğitimleri, yarışma düzenlenmesi gibi görevlerde bulundum.
    Sudoku ve mantığa dayalı zeka oyunları, kelime oyunları, bilmeceler, mekanik oyunlar gibi tüm zeka oyunlarını çözmeyi seviyorum.
    ODTÜ makine mühendisliği lisans, yüksek lisans ve doktora mezunuyum. Şu anda havacılık sektöründe faaliyet gösteren bir şirkette çalışıyorum.
    `,
  },
  {
    result: "Yetişkinler Üçüncüsü",
    name: "Metehan Dilaver",
    medal: 3,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy3%20metehan%20Dilaver.jpg?alt=media&token=1e0e9304-435c-41b6-bdf6-b24268e5ee4b",
    about: `1994 Ankara doğumluyum. Lise eğitimimi Ankara Gazi Anadolu Lisesinde tamamladıktan sonra lisans eğitimimi Hacettepe Üniversitesi...`,
    about2: `1994 Ankara doğumluyum. Lise eğitimimi Ankara Gazi Anadolu Lisesinde tamamladıktan sonra lisans eğitimimi Hacettepe Üniversitesi Endüstri Mühendisliği Bölümünden elde ettim. Sonrasında Bilkent Üniversitesinde yüksek lisans derecemi yine Endüstri Mühendisliği alanında aldım. Şu anda Eindhoven Teknoloji Üniversitesinde doktora çalışmalarımı sürdürmekteyim. Lise yıllarından beri büyük bir keyif aldığım akıl ve zekâ oyunları en çok sevdiğim hobilerimin arasındadır. Ayrıca satranç ve voleybol oynamayı severim. Lise döneminde liseler arası akıl oyunları yarışmalarda bireysel birincilik ve çeşitli takım derecelerim bulunsa da yarışmadan haber olduğumdan beri her yıl katıldığım Oyun’da ilk kez dereceye girmiş bulunuyorum. TZV’ye bu yarışmayı düzenleyerek bu mutluluğuma yol açtığı için teşekkür ederim. Nice derecelerde görüşmek dileğiyle...`,
  },
  {
    result: "Yetişkinler Üçüncüsü",
    name: "Nazire barlas dilaver",
    medal: 3,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy3%20Nazire%20Barlas.jpg?alt=media&token=d97886c0-4149-401f-a43d-3e64d448ebc4",
    about: `1993 Isparta doğumluyum. Lise eğitimimi Eğirdir Anadolu Lisesinde tamamladıktan sonra lisans ve yüksek lisans derecelerimi Hacettepe...`,
    about2: `1993 Isparta doğumluyum. Lise eğitimimi Eğirdir Anadolu Lisesinde tamamladıktan sonra lisans ve yüksek lisans derecelerimi Hacettepe Üniversitesi Kimya Bölümü'nden elde ettim. Şu anda Dow kimya firmasında Müşteri Hizmetleri Uzmanı olarak çalışıyorum. Seyahat etmek, yeni yerler keşfetmek, kamp ve trekking etkinlikleri, sulu boya resim yapmak hobilerim arasındadır. Zeka oyunlarına eşim sayesinde ilgi duymaya başladım. Benim için yeni ve bir o kadar da eğlenceli bir süreç. Her seferinde yeni bir bilgi, farklı bir çözüm öğrenmeme sebep oluyor ve bundan çok keyif alıyorum. Bu seneki yarışma sonucunda ilk 10'da yer almaktan dolayı da çok mutluyum. Düzenlediği bu etkinlikten dolayı TZV'ye teşekkür ederim.`,
  },
  {
    result: "Yetişkinler Dördüncüsü",
    name: "Murat Ak",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy4%20Murat_Ak.jpg?alt=media&token=bb3313e6-b0bb-4e81-91a0-ebb0364acd4d",
    about: `Murat Ak 1983 yılında Antalya'da doğdu. İlk ve orta öğrenimini Antalya'da tamamladı. Lise yıllarında karşılaştığı Matematiğin Aydınlık...`,
    about2: `Murat Ak 1983 yılında Antalya'da doğdu. İlk ve orta öğrenimini Antalya'da tamamladı. Lise yıllarında karşılaştığı Matematiğin Aydınlık Dünyası ve Dr. Ecco'nun Şaşırtıcı Serüvenleri kitaplarıyla matematik ve algoritmalara özel bir ilgi duymaya başladı. 2000 yılında ÖSS'de ilk 400'e girerek Bilkent Üniversitesi Bilgisayar Mühendisliği Bölümüne burslu olarak yerleşti. Lisansını bitirdikten sonra yüksek lisans ve doktorasını da aynı bölümde kriptografi üzerine yaparak mezun oldu. 2013 yılından beri Akdeniz Üniversitesi Bilgisayar Mühendisliği Bölümünde öğretim üyesi olarak çalışmaktadır. Lise yıllarında, bir başka deyişle henüz cevapların posta (dikkat, elektronik değil, fiziksel posta) ile gönderildiği 20. yüzyıldan bu yana Oyun ve Soru Maratonu yarışmalarına bir hobi olarak neredeyse her yıl katılmaktadır. Evli ve bir çocuk babasıdır. İleride oğluyla birlikte farklı kategorilerde de olsa yarışmayı planlamaktadır.`,
  },
  {
    result: "Yetişkinler Dördüncüsü",
    name: "Ahmed Faruk Koç",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy4%20ahmed.jpeg?alt=media&token=46d99c79-5176-4cf5-ad62-e5bb05f832db",
    about: `Merhaba, ben Ahmed Faruk KOÇ. Eğitim hayatımın büyük bir bölümünü Bursa’da geçirdim. Ortaokuldan itibaren matematik ve bilim olimpiyatlarına...`,
    about2: `Merhaba, ben Ahmed Faruk KOÇ. Eğitim hayatımın büyük bir bölümünü Bursa’da geçirdim. Ortaokuldan itibaren matematik ve bilim olimpiyatlarına katıldım. Lisede Bursa Fen Lisesinde öğrenim gördüm. TÜBİTAK Biyoloji olimpiyatlarına katıldım. Daha sonra Karabük Üniversitesinde Tıp Fakültesini kazandım. Şu an 5. Sınıf stajyer doktor olarak devam etmekteyim. Hobilerim arasında çoklu dil öğrenme, anlayarak hızlı okuma, akıl ve zeka oyunları, geometri,psikoterapiler, seyahat etme, masa tenisi, zeka oyunları çözme, müzik ve beste yapma, şarkı söyleme gibi çeşitli aktiviteler bulunmakta. Aynı zamanda kendi başıma öğrendiğim piyanoda ileri seviyede çalmaktayım. İçerik ve sosyal medya editörlüğü de yapmaktayım. TZV nin ilk yarışmasına lisede katıldım ve ilk yılda finale çıktım. Daha sonraki yıllarda da büyük bir keyifle yarışmalara girerek her yıl finalistler arasına girdim. Bu yıl ise yetişkinler kategorisinde 4. oldum. Gelecek yarışmalarda görüşmek üzere.`,
  },
  {
    result: "Yetişkinler Dördüncüsü",
    name: "Ömer Ayhan",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy4%20%C3%96mer%20Ayhan.jpg?alt=media&token=726cff79-95fd-47b5-b2dd-ae494b4ed908",
    about: `2000 Bursa doğumluyum. 
    Üniversiteye kadar eğitim öğretim hayatımı Bursa’da geçirdim. Ortaokulda çeşitli matematik olimpiyatlarına...`,
    about2: `2000 Bursa doğumluyum. 
    Üniversiteye kadar eğitim öğretim hayatımı Bursa’da geçirdim. Ortaokulda çeşitli matematik olimpiyatlarına, lisede Tübitak bilgisayar olimpiyatlarına katıldım. Akıl oyunları, gitar çalmak, yazılım ve masa tenisi hobilerim arasındadır. Şu an ODTÜ Elektrik - Elektronik Mühendisliği bölümünde okuyorum. 2012’de tanıştığım TZV Zeka Oyunları yarışmasına son 5 senedir düzenli olarak katılıyorum. Katıldığım her yıl finale kaldığım bu yarışmada 4. olarak şeref kürsüsünde yer almak beni çok gururlandırdı. Bu etkinliğin bir parçası olan bütün yarışmacıları tebrik ederim. Gelecek sene final aşamasını ve ödül törenini yüz yüze gerçekleştirmek dileğiyle.`,
  },
  {
    result: "Yetişkinler Beşincisi",
    name: "Serkan Oral",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy5%20IMG_20220905_150349.jpg?alt=media&token=c61e6c95-1184-4485-9f1a-e7c883bb1549",
    about: `1986 doğumluyum. Gümüşhaneliyim.  İlk ve orta öğrenimimi Ankara’da tamamladıktan sonra Orta Doğu Teknik Üniversitesi İnşaat Mühendisliği...`,
    about2: `1986 doğumluyum. Gümüşhaneliyim.  İlk ve orta öğrenimimi Ankara’da tamamladıktan sonra Orta Doğu Teknik Üniversitesi İnşaat Mühendisliği bölümünden 2012 yılında mezun oldum.  2015 yılına kadar Sağlık Bakanlığında mühendis olarak görev yaptım. Şu an Sanayi ve Teknoloji Bakanlığında Sanayi ve Teknoloji Uzmanı olarak görevimi sürdürmekteyim. 2013 yılında ömür boyu yolculuğa çıktığımız sevgili eşim Sahure ile Erkan ve Göktürk adında iki çocuğa sahibiz. Küçüklüğümden beri zeka oyunlarına, bulmacalara ve kriptolojiye ilgi duymaktayım. Yarışmalara katılmak ve futbol oynamak en sevdiğim aktivitelerdir.`,
  },
  {
    result: "Yetişkinler Altıncısı",
    name: "Barış Mutlu",
    medal: 6,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy6%2020220905_205945.jpg?alt=media&token=6b08ca45-35f9-4b82-b013-7caa4df3ce29",
    about: `İsmim Barış, İstanbulluyum. Makine mühendisliği okudum ve şu anda mobil oyunlar için kod yazıyorum. Kendimi çeşitli konularda test etmekten...`,
    about2: `İsmim Barış, İstanbulluyum. Makine mühendisliği okudum ve şu anda mobil oyunlar için kod yazıyorum. Kendimi çeşitli konularda test etmekten ve geliştirmeye çalışmaktan büyük keyif alıyorum. Bu yazıyı okuyan herkesi kendine ufak bir hedef koymaya davet ediyorum. İster zeka ile ilgili olsun, ister fiziksel. İsterseniz kurabiye pişirip tanımadığınız bir kişiye ikram edin. Dik durmayı unutmayın, keyifli bir gun dilerim :)`,
  },
  {
    result: "Yetişkinler Yedincisi",
    name: "Alihan Ağaya",
    medal: 7,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy7%20Alihan%20A%C4%9Faya.jpg?alt=media&token=dab1e57e-f082-4499-8e55-2dfb4af0fd8b",
    about: `1995 İzmir doğumluyum. Lise eğitimimi Çimentaş Anadolu Lisesi’nde tamamladım. 2014 yılında Pamukkale Üniversitesi Eğitim Fakültesi İlköğretim...`,
    about2: `1995 İzmir doğumluyum. Lise eğitimimi Çimentaş Anadolu Lisesi’nde tamamladım. 2014 yılında Pamukkale Üniversitesi Eğitim Fakültesi İlköğretim Matematik Öğretmenliği bölümüne başladım. 2018 yılında mezun oldum. Artvin’de ilköğretim matematik öğretmeni olarak çalışmaktayım. Zeka sorularını çözmeyi seviyorum. Bu seneki sorular gayet güzeldi. İlk 10’a girmek beni mutlu etti.`,
  },
  {
    result: "Yetişkinler Sekizincisi",
    name: "Yiğit Şahinkoç",
    medal: 8,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Yetişkinler Sekizincisi",
    name: "Devran Torun",
    medal: 8,
    photo: "",
    about: ``,
    about2: ``,
  },
  {
    result: "Yetişkinler Dokuzuncusu",
    name: "Seyfullah Yürük",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy9%20Seyfullah.jpg?alt=media&token=37877507-4c32-416e-8afc-394ada1319fd",
    about: `1 Aralık 1989 tarihinde Ankara/Çankaya’da doğdu. İlkokul ve ortaokulu Bafra’da, liseyi Samsun’da okudu. 2006 yılındaki ÖSS sınavında...`,
    about2: `1 Aralık 1989 tarihinde Ankara/Çankaya’da doğdu. İlkokul ve ortaokulu Bafra’da, liseyi Samsun’da okudu. 2006 yılındaki ÖSS sınavında EA puan türünde Türkiye 40.’sı oldu ve Bilkent İşletme’de 2 yıl okudu. 2008 yılında tekrar girdiği ÖSS sınavı sonucunda kazandığı TOBB ETÜ İktisat’tan 2013 yılında mezun oldu. Bu süre zarfında çeşitli şirketlerde bir yıla yakın staj yaptı. Mezun olduktan kısa süre sonra ÖYP kapsamında yaptığı tercihle Eskişehir Osmangazi Üniversitesi İktisat Bölümü’ne Araştırma Görevlisi olarak atandı. Aynı üniversitede yüksek lisansını tamamladıktan sonra, 2017 yılında Ankara Üniversitesi’nde İktisat alanında Doktora programına başladı. Halen doktora tez yazım sürecini sürdürmektedir. Akademik ilgi alanları seçim sistemleri analizi, davranışsal iktisat ve yapay zekâ uygulamalarıdır. Akademi dışındaki ilgi alanlarının başında ise futbol oynamak, premier lig başta olmak üzere spor müsabakaları izlemek, kamp yapmak ve bisiklet sürmek gelmektedir. Yine bir Araştırma Görevlisi olan Esma Yürük ile 2014 yılından beri evli olup, 2016 yılında dünyaya gelen Gülce Yürük’ün babasıdır.`,
  },
  {
    result: "Yetişkinler Dokuzuncusu",
    name: "Türkmen Göksel",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy9%20T%C3%BCrkmen%20G%C3%B6ksel.jpg?alt=media&token=88fd0fd5-efd6-49d2-99c9-21da19cc717a",
    about: `26 Kasım 1979 tarihinde Mersin’de doğdu. İlkokul eğitimini Mersin’de, Ortaokul ve Lise eğitimini Tarsus Amerikan Koleji’nde...`,
    about2: `26 Kasım 1979 tarihinde Mersin’de doğdu. İlkokul eğitimini Mersin’de, Ortaokul ve Lise eğitimini Tarsus Amerikan Koleji’nde tamamladı. Ankara Üniversitesi Siyasal Bilgiler Fakültesi (Mülkiye) İktisat Bölümü’nü 2002 yılında dereceyle bitirdi. Branşında birincilikle kazandığı Yükseköğretim Kurulu Bursuyla 2004-2009 yılları arasında ABD’de Minnesota Üniversitesi’nden Ekonomi alanında Yüksek Lisans ve Doktora dereceleri aldı. 

    2002 yılının Kasım ayında Mülkiye’de İktisat Bölümü’nde araştırma görevlisi olarak akademik hayata adım attı. 2011 yılında (eski adıyla) Yardımcı Doçent, 2013 yılında Doçent ve 2020 yılında Profesör ünvanlarını aldı. Aynı fakültede 2019 yılında Politika ve Ekonomi Bölümü’nün kuruculuğuna öncülük etti ve halen bu bölümde kurucu bölüm başkanlığı görevini sürdürmektedir. Minnesota, Ankara ve Boğaziçi Üniversite’lerinde çeşitli düzeylerde dersler verdi.
    
    Farklı dönemlerde Türkiye Ekonomi Kurumu Yönetim Kurulu üyeliği görevlerinde bulunmuştur. Yurt içi ve yurt dışında yayımlanmış çok sayıda bilimsel makale ve kitabı bulunmaktadır. Akademik ilgi alanları matematiksel modelleme, hesaplamalı iktisat, yapay zekâ uygulamaları ve seçim sistemleri analizidir. Akademi dışındaki ilgi alanlarının başında ise futbol oynamak, izlemek ve zekâ oyunları çözmek gelmektedir. Sıkı bir Beşiktaş taraftarıdır. TZV’nin 2001’de düzenlediği Soru Maratonu yarışmasında (ortak) birincilik derecesi, 26. Zekâ Oyunları yarışmasında ise (2021) dördüncülük derecesi elde etmiştir. 
    Ressam olan Senem Göksel ile 2013 yılından beri evli olup, 2016 yılında dünyaya gelen Yusuf Alp Göksel’in babasıdır.`,
  },
  {
    result: "Yetişkinler Onuncusu",
    name: "SEÇKİN ALTINEL",
    medal: 10,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fy10%20se%C3%A7kin.jpg?alt=media&token=52eaba81-f6d7-4dff-bbf2-d9e3c748e327",
    about: `1981 Edirne doğumluyum. Ankara Üniversitesi Siyasal Bilgiler Fakültesi (Mülkiye) İktisat bölümü mezunuyum. Ankara Üniversitesi Hukuk Fakültesi’nde...`,
    about2: `1981 Edirne doğumluyum. Ankara Üniversitesi Siyasal Bilgiler Fakültesi (Mülkiye) İktisat bölümü mezunuyum. Ankara Üniversitesi Hukuk Fakültesi’nde yüksek lisans yaptım. Yazılım Mühendisliği alanında da yüksek lisans yapıyorum. Maliye Bakanlığı’nda Hesap Uzmanı olarak çalıştım. Halen Yeminli Mali Müşavir ve Bağımsız Denetçi olarak, yerli ve yabancı firmalara, ulusal ve uluslararası alanlarda vergi planlama, denetim ve teşvik danışmanlığı yapıyorum. Matematiğe, zeka oyunlarına, sorulara farklı çözüm yolları bulmaya karşı küçüklüğümden beri ilgim var. Çalıştığım kurumlar ve yaptığım mesleğim gereği de, analitik düşünme ve problem çözme yeteneğimi sürekli kullanma ve geliştirme fırsatı buluyorum. Satranç turnuvalarında elde ettiğim başarılar sonrası Türkiye Satranç Milli Takımı’nda oynadım. Türkiye Hafıza Şampiyonası’nda 6 yıl boyunca çeşitli dallarda Türkiye Şampiyonluğum var. Dünya Hafıza Olimpiyatlarında Türkiye Milli Takımı üyesi olarak yer aldım. Futbol oynamaya ve izlemeye karşı da özel bir tutkum var. Türkiye Mini Futbol (Halısaha) Milli Takımı kaptanıyım. Bu yıl Slovakya’da düzenlenen Avrupa Şampiyonası’nda da milli takım kaptanı olarak yer aldım.`,
  },
];

const emektarlar2022 = [
  {
    result: "Emektarlar Birincisi",
    name: "hakan çetin",
    medal: 1,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe1%20hakan%20cetin.jpeg?alt=media&token=71008a50-912c-4575-a937-83aaea885ea1",
    about: `1971 Erzincan Refahiye doğumluyum. K.T.Ü Biyoloji öğretmenliğinden mezunum. Eğitim yönetimi ve denetimi alanında yüksek lisansım var.`,
    about2: `1971 Erzincan Refahiye doğumluyum. K.T.Ü Biyoloji öğretmenliğinden mezunum. Eğitim yönetimi ve denetimi alanında yüksek lisansım var. Meslekte 28 yılımı tamamladım. Dört yılı Van olmak üzere Kocaeli’nin çeşitli okullarında çalıştım. Son olarak Kocaeli Çayırova Güzeltepe Ortaokulunda müdür yardımcısı  olarak çalışmaktayım. Evli iki çocuk babasıyım. Geçtiğimiz yıl yapılan yarışmada ikincilik derecem var.Çözebileceğime inandığım ve zihnimi zorlayan problemlerle uğraşmaktan büyük keyif alırım. Okumayı,doğa yürüyüşlerini severim. Çevre konusunda oldukça duyarlıyım.`,
  },
  {
    result: "Emektarlar İkincisi",
    name: "Funda Kartaloğlu",
    medal: 2,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe2%20funda.jpg?alt=media&token=a957315b-22be-4717-b9cc-87a286ac864d",
    about: `1972 Ankara doğumluyum. İlk, orta, lise eğitimimi İzmir'de tamamladıktan sonra, 1993 yılında Ege Üniversitesi, Mühendislik Fakültesi...`,
    about2: `1972 Ankara doğumluyum. İlk, orta, lise eğitimimi İzmir'de tamamladıktan sonra, 1993 yılında Ege Üniversitesi, Mühendislik Fakültesi, Tekstil Mühendisliği bölümünden mezun oldum. 2003 yılında oğlum doğana kadar Talya Tekstil Ltd. Sti'nde üretim müdürlüğü yaptım. 2021 yılında da Anadolu Üniversitesi Açıköğretim Fakültesi, Web Tasarım ve Kodlama bölümünü bitirdim. Çocukluğumdan beri matematik,  zeka oyunları ve bulmacalara karşı olan ilgim, son yıllarda daha da artarak, yarışmalara katılmaya, hatta profesyonel işlere destek vermeye başladım. 25. Oyun yarışmasında yetişkinler 7.'si oldum. Bir erkek çocuk annesiyim. Oğlumun eğitiminden ötürü yılın büyük çoğunluğu Ankara'daki ikamet etmekteyim. Herkese bol oyunlu günler dilerim.`,
  },
  {
    result: "Emektarlar İkincisi",
    name: "Sinan Bastırmacı",
    medal: 2,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe2%20sinan%20b.jpeg?alt=media&token=40a8bd50-1cf5-47fe-b590-4802351f9f0f",
    about: `1959 Samsun doğumluyum. Aslen Orduluyum.  Adana’da yaşıyorum. İlk, orta ve lise eğitimimi Adana’da tamamladım. ODTÜ Makine Mühendisliği...`,
    about2: `1959 Samsun doğumluyum. Aslen Orduluyum.  Adana’da yaşıyorum. İlk, orta ve lise eğitimimi Adana’da tamamladım. ODTÜ Makine Mühendisliği lisans mezunuyum. İş hayatımın tamamı özel sektörde geçti. Şu anda emekliyim. Cemal ve Bahar adında iki çocuk, Bade adında bir torun sahibiyim. Başlıca hobilerim; futbol oynamak, yürüyüş yapmak ve yüzmektir. Zekâ oyunları ile yaklaşık 20 yıldır ilgileniyorum. Geçen yıl, Emrehan Halıcı’nın yönettiği Puzzle-up oyunlarına da katılmıştım. Oradaki derecem 45.lik olmuştu. Tzv’nin yarışmasına ilk defa katılıyorum. Her yaştan, büyük bir kesime hitap eden çok zevkli ve faydalı bir yarışma. Tüm Tzv ekibine teşekkürler.`,
  },
  {
    result: "Emektarlar Üçüncüsü",
    name: "Mert-Er KARAGÜLLE",
    medal: 3,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe3%20mert%20er.jpg?alt=media&token=82a996a7-cc2b-42ed-8c0e-0710a8be2f02",
    about: `1968 İstanbul doğumlu. Pertevniyal Lisesi (1984) ve İ.Ü. Hukuk Fakültesi (1988) mezunu. Avukatlık Stajını tamamladığı 1989 yılından bugüne İstanbul’da...`,
    about2: `1968 İstanbul doğumlu.
    Pertevniyal Lisesi (1984) ve İ.Ü. Hukuk Fakültesi (1988) mezunu.
    Avukatlık Stajını tamamladığı 1989 yılından bugüne İstanbul’da serbest avukatlık yapmakta.
    1993’te Çağdaş Hukukçular Derneği İstanbul Şubesi Yönetim Kurulu Üyeliği yaptı.
    1996-2000 yılları arasında İstanbul Barosu Yönetim Kurulu Üyesi ve Genel Sekreter olarak görev aldı.
    Bu görevi sırasında, TBMM Avukatlık Yasası Alt Komisyonu’nda İstanbul Barosu’nu temsil etti.
    1997–2019 yıları arasında İstanbul Barosu Staj Eğitim Merkezi’nde “Avukatlık Hukuku” ve “İdare Hukuku” eğitimleri verdi, Tez Danışmanlığı ve Jüri Üyeliği görevleri üstlendi.
    2000-2002 döneminde İstanbul Barosu Bilişim Merkezi Koordinatörlüğü ve Türkiye Barolar Birliği Bilişim Komisyonu üyeliğinde bulundu.
    Mehmet Yavuz Hukuk ve Adalet Vakfı Yönetim Kurulu Üyesi.
    Pertevniyal Eğitim Vakfı Genel Sekreteri.
    Evli ve bir oğlu var.`,
  },
  {
    result: "Emektarlar Dördüncüsü",
    name: "Erol Akın",
    medal: 4,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe4%20ErolAkin_Emektar_Foto.jpg?alt=media&token=afd40b69-6450-43fb-aae1-2362406100e6",
    about: `Erol Akın 1960 yılında İzmir'de doğmuştur. Ege Üniversitesi Fen Fakültesi Astronomi-Matematik bölümünü bitirdikten sonra, üniversiteden...`,
    about2: `Erol Akın 1960 yılında İzmir'de doğmuştur. Ege Üniversitesi Fen Fakültesi Astronomi-Matematik bölümünü bitirdikten sonra, üniversiteden aldığı RPG kursu ve kendi çabalarıyla öğrendiği Basic dili aracılığıyla bilgisayar programcısı olarak çalışmaya başlamıştır. 33 yıllık çalışma hayatının ilk 2,5 ve son 25,5 yılı aynı şirkette geçirmiştir ve bilgi işlem müdürü pozisyonundan emekliye ayrılmıştır. İngilizce ve Almanca bilmektedir. Evlidir; 2 çocuk ve 2 torun sahibidir. 
    Erol Akın bilgisayarı hayatının vazgeçilmez bir parçası olarak görmektedir. Üniversiteden yeni mezun olan ve iş arayan gençlerin çok iyi düzeyde MS Excel ve SQL öğrenebilmeleri için kolay takip edilebilen ve eğlenceli bir çevrimiçi eğitim hazırlama hayali vardır. Türkiye Zeka Vakfı'nın yarışmalarındaki sorular ve bu soruların bilgisayar ile yapılabilecek çözümlerinin de bu eğitimde yer alması Türkiye Zeka Vakfı yarışmalarına katılmasının ana sebeplerinden birisidir.`,
  },
  {
    result: "Emektarlar Beşincisi",
    name: "Metin Örsel",
    medal: 5,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe5%20Metin%20%C3%96rsel.jpg?alt=media&token=b03c97bb-71a9-4cb6-9b64-cd574027b1d2",
    about: `1969 İstanbul doğumlu, Boğaziçi Üniversitesi mezunu, Bilgisayar Mühendisi. Beykent Üniversitesi’nden MBA’i var. Devlet okulları birincileri...`,
    about2: `1969 İstanbul doğumlu, Boğaziçi Üniversitesi mezunu, Bilgisayar Mühendisi. Beykent Üniversitesi’nden MBA’i var. Devlet okulları birincileri arasından seçilerek 2 sene burslu olarak Amerika’da okumuştur.
    Çoğunlukla BT sektöründe olmak üzere, 25 yılı aşkın süre; yerel, bölgesel ve global rollerde çalıştıktan sonra, şimdi de Medipol Sağlık Grubu’nun üniversite ve hastanelerindeki BT proje portföyünü yönetiyor.
    Bulmaca merakı, 1982 yılında bir arkadaşının evinde gördüğü ilk Rubik kübü ile başlamış ve o günden beri artarak devam etmiştir. Üniversite yıllarında NxNxN Rubik kübünü çözen bilgisayar programını yaptığında renkli bir monitörü dahi yoktu!... Özellikle 3 boyutlu topoloji sorularını sever. Soru hazırlayıcılara çok saygı duyar. Alternatif cevaplar bulmak, soru hazırlayıcıya gol atmak  için uğraşır!
    1984’ten beri; akademik, genel kültür, zeka oyunu, bulmaca vb; yurtiçi ve yurtdışı, bulduğu her yarışmaya girmiştir. 6 defa Türkiye Beyin Takımı üyeliğinin yanı sıra, TZV’de de 2 derecesi vardır. Bireyselde, Dünya 22.’liğine kadar çıkmıştır.
    2010 yılında Budapeşte’de yapılan 24 saatlik yarışmanın arasına sıkıştırılmış biçimde TZV yarı finalini de uzaktan yaparak, ‘En uzun süre aralıksız zeka yarışmaları soruları çözen’ kişi unvanı kazanmışsa da, önceden resmi delegasyona haber verilmediği için Guinness Rekorlar Kitabı’na girememiştir.`,
  },
  {
    result: "Emektarlar Altıncısı",
    name: "MEHMET BOLAK",
    medal: 6,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe6%20Mehmet%20Bolak.jpg?alt=media&token=e7398249-bd2d-4d33-928d-35928b736f0a",
    about: `1956 İstanbul doğumluyum. 1967 yılında sınavı birincilikle kazanarak Galatasaray Lisesi’ne girdim. 1975 yılında liseden mezun...`,
    about2: `1956 İstanbul doğumluyum. 1967 yılında sınavı birincilikle kazanarak Galatasaray Lisesi’ne girdim. 1975 yılında liseden mezun olduktan sonra İ.T.Ü. Makina Fakültesi Sanayi Mühendisliği bölümünü gene en yüksek puanlı, dolayısıyla 1 numaralı öğrenci olarak kazandım. Lisans eğitimimi tamamladıktan sonra İstanbul Teknik Üniversitesi’nde işletme üzerine yüksek lisans yaptım ve 1982 yılında araştırma görevlisi olarak akademik hayata atıldım. Gene işletme alanında doktora diplomamı aldıktan sonra 1999 yılı sonuna kadar İ.T.Ü.’de, 2000 – 2013 yılları arasında Galatasaray Üniversitesi’nde finansal yönetim, risk yönetimi, menkul kıymetler yönetimi gibi dersler verdikten sonra 2013 yılında emekli oldum. Halen emekli öğretim üyesi olarak Galatasaray Üniversitesi’nde ders vermeye devam etmekteyim. Hobilerim arasında puzzle yapmak, bulmaca çözmek ve başta polisiye romanlar olmak üzere kitap okumak bulunmaktadır. TZV’nin düzenlediği bu oyunlara uzun yıllardır ilgi gösterip katılım sağlamaktayım. İlk defa dereceye girmeyi başarabildiğim için mutluyum.
    Prof. Dr. Mehmet BOLAK`,
  },
  {
    result: "Emektarlar Yedincisi",
    name: "Ali İsmail TARHAN",
    medal: 7,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe7%20Ali_Ismail_TARHAN_Foto.png?alt=media&token=49f75348-810e-481c-8ede-10d2a8849b90",
    about: `1971 Ankara doğumluyum. ODTÜ Makina Mühendisliği mezunuyum. Yanlış hatırlamıyorsam 1995 yılında ODTÜ'de düzenlenen bir zeka oyunları...`,
    about2: `1971 Ankara doğumluyum. ODTÜ Makina Mühendisliği mezunuyum. Yanlış hatırlamıyorsam 1995 yılında ODTÜ'de düzenlenen bir zeka oyunları etkinliğiyle başlayan Zeka Oyunları serüvenim daha sonra TZV'nin düzenlediği oyunlarla günümüze kadar aralıksız devam etti. Artık finallere kalınca yaklaşık 20 yıl önce ilk finallere kaldığım zamanki gibi sevinçten odanın tavanına değmiyorsam da (o zamanlar finallere yaklaşık 10-20 kişi kalırdı ve ben artık istesem de tavana değemiyorum) hala soruları çözmenin verdiği haz değişmedi. Zamanla arabada kırmızı ışıkta beklerken bir zeka sorusunu kafanızdan çözmek, çıktığınız merdivenleri ya da filmin başlamasını beklerken sinemada kaç koltuk olduğunu gayri ihtiyari saymak kadar doğal bir hale geliyor.`,
  },
  {
    result: "Emektarlar Sekizincisi",
    name: "Hasan Vural",
    medal: 8,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe8%20hasan%20vural.jpeg?alt=media&token=4ca8ec5e-ed4e-41ea-9e55-1d05324cafa4",
    about: `Hep birlikte Emrehan Halıcıyı severek takip eden bir ailede, uyku öncesi masal anlatılarak değil malesef bu tür sorular çözmeye çalışarak büyütüldüm.`,
    about2: `Hep birlikte Emrehan Halıcıyı severek takip eden bir ailede, uyku öncesi masal anlatılarak değil malesef bu tür sorular çözmeye çalışarak büyütüldüm.

    İstanbul Atatürk Fen Lisesi, ve ODTÜ Elektrik Elektronik  Müh. 1996 mezunuyum. 
    
    28 yıldır sağlık sektöründe, kardiyoloji odaklı tıbbi cihaz teknolojisi konusunda çalışmaktayım.
    Sürekli yeni şeyler öğrenebildiğim, insanların hayatlarına dokunabildiğim, ilham verici ve özellikle şaşırabildiğim, hayata farklı bakabildiğim güzellikte her şey ilgi alanımdadır.
    
    Bu yıl Emektarlar kategorisine yükselinceye kadar dereceye girememiş olsam da oyunlara her yıl düzenli olarak tutkuyla, heyecanla katılmaktayım:)
    
    Emeği geçen herkese teşekkürler.
    Hasan Vural`,
  },
  {
    result: "Emektarlar Dokuzuncusu",
    name: "Selçuk Saraç",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe9%20selcuk_sarac.jpg?alt=media&token=58f9eafe-7d2b-4744-8799-0b009a3445bd",
    about: `1972 yılında Bursa’da doğdum. Bursa’da, Ankara’da, İstanbul’da yaşadım. Makine Mühendisiyim. 20 yıl Eczacıbaşı’nda çalıştıktan sonra...`,
    about2: `1972 yılında Bursa’da doğdum. Bursa’da, Ankara’da, İstanbul’da yaşadım. Makine Mühendisiyim. 20 yıl Eczacıbaşı’nda çalıştıktan sonra, son 3,5 yıldır Buga Otis Asansör firmasında yönetici olarak çalışmaktayım. Evliyim. Defne ve Demir’in babasıyım. Zihni ve bedeni güçlü tutmak için sporun ve zeka oyunlarının önemine inanırım. Eski bir basketbolcu, 10 yıllık maratoncuyum. Bazıları tam maraton olmak üzere, 50’den fazla maratonda koştum. Bu koşuların bazılarında bağış toplayarak ODTÜ Mezunlar Derneği Burs Havuzu, KAÇUV, TEV, Tohum Otizm gibi STK’lara destek olmaya çalıştım.
    Zeka oyunlarına olan ilgim lise yıllarında başlamış olsa da, hayatımın bir parçası oluşu ODTÜ yurtlarına denk gelir. Uzun yurt gecelerinde arkadaş sohbetlerimizin en hararetli bölümleri zeka sorularıyla geçerdi. Yurt arkadaşlıklarımız, 30 yıllık dostluklara dönüşmesinde de bu ortak noktamızın etkisi büyüktür. Bu yıl ‘Emektarlar’ bölümüne terfi etmekten cesaretle, gençlere, birlikte soru çözmekten keyif aldıkları dostlar edinip bu dostlukları sürdürmelerini tavsiye ederim. 
    TZV Oyun yarışmalarına çeşitli kereler katıldım. 2015 yılında ikinci olmanın heyecanını yaşadım. TZV Oyun organizasyonunu Türkiye için çok önemli buluyor, zeka oyunlarının sürekliliğinde çok önemli olduğuna inanıyorum. Yarışmaya düzenlendiği sürece katılmayı düşünüyorum.
    `,
  },
  {
    result: "Emektarlar Dokuzuncusu",
    name: "Emre Özbağcı",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe9%20Emre%20Ozbagc%C4%B1.jpg?alt=media&token=86ca39eb-819c-444e-a119-eb51d3d2d25a",
    about: `1972 yılında Konya’da doğdum. İlkokul ve ortaokulu Konya’da tamamladıktan sonra 1987 senesinde Ankara Fen Lisesine başladım.`,
    about2: `1972 yılında Konya’da doğdum. İlkokul ve ortaokulu Konya’da tamamladıktan sonra 1987 senesinde Ankara Fen Lisesine başladım. Fen lisesini 1990 senesinde bitirip ODTÜ Elektrik-Elektronik bölümüne girdim. 1994 yılında üniversiteden mezun olup çalışma hayatıma başladım. Önce bir telekomuniskasyon firmasında 3 yıl ve sonrasında da bir büyük bir bankanın bilgi işlem müdürlüğünde 4 yıl çalıştım. Aynı esnada ODTÜ de tam zamanlı MBA programına kaydoldum ve 1999 senesinde mezun oldum.  2001 senesinde başladığım proje yönetimi, mühendislik ve müşavirlik hizmetleri sunan uluslararası bir firmada çeştili görevlerde çalıştıktan sonra halen ülke yöneticisi olarak çalışmaya devam etmekteyim. Evliyim, bir kızım, bir oğlum var. 

    Matematik ve zeka oyunlarına çocukluğumdan beri ilgi duymaktayım. Zeka oyunları yarışmalarını ilk yıllarında takip etmiştim ama  2015 senesinden beri düzenli olarak katılmaktayım. Bu sene Emektarlar kategorisine terfi ettim :). Bundan sonraki senelerde de fırsat buldukca keyifle katılmayı diliyorum. `,
  },
  {
    result: "Emektarlar Dokuzuncusu",
    name: "Şerife Şenlik",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe9%20%C5%9Eerife%20%C5%9Eenlik.jpeg?alt=media&token=9d8a4b31-faa6-4c38-a0de-8647d36c9e4b",
    about: `Konya doğumluyum. Ankara Fen Lisesi, ODTÜ Matematik ve Marmara Üniversitesi Matematik Öğretmenliği yüksek lisans mezunuyum. `,
    about2: `Konya doğumluyum. Ankara Fen Lisesi, ODTÜ Matematik ve Marmara Üniversitesi Matematik Öğretmenliği yüksek lisans mezunuyum. 
 
    İstanbul’da yaşıyorum. Evli ve iki çocuk annesiyim. 
     
    Bir süre bankacılık sektöründe çalıştıktan sonra öğretmenlik yapmanın beni daha mutlu edeceğine karar verdim. Şu anda Sarıyer Ali Akkanat Anadolu Lisesinde Matematik öğretmenliği yapmaktayım. 
     
    Hobilerimin en başında bilmeceler ve zeka soruları çözmek yer alıyor. Türkiye Zeka Oyunlarını ilk gününden itibaren ilgiyle takip ediyorum.
    `,
  },
  {
    result: "Emektarlar Dokuzuncusu",
    name: "Türker Tekten",
    medal: 9,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe9%20Turker.png?alt=media&token=9fa732d6-f77d-4cbd-a56f-67c558182e7a",
    about: `1970 Niğde doğumluyum. Küçük yaşlardan itibaren öğretmen olan babamın sayesinde zeka ve matematik sorularına hep meraklı oldum.`,
    about2: `1970 Niğde doğumluyum. Küçük yaşlardan itibaren öğretmen olan babamın sayesinde zeka ve matematik sorularına hep meraklı oldum. 1992 Bilkent Endüstri Mühendisliği mezuniyeti sonrası bankacılık / finans alanlarında çalıştım. 45 yaş sonrası artık sadece iş hayatındaki sorunlarla uğraşmak yerine ve de aklımı dinç tutmak için gençlik merakım olan zeka sorularıyla tekrar uğraşmaya başladım. Bu arada benzer sebeplerle İstanbul Üniversitesi Felsefe Bölümünde (açık ve uzaktan eğitim) okudum ve geçen sene mezun oldum. Zeka oyunlarını özellikle belli bir yaşın üzerinde ve bugüne kadar çoğunlukla mesleğiyle uğraşmış olan herkese tavsiye ederim. `,
  },
  {
    result: "Emektarlar Onuncusu",
    name: "Mehmet Zirek",
    medal: 10,
    photo:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fstudent_pictures%2Fe10%20mehmet%20zirek.jpg?alt=media&token=21ad57e4-eed8-4eed-855a-8c65ad3fc73f",
    about: `1965 Ankara doğumluyum. 1974-82 yılları arasında Robert Kolej’de okuduktan sonra 1982 yılında İstanbul Teknik Üniversitesi Elektronik ve Haberleşme...`,
    about2: `1965 Ankara doğumluyum. 1974-82 yılları arasında Robert Kolej’de okuduktan sonra 1982 yılında İstanbul Teknik Üniversitesi Elektronik ve Haberleşme Mühendisliği bölümüne girdim. Mezuniyet sonrası Türkiye Radyo ve Televizyon kurumunda altı yıl mühendis olarak görev yaptım. Bunu takiben çeşitli uluslararası şirketlerde mühendislik ve yöneticilik yaptıktan sonra, yurtdışı görevim gereği geldiğim Arnavutlukta akademik kariyere devam etmeye karar verdim. 2013 yılında. Arnavutluk'taki Epoka Üniversitesi'nde Ekonomi ve İşletme Yönetimi alanında Master yaptım. 
    2017 yılında Arnavutluk, Tiran’a yerleştim ve burada yaşamaya başladım. Matematik, bilgisayar programlama dilleri, blok zincir gibi konularla özel olarak ilgileniyor, bu konularda kitaplar okuyarak bilimsel gelişmeleri ve haberleri takip etmeye çalışıyorum. Şu anda Tiran Metropolitan Üniversitesi'nde tam zamanlı olarak Veri Bilimi, Veri Madenciliği, Finans Mühendisliği  teori ve uygulamaları üzerine  dersler vermekteyim. 
    `,
  },
];

const allUsers = [
  { title: "MİNİKLER", users: minikler2022 },
  { title: "ÇOCUKLAR", users: cocuklar2022 },
  { title: "GENÇLER", users: gencler2022 },
  { title: "YETİŞKİNLER", users: yetiskinler2022 },
  { title: "EMEKTARLAR", users: emektarlar2022 },
];

function HallOfFame2022({ selectedUser, onShowFull, onModalClose }) {
  return (
    <div className="hallOfFame">
      {header}
      {allUsers.map((category) => (
        <HallOfFameCategoryBox
          key={"category_box" + category.title}
          title={category.title}
          users={category.users}
          onShowFull={onShowFull}
        />
      ))}
      {selectedUser && (
        <HallOfFameUserModal
          selectedUser={selectedUser}
          onModalClose={onModalClose}
        />
      )}
    </div>
  );
}

export default HallOfFame2022;
