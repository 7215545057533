import React, { useState } from "react";

import "./appBar.css";
import Button from "@mui/material/Button";
import {
  Routes,
  Route,
  Outlet,
  Link,
  BrowserRouter as Router,
} from "react-router-dom";
import AppContext from "../../../AppContext";
import { fbLogout } from "../../../db/firebaseMethods";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

export default function AppBar() {
  const { user } = React.useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutButton = user && (
    <Link className="link" to="/">
      <button
        className="menuButtons"
        onClick={() => {
          fbLogout(user?.uid);
        }}
      >
        {"ÇIKIŞ"}
      </button>
    </Link>
  );

  const verticalMenu = (
    <div className="vertical-menu">
      <button
        className="menuButtons"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ fontSize: "xlarge" }}
      >
        <MenuIcon />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          {generateButton("/", "ANA SAYFA")}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          {generateButton("/about", "HAKKINDA")}
        </MenuItem>

        <MenuItem onClick={handleClose}>
          {generateButton("/history", "GEÇMİŞ")}
        </MenuItem>

        <MenuItem onClick={handleClose}>
          {generateButton("/hall_of_fame", "ŞEREF KÜRSÜSÜ")}
        </MenuItem>

        <MenuItem onClick={handleClose}>
          {generateButton("/help", "YARDIM")}
        </MenuItem>
        {!user && (
          <MenuItem onClick={handleClose}>
            {generateButton("/login", "GİRİŞ")}
          </MenuItem>
        )}
        {!user && (
          <MenuItem onClick={handleClose}>
            {" "}
            {generateButton("/signup", "KAYIT")}
          </MenuItem>
        )}
        {user && (
          <MenuItem onClick={handleClose}>
            {generateButton("/profile", "YARIŞMA SAYFASI")}
          </MenuItem>
        )}
        {user && user.role === "admin" && (
          <MenuItem onClick={handleClose}>
            {generateButton("/admin", "YÖNETİM")}
          </MenuItem>
        )}
        {user && (
          <MenuItem onClick={handleClose}>
            {" "}
            {/* {generateButton("/", "ÇIKIŞ")} */}
            {logoutButton}
          </MenuItem>
        )}
      </Menu>
    </div>
  );

  const horizontalMenu = (
    <div className="menu">
      <div>
        {generateButton("/", "ANA SAYFA")}
        {generateButton("/about", "HAKKINDA")}
        {generateButton("/history", "GEÇMİŞ")}
        {generateButton("/hall_of_fame", "ŞEREF KÜRSÜSÜ")}
        {generateButton("/help", "YARDIM")}
      </div>

      <div>
        {!user && generateButton("/login", "GİRİŞ")}
        {!user && generateButton("/signup", "KAYIT")}
        {user && user.role === "admin" && generateButton("/admin", "YÖNETİM")}
        {user && generateButton("/profile", "YARIŞMA SAYFASI")}
        {/* {user && generateButton("/", "ÇIKIŞ")} */}
        {user && logoutButton}
      </div>
    </div>
  );

  return window.innerWidth > 600 ? horizontalMenu : verticalMenu;
}

const generateButton = (to, label, onClickFunction) => {
  return (
    <Link to={to}>
      <button className="menuButtons" onClick={onClickFunction}>
        {label}
      </button>
    </Link>
  );
};
