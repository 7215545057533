import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./signupPage.css";
import { createAuthUser } from "../../db/firebaseMethods";
import AppContext from "../../AppContext";
import { Navigate, Link } from "react-router-dom";
import LoadingBlock from "../../components/organisms/loadingBlock/loadingBlock";
import { FormGroup, Modal } from "@mui/material";
import { KVKK_DOC } from "../../common/kvkk";

export default function SignUpPage() {
  const [isLoading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [kvkkChecked, setKvkkChecked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setCentralState, user, activeProduct } = React.useContext(AppContext);
  if (user) return <Navigate replace to="/profile" />;

  const addUser = async (email, pass) => {
    if (!activeProduct) {
      alert("Kayıt süreci sona ermiştir, bir sonraki sezonda görüşmek üzere.");
      return;
    }
    console.log({ activeProduct });
    setLoading(true);
    const res = await createAuthUser(email, pass, activeProduct.order_id);
    if (res.uid) {
      setCentralState({
        user: res,
      });
    }
    setLoading(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    let values = {
      email: "",
      password: "",
      password2: "",
      // firstName:"",
      // lastName:"",
      // birthYear:""
    };

    Object.keys(values).forEach((k) => {
      values[k] = data.get(k)?.trim();
    });

    if (values.password.length < 6)
      alert("Şifre en az 6 karakter uzunluğunda olmalıdır.");
    else if (values.password !== values.password2) {
      alert("Girdiğiniz şifreler uyuşmuyor! Tekrar giriniz.");
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(values.email))
      alert("Lütfen geçerli bir e-posta adresi yazınız.");
    else {
      addUser(values.email, values.password);
    }

    console.log(values);
  };

  return (
    <Container component="main" maxWidth="xs">
      {isLoading && <LoadingBlock />}
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>

        {/* <Typography component="h1" variant="h5">
          OYUN 2023 kayıtları sona ermiştir. Şampiyona sayfasına gitmek için{" "}
          <a
            href="https://sampiyona.tzv.org.tr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            tıklayınız.
          </a>
        </Typography> */}

        <Typography component="h1" variant="h5">
          YENİ KAYIT
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="E-Posta"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Şifre"
                type="password"
                id="password"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password2"
                label="Şifre Tekrar"
                type="password"
                id="password2"
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={kvkkChecked}
                onChange={(e) => setKvkkChecked(e.target.checked)}
              />
              <Typography
                variant="body2"
                onClick={() => setOpenModal(true)}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                KVKK sözleşmesini okudum, anladım, onaylıyorum.
              </Typography>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!kvkkChecked}
          >
            Kaydet
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Zaten kayıtlı mısınız? Giriş Yapın
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        sx={{ overflow: "scroll" }}
      >
        <KVKK_DOC setOpenModal={setOpenModal} />
      </Modal>
    </Container>
  );
}
