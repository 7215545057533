import { Button } from "@mui/material";
import React from "react";
import { PageSizes, PDFDocument, rgb } from "pdf-lib";
import eleme_png from "../../../../files/pdfBackgrounds/2024/01_eleme_katilim.png";
import gilroyFont from "../../../../files/fonts/Gilroy-Bold.ttf";

import fontkit from "@pdf-lib/fontkit";
export const AttendanceDocument2024 = (props) => {
  return (
    <Button
      variant="contained"
      style={{
        width: 180,
        textTransform: "none",
        alignSelf: "center",
        marginTop: 10,
      }}
      onClick={async () => {
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        const page = pdfDoc.insertPage(0, [PageSizes.A4[1], PageSizes.A4[0]]);

        const pngUrl = eleme_png;
        const pngImageBytes = await fetch(pngUrl).then((res) =>
          res.arrayBuffer()
        );

        const fontBytes = await fetch(gilroyFont).then(
          (res) => res.arrayBuffer() //Fontu da pdf içine gömebilmek için byte array'e dönüştürüyoruz.
        );
        const customFont = await pdfDoc.embedFont(fontBytes);
        const pngImage = await pdfDoc.embedPng(pngImageBytes);

        const pngDims = pngImage.scale(0.5);
        page.drawImage(pngImage, {
          x: 0,
          y: 0,
          width: pngDims.width,
          height: pngDims.height,
        });
        const fontSize = 22;
        const nameWidth = customFont.widthOfTextAtSize(
          props.userName,
          fontSize
        );
        const spaceLengthForName = 358;
        const xOfName = (spaceLengthForName - nameWidth) / 2 + 400;

        page.drawText(props.userName, {
          x: xOfName,
          y: 285,
          size: fontSize,
          color: rgb(0.11765, 0.14118, 0.32941),
          font: customFont,
          lineHeight: 22,
          // opacity: 0.75,
        });

        const pdfBytes = await pdfDoc.save();

        var blob = new Blob([pdfBytes], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = props.userName + " (Katılım Belgesi)";
        link.download = fileName;
        link.click();
      }}
    >
      2024 Eleme Katılım Belgesini İndir
    </Button>
  );
};
