import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";

export default function Year2021() {
  const studentData = [
    {
      title: "Minikler",
      rows: [
        ["1. Can Kahraman", 126.73],
        ["2. Mert Ergezer", 125.54],
        ["3. Kuzey Deniz", 113.65],
        ["4.Tarık Mirza Gökcimen", 107.82],
        ["5.Kuzey İnce", 107.33],
        ["6.Mustafa Ayberk Eroğlu", 92.57],
        ["7.Alperen Ertuğ", 90.89],
        ["8.Kuzey Aras Atalay", 90.89],
        ["9.Elif Ceylin Demir", 89.7],
        ["10.Can Kıvılcım", 89.6],
      ],
    },
    {
      title: "Çocuklar",
      rows: [
        ["1.Fatma Zehra Yıldız", 143.3],
        ["2.Kerem Gelir", 139.4],
        ["3. Mehmet Efe Okumuş", 121.09],
        ["4.İpek Barış", 120.65],
        ["5.Çağlayan Dinçtürk", 111.33],
        ["6.Sinan Gencer", 106.43],
        ["7.Aras Özkaya ", 105.78],
        ["8.Deniz Şahin", 104.61],
        ["9.Poyraz Aksakal", 104.38],
        ["10.İrem Efek", 103.88],
      ],
    },
    {
      title: "Gençler",
      rows: [
        ["1. Kadir Kaan Erbaş", 103.63],
        ["1. Efe Kaan Keremoğlu", 103.63],
        ["3.Ahmet Eren Gözübenli", 130.45],
        ["4.Mehmet Can Baştemir", 128.99],
        ["5.Ege Yiğit Köksal", 128.99],
        ["6.Mete Gökhan Coşkun", 120.21],
        ["7.Arda Sönmez", 118.4],
        ["7.Melisa Karataş", 118.4],
        ["9. Aleyna Nur Temel", 113.74],
        ["9.Onur Alp Güngör", 113.74],
        ["9.Berre Şengül", 113.74],
        ["9.Tarık Yıldız", 113.74],
        ["9.Ezgi Ergüney", 113.74],
        ["9.Miray Akgün", 113.74],
      ],
    },
    {
      title: "Yetişkinler",
      rows: [
        ["1.Ulaş Torun", 141.06],
        ["1. Hayrullah Mert Şahinkoç", 141.06],
        ["1. Devran Torun141.06", 141.06],
        ["4. Türkmen Göksel", 136.04],
        ["5. Gürkan İnal", 126.13],
        ["6.Salih Alan", 125.77],
        ["7.Okan Tezcan", 124.22],
        ["7.Arman Özcan", 124.22],
        ["7.Simay Pala", 124.22],
        ["7.Yiğit Şahinkoç", 121.57],
      ],
    },
    {
      title: "Emektarlar",
      rows: [
        ["1.  Ali Kılıç", 118.98],
        ["2. Hakan Çetin", 106.42],
        ["3. Hakan Koparal", 97.91],
        ["4.Metin Örsel", 95.81],
        ["5.Aliye Yalçın", 85.02],
        ["6.Veli Çıracı", 84.88],
        ["6. Latif Haliloğlu", 84.88],
        ["8.Zekeriya İnal", 83.95],
        ["9. Sülün Turgay", 83.26],
        ["10.Kazim Pars", 83.02],
      ],
    },
  ];

  return (
    <div className="history2021">
      <div>
        <h3>Türkiye 26. Zeka Oyunları Yarışması</h3>
        <h1>
          <strong>OYUN 2021’in Sonuçları Açıklandı!</strong>
        </h1>
        <p>
          <strong>
            Bu sene 26.’sı düzenlenen Türkiye Zeka Oyunları Yarışması “Oyun
            2021”in sonuçları, 17 Haziran 2021 Perşembe günü ilan edildi.
          </strong>
        </p>
        <p>
          <strong>
            Sonuçların ilanı, pandemi koşulları nedeniyle sanal törenle
            gerçekleştirildi.
          </strong>
        </p>
      </div>

      <div>
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/moMy0SyqKWQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
          {" "}
        </iframe>
        <h2 style={{ paddingTop: "20px", textAlign: "left" }}>
          Her İlden, Beş Kategoriden Katılım
        </h2>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye 26. Zeka Oyunları Yarışması “Oyun 2021” için, 81 ilden farklı
          yaş gruplarından yarışmacılar “Çık dışarıya oynayalım, dediğimiz
          günler gelene kadar evlerden Oyun’a devam!” dedi. “Emektarlar”,
          “Yetişkinler”, “Gençler”, “Çocuklar” ve “Minikler” olmak üzere beş
          kategoride gerçekleşen Yarışma’ya katılan yaşça en küçük yarışmacı 4;
          en büyük yarışmacı ise 80 yaşında.
        </p>
        <h2 style={{ paddingTop: "20px", textAlign: "left" }}>
          Pandemi ve “Oyun 2021”
        </h2>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Gelenek bozulmadı, “Oyun 2021” Pandemi sürecinin gerektirdiği yasaklar
          dikkate alınarak düzenlendi.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          8 Şubat’tan 21 Mart’a kadar süren Eleme Sınavı’ndan sonra 24 Nisan’da
          Yarı Final Sınavı, 29 Mayıs’ta ise Final Sınav oyun.tzv.org.tr
          adresinde gerçekleşti.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          Ödül Töreni ise 17 Haziran 2021 Perşembe günü, Millî Eğitim Bakanı
          Sayın Ziya Selçuk, Türkiye Zeka Vakfı Başkanı Emrehan Halıcı ve
          Türkiye Zeka Vakfı Yönetim Kurulu Üyesi Dr. Şeref Oğuz’un konuşma
          yaptığı video ile sanal olarak düzenlendi.
        </p>
        ’
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          Bakan Selçuk, 26 yıldır yarışma sorularını hazırlayan Emrehan Halıcı
          ve Şeref Oğuz konuşmalarında katılımcıları tebrik ederken yarışmanın
          takip eden yıllarda sağlıklı koşullarda mutlulukla ve yüz yüze
          gerçekleşmesi dileklerinde de bulundular.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          Ödül Töreni videosunda, dereceye giren katılımcılar ilan edildi.
        </p>
        <h2 style={{ paddingTop: "10px", textAlign: "left" }}>
          Her Kategoriden Birinciliği Elde Eden Yarışmacılar
        </h2>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          “Minikler” kategorisinde <strong>Can Kahraman</strong> 126.73 puan,
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          “Çocuklar” kategorisinde <strong>Fatma Zehra Yıldız</strong> 143.3
          puan,
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          “Gençler” kategorisinde{" "}
          <strong>Kadir Kaan Erbaş ve Efe Kaan Keremoğlu</strong> 130.63 puan,
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          “Yetişkinler” kategorisinde{" "}
          <strong>Ulaş Torun, Hayrullah Mert Şahinkoç ve Devran Torun</strong>{" "}
          141.06 puan,
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          “Emektarlar” kategorisinde <strong>Ali Kılıç</strong> 118.98 puan ile
          birbirinden zor soruları çözerek birinci oldular.
        </p>
        <h2 style={{ paddingTop: "10px", textAlign: "left" }}>
          Derece Alan Yarışmacılar
        </h2>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        {studentData.map((group) => {
          return <LeaderBoard key={group.title} data={group} />;
        })}
      </div>

      <a
        href="http://www.file.tzv.org.tr/oyun/oyun2021_final_minikler.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAV SONUÇLARI-MİNİKLER
      </a>
      <br></br>

      <a
        href="http://www.file.tzv.org.tr/oyun/oyun2021_final_cocuklar.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAV SONUÇLARI-ÇOCUKLAR
      </a>
      <br></br>
      <a
        href="http://www.file.tzv.org.tr/oyun/oyun2021_final_gencler.pdf "
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAV SONUÇLARI-GENÇLER
      </a>
      <br></br>
      <a
        href="http://www.file.tzv.org.tr/oyun/oyun2021_final_yetiskinler.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAV SONUÇLARI-YETİŞKİNLER
      </a>
      <br></br>
      <a
        href="http://www.file.tzv.org.tr/oyun/oyun2021_final_emektarlar.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAV SONUÇLARI-EMEKTARLAR
      </a>
    </div>
  );
}
