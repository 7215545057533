import { Button } from "@mui/material";
import React, { useState } from "react";
import Year2023 from "./year2023";
import Year2024 from "./year2024";

const years = [2024, 2023];
const pages = {
  2024: <Year2024 />,
  2023: <Year2023 />,
};

const generateYearsButtons = (selectYear, selectedYear) => {
  return (
    <div className="yearSelectionBox">
      {years.map((y) => (
        <Button
          color="secondary"
          style={{
            marginLeft: 3,
            marginRight: 3,
            marginBottom: 6,
            backgroundColor: selectedYear === y ? "#582b71" : "",
            color: selectedYear === y ? "white" : "black",
          }}
          variant="outlined"
          key={"year_button" + y}
          onClick={() => selectYear(y)}
        >
          {"Belgelerim " + y}
        </Button>
      ))}
    </div>
  );
};

export const DocumentsPage = () => {
  const [state, setStateValues] = useState({
    selectedYear: 2024,
  });

  const setState = (obj) => {
    setStateValues({
      ...state,
      ...obj,
    });
  };

  return (
    <div>
      {generateYearsButtons(
        (y) => setState({ selectedYear: y }),
        state.selectedYear
      )}
      {pages[state.selectedYear]}
    </div>
  );
};
