import React from "react";

function HallOfFameCategoryBox({ title = "", users = [], onShowFull }) {
  return (
    <div className="catgoryContainer">
      <div className="category_header">
        <span>{title}</span>
      </div>
      <div className="usersContainer">
        {users
          .sort((a, b) => a.medal - b.medal)
          .map((u) => {
            const medalImage = (
              <img
                className="medalImage"
                src={"/assets/images/medals/md-" + u.medal + ".png"}
              ></img>
            );
            const userImage = (
              <div
                className="photo_container"
                style={{ height: u.photo ? "" : 232 }}
              >
                {u.photo && <img className="photo" src={u.photo}></img>}
              </div>
            );
            const result = <div className="result">{u.result}</div>;
            const name = (
              <div className="userName">{u.name.toLocaleLowerCase()}</div>
            );
            const ghost = <div className="ghost"></div>;
            const about = <div className="about">{u.about}</div>;
            const showFull = (
              <div onClick={() => onShowFull(u)} className="showFull">
                {"Devamını Göster"}
              </div>
            );
            const about2 = <div className="about2">{u.about2}</div>;

            return (
              <div key={"user_box" + u.name} className="userBox">
                {medalImage}
                {userImage}
                <div className="result_name_ghost">
                  {result}
                  {name}
                  {ghost}
                </div>

                {about}
                {u.about && showFull}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default HallOfFameCategoryBox;
