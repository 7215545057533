import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./mainPage.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AppContext from "../../AppContext";
import { useContext } from "react";
import React from "react";
import Year2023 from "../historyPage/year2023";

const pdfFiles = [
  {
    category: "Minikler",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FMinikler%20Kategorisi.pdf?alt=media&token=526ebaa8-7ab8-4fc6-8ca7-fd85264293c0",
  },
  {
    category: "Çocuklar",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2F%C3%87ocuklar%20Kategorisi.pdf?alt=media&token=1550520d-4bac-49ad-9925-d41a90415475",
  },
  {
    category: "Gençler",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FGen%C3%A7ler%20Kategorisi.pdf?alt=media&token=9e3a2e3c-8090-4258-8beb-d68407a4d320",
  },
  {
    category: "Yetişkinler",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FYeti%C5%9Fkinler%20Kategorisi.pdf?alt=media&token=d1269f3a-a2cb-4fae-99d0-a007a6ec7054",
  },
  {
    category: "Emektarlar",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FEmektarlar%20Kategorisi.pdf?alt=media&token=89a5df78-8cd1-4682-a1de-dd3c212d2e7c",
  },
];

const resultsDiv = (
  <div className="resultsDiv">
    {/* <h1>
      <strong>OYUN 2022 Sanal Ödül Töreni</strong>
    </h1> */}
    <div className="iframeContainerContainer">
      <div className="iframeContainer">
        <iframe
          className="responsive-iframe"
          src="https://www.youtube.com/embed/o3Bo7FrR8Is"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>

    {/* <p>
      <strong>
        Bu sene 27.’si düzenlenen Türkiye Zeka Oyunları Yarışması “Oyun 2022”nin
        sonuçlarına aşağıdaki bağlantılardan ulaşabilirsiniz.
      </strong>
    </p> */}
    {/* <p>
      Final Sınavı'na katılan yarışmacıların puanlarına ve derecelerine
      aşağıdaki listelerden ulaşabilirsiniz. Dereceye giren yarışmacılara ödül
      için ayrıca e-posta ile bilgilendirme yapılacaktır.
    </p> */}

    <h3>SONUÇLAR</h3>
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {pdfFiles.map((p) => {
        return (
          <a
            style={{ textDecoration: "none" }}
            key={p.category + "_pdf_link"}
            href={p.pdfUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              style={{
                backgroundColor: "white",
                textTransform: "none",
                width: 110,
              }}
              variant="outlined"
            >
              {p.category}
            </Button>
          </a>
        );
      })}
    </div>

    <p>
      Bu sene 27.’si düzenlenen Türkiye Zeka Oyunları Yarışması “Oyun 2022”nin
      sonuçları açıklanmıştır. Sanal ödül törenini 4 Ekim 2022 saat 20.00'den
      itibaren izleyebilirsiniz.
    </p>
  </div>
);

const bannerImage = (
  <img
    style={{ maxWidth: 700, width: "100%" }}
    src="/assets/images/oyun-banner.jpg"
    alt="Oyun 2022 banner"
  />
);

const competitionCalendar = (
  <div>
    <h2>YARIŞMA TAKVİMİ</h2>

    <TableContainer component={Paper} style={{ marginTop: 30 }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">SINAV</TableCell>
            <TableCell align="center">AÇIKLAMA</TableCell>
            <TableCell align="center">TARİH</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <strong>Eleme Sınavı</strong>
            </TableCell>
            <TableCell>
              Eleme Sınavı <strong>23 Mayıs 2024 Perşembe</strong> günü saat{" "}
              <strong>13:00</strong>'ten itibaren bu site üzerinden yayınlanmaya
              başlayacaktır. Yarışmacıya ait bilgiler ve cevaplar en geç{" "}
              <strong>31 Temmuz 2024 Çarşamba</strong> günü bu site üzerinden
              girilecektir.
            </TableCell>
            <TableCell>
              <strong>23 Mayıs 2024 Perşembe</strong>
              <br />
              <strong>31 Temmuz 2024 Çarşamba</strong>
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <strong>Yarı Final Sınavı</strong>
            </TableCell>
            <TableCell>
              <strong>31 Ağustos 2024 Cumartesi</strong> günü{" "}
              <strong>13:00</strong> ile <strong>14:30</strong> saatleri
              arasında bu site üzerinden yapılacaktır.
            </TableCell>
            <TableCell>
              <strong>31 Ağustos 2024 Cumartesi</strong>
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <strong>Final Sınavı</strong>
            </TableCell>
            <TableCell>
              Final Sınavı <strong>19 Ekim 2024 Cumartesi</strong> günü{" "}
              <strong>Ankara'da yazılı sınav olarak </strong>
              yapılacaktır. Yarı Final Sınavında her kategoride ilk 100 dereceyi
              alanlar Final Sınavına katılacaklardır. Ödül töreninin günü ve
              programı ayrıca duyurulacaktır.
            </TableCell>
            <TableCell>
              <strong>19 Ekim 2024 Cumartesi</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

const conditions = (
  <div className="list-items">
    <h2 style={{ textAlign: "center" }}>YARIŞMA BİLGİLERİ ve KOŞULLAR</h2>
    <li>
      Türkiye Zeka Oyunları Yarışmasının amacı; her yaştaki insanların düşünme
      ve problem çözme alışkanlıklarını ve yeteneklerini geliştirmeye katkıda
      bulunmaktır.
    </li>
    <li>Yarışma beş kategoride yapılmaktadır.</li>
    <ul className="list-items">
      <li>
        <strong>MİNİKLER</strong> 6-7-8 yaş (2018, 2017 ve 2016 yıllarında
        doğanlar. Okuma, yazma ve matematikte dört işlem becerisine sahip olmak
        gereklidir.)
      </li>
      <li>
        <strong>ÇOCUKLAR</strong> 9-10-11-12-13 yaş (2015, 2014, 2013, 2012,
        2011 yıllarında doğanlar.)
      </li>
      <li>
        <strong>GENÇLER</strong> 14-15-16-17-18 yaş (2010, 2009, 2008, 2007,
        2006 yıllarında doğanlar.)
      </li>
      <li>
        <strong>YETİŞKİNLER</strong> 19-49 yaş arası (2005, …,1975 yıllarında
        doğanlar.)
      </li>
      <li>
        <strong>EMEKTARLAR</strong> 50 yaş ve üstü (1974 ve önceki yıllarda
        doğanlar.)
      </li>
    </ul>
    <li>
      Her kategoride ilk 10 dereceye girenlere TZV Başarı Madalyası ve Tübitak
      kitapları ödül olarak verilecektir. Ayrıca;{" "}
    </li>
    <ul className="list-items">
      <li>
        Her kategoride ilk 10 dereceye girenler TZV yarışma sayfasında “Şeref
        Kürsüsü”nde (fotoğrafları ve kısa özgeçmişleriyle birlikte) ilan
        edileceklerdir.
      </li>
      <li>
        Final Sınavına katılım hakkı kazananlara Başarı Belgesi (basılı)
        verilecektir.
      </li>
      <li>
        Yarı Final Sınavına katılım hakkı kazananlara Başarı Belgesi
        (elektronik) verilecektir.
      </li>
      <li>
        Eleme Sınavına katılanlara Katılım Belgesi (elektronik) verilecektir.
      </li>
    </ul>
    <li>
      Yarışmaya TZV web sitesi üzerinden kayıt olunacak ve gerekli bilgiler
      girilecektir. Yanlış bilgi -hangi aşamada olursa olsun- diskalifiye
      nedenidir.
    </li>
    <li>Yarışma kayıt tutarı 200 TL'dir.</li>
    <li>
      Yarışmacılar Eleme Sınavı sorularını belirtilen tarihler içinde herhangi
      bir süre kısıtlaması olmadan tek başlarına çözüp sisteme gireceklerdir.
      Sorular cevaplanırken ara verilebilir, belirtilen süreler içinde kalınan
      yerden devam edilebilir veya cevaplar değiştirilebilir.
    </li>
    <li>
      İptal edilen soruların olması durumunda değerlendirmeler kalan sorular
      üzerinden yapılacak ve duyurulacaktır.{" "}
    </li>
    <li>
      Sınav sonuçları TZV web sitesinde yayınlanacak, katılım ve başarı
      belgelerine internet üzerinden erişeceklerdir.
    </li>
    <li>
      Yarışma soruları Emrehan Halıcı tarafından hazırlanmaktadır. Türkiye Zeka
      Vakfı’nın yazılı izni alınmadan yarışma soruları kullanılamaz, alıntı ya
      da uyarlama yapılamaz.{" "}
    </li>
    <li>
      Türkiye Zeka Oyunları Yarışmalarında soruların cevapları açıklanmakta
      fakat çözümleri açıklanmamaktadır.
    </li>
    <li>
      Yarışma sorularının kontrolünde ve itirazların değerlendirilmesinde TZV
      Soru Kurulu yetkilidir.
    </li>
    <li>
      Türkiye Zeka Vakfı yarışma koşullarında ve takviminde değişiklik yapma
      hakkına sahiptir.
    </li>
    <li>Yarışmaya katılanlar bu koşulları kabul etmiş sayılırlar.</li>
  </div>
);

const signUpButton = (
  <div style={{ marginTop: 15 }}>
    <Link to={"/signup"} style={{ textDecoration: "none" }}>
      <Button variant="contained" size="large" color="secondary">
        KAYIT OL
      </Button>
    </Link>
  </div>
);

const footerBanner = (
  <div style={{ marginTop: 30 }}>
    <a href="assets/images/afis-buyuk2023.png" target="_blank">
      <img src="assets/images/afis-kucuk2023.png" alt="Oyun 2023 Afiş" />
    </a>
  </div>
);
const Whatsap = (
  <div style={{ marginTop: 40, marginBottom: 40 }}>
    <p className="small-announcement">
      Tüm Oyun2024 duyuruları, hatırlatmalar, yaklaşan sınav tarihleri ve itiraz
      süreci gibi önemli açıklamalar, Whatsapp duyuru kanalımız aracılığıyla
      yapılacaktır. Aşağıdaki bağlantıyı kullanarak kanalımıza katılabilirsiniz.
      Telefon numaranız diğer üyelerle paylaşılmayacaktır. İstediğiniz zaman
      kanaldan çıkabilirsiniz.
    </p>
    <p>
      <a
        href="https://whatsapp.com/channel/0029VabTkl305MUjehocPd0S"
        target="_blank"
        rel="noreferrer"
      >
        Whatsap Duyuru Kanalı
      </a>
    </p>
    <p className="small-announcement">
      (Kanala üye olduktan sonra kanal ayarlarından bildirimleri açmayı
      unutmayınız.)
    </p>
  </div>
);
function MainPage() {
  const { refreshUserData, setCentralState, user, activeProduct } =
    React.useContext(AppContext);

  // return <Year2023 />;

  return (
    <div className="main-body">
      <div>
        <h2>Türkiye 29. Zeka Oyunları Yarışması</h2>
        {/* {resultsDiv} */}
        {bannerImage}
        {competitionCalendar}
        {Whatsap}
        {conditions}
        {/* {footerBanner} */}
        {/* {!user && signUpButton} */}
      </div>
    </div>
  );
}

export default MainPage;
