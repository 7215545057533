import React, { useEffect, useRef, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./loginPage.css";
import {
  fbLogin,
  loginWithMasterPass,
  requestPasswordReset,
  writeTokenToDb,
} from "../../db/firebaseMethods";
import AppContext from "../../AppContext";
import { Navigate, Link } from "react-router-dom";
import LoadingBlock from "../../components/organisms/loadingBlock/loadingBlock";
import { LinearProgress } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

export default function LoginPage() {
  const [status, setStatus] = useState({
    wrong_pass: false,
    email_not_found: false,
    loading: false,
  });
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [isCapchaValid, setIsCapchaValid] = useState(false);

  const {
    refreshUserData,
    setCentralState,
    user,
    userSetter,
    isLoggedInByOriginalPassFunc,
  } = React.useContext(AppContext);
  if (user) return <Navigate replace to="/profile" />;

  const loginUser = async (email, pass) => {
    setStatus({ ...status, loading: true });
    let res = "";

    if (pass.substring(0, 9) === "oyunsüper") {
      // Süper şifre ile tüm hesaplara login olunabilir. Süper şifrenin ilk karakterleri oyunsüper, ama devamı da var, bu şifre firebase functions içinde config var olarak kayıtlı
      // Şifrenin ilk kısmı oyunsüper ise, email-şifre cloud functions'a gönderiliyor, orada şifrenin tamamı doğru mu diye kontrol ediliyor. Doğruysa user login ediliyor.
      res = await loginWithMasterPass(
        email,
        pass,
        userSetter,
        isLoggedInByOriginalPassFunc
      );
    } else {
      res = await fbLogin(email, pass);
      isLoggedInByOriginalPassFunc(true);
    }

    if (res === "wrong_pass") {
      setStatus({ ...status, wrong_pass: true, loading: false });
      await new Promise((resolve) => setTimeout(resolve, 500));
      alert("E-posta ve Şifre uyumsuz!");
    } else if (res === "email_not_found") {
      setStatus({ ...status, email_not_found: true, loading: false });
      await new Promise((resolve) => setTimeout(resolve, 500));
      alert("Böyle bir e-posta adresi kayıtlı değil!");
    } else {
      if (res?.uid) {
        await writeTokenToDb(
          res?.uid,
          res?.stsTokenManager?.refreshToken.slice(-10)
        );
      }
      await refreshUserData();
      //setStatus({ ...status, loading: false });
    }
  };

  const requestPassReset = async () => {
    if (formValues.email.length < 6)
      alert("Lütfen e-posta adresinizi yazınız.");
    else {
      const res = await requestPasswordReset(formValues.email);
      alert(res);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { password, email } = formValues;

    if (password.length < 6)
      alert("Şifre en az 6 karakter uzunluğunda olmalıdır.");
    else loginUser(email, password);
  };

  const onCaptchaChange = (value) => {
    if (value) setIsCapchaValid(true);
    else setIsCapchaValid(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {status.loading && <LoadingBlock />}
        {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h3"
          variant="h6"
          style={{ marginTop: 20, fontSize: 18 }}
        >
          Türkiye Okullar Arası Zeka Oyunları Şampiyonası sayfasına ulaşmak için
          <a href="https://sampiyona.tzv.org.tr/"> tıklayınız.</a>
          <p>(Bu ekran şampiyona girişi için değildir.)</p>
           <LinearProgress color="secondary" /> 
        </Typography> */}

        <Typography component="h1" variant="h5">
          GİRİŞ
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="E-Posta"
                name="email"
                autoComplete="email"
                value={formValues.email}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    email: e.target.value.trim(),
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Şifre"
                type="password"
                id="password"
                value={formValues.password}
                onChange={(e) => {
                  setFormValues({ ...formValues, password: e.target.value });
                }}
              />
            </Grid>
          </Grid>
          <ReCAPTCHA
            style={{ marginTop: 10 }}
            sitekey="6Lf94lMpAAAAABnALfA9uZLHj2z4IH5OAwONBJxN"
            onChange={onCaptchaChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isCapchaValid ? false : true}
          >
            GİRİŞ
          </Button>
          {status.email_not_found && (
            <div className="bottomNote">
              NOT: Daha önceki yıllara ait kullanıcı bilgileri sistemden
              silinmiştir. 2022 yılı için e-posta adresiniz ile yeniden kayıt
              olmanız gerekmektedir.
            </div>
          )}

          <Grid container justifyContent="space-between">
            <Grid item>
              <Link to="/signup" variant="body2" style={{ fontSize: "1rem" }}>
                Kayıt olmak için tıklayınız.
              </Link>
            </Grid>
            <Grid item>
              <Button
                style={{
                  textDecoration: "underline",
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "-webkit-link",
                  marginTop: -8,
                }}
                onClick={requestPassReset}
              >
                Şifremi Unuttum
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
