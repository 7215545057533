import React from "react";
import HallOfFameCategoryBox from "./hallOfFameCategoryBox";
import HallOfFameUserModal from "./hallOfFameUserModal";

const header = <div className="header">OYUN 2021 Şeref Kürsüsü</div>;

const minikler2021 = [
  {
    result: "Minikler Birincisi",
    name: "Can Karaman",
    medal: 1,
    photo: "",
    about2:
      "05/07/2014 tarihinde İzmir Karşıyaka’ da doğdu. 2 yaşında anaokuluna başladı. Özel Çakabey İlkokulu 1. Sınıf öğrencisidir.",
    about:
      "05/07/2014 tarihinde İzmir Karşıyaka’ da doğdu. 2 yaşında anaokuluna başladı. Özel Çakabey İlkokulu 1. Sınıf öğrencisidir.",
  },
  {
    result: "Minikler İkincisi",
    name: "Kuzey Deniz",
    medal: 2,
    photo: "",
    about2:
      "Merhabalar, Adım Kuzey DENİZ. 21 Nisan 2014  tarihinde Zonguldak ilinin Kdz. Ereğli ilçesinde doğdum.2020 yılında Atatürk İlkokulunda eğitimime başladım ve şu an itibariyle 2. sınıfa geçmiş bulunmaktayım. Babam Turgay DENİZ, Annem Şermin DENİZ ve abim Yağız DENİZ ile beraber Zonguldak’a bağlı Alaplı ilçesinde yaşamaktayım. Babam sınıf öğretmeni olarak, annem ise hemşire olarak çalışma hayatlarını sürdürmektedirler. Abim bu sene itibariyle 8. Sınıfa geçmiş bulunmaktadır. Abimle beraber zeka oyunları oynamayı ve akıl-mantık soruları çözmeyi çok seviyoruz. Annem ve babam da bu konuda bizlere çok destek olmaktadırlar. Abim Caribou Matematik,  Kanguru Matematik ve çeşitli zeka oyunlarında Türkiye çapında dereceler yapmış olup bende onun izinden giderek, derslerimi aksatmadan,  bu tarz yarışmalara katılım sağlamaya devam edeceğim. Bunların dışında küçük deneyler yapmayı, masal tarzı kitapları okumayı ve oyun oynamayı çok seviyorum. İleride bilgisayar mühendisi olmak istiyorum. Bu sene öğretmenim Ülker ÇİÇEK  ve birkaç arkadaşımla beraber  “bilim arkadaşım olur musun?” adlı eTwinning proje çalışmasında yer aldım. Her hafta zoom üzerinden çeşitli deneyler yaparak projemizi gerçekleştirdik. Türk Zeka Vakfına bu tarz yarışmalar düzenlediği için çok teşekkür ediyorum saygılarımı sunuyorum. ",
    about:
      "21 Nisan 2014  tarihinde Zonguldak ilinin Kdz. Ereğli ilçesinde doğdum.2020 yılında Atatürk İlkokulunda eğitimime...",
  },
  {
    result: "Minikler İkincisi",
    name: "Mert Ergezer",
    medal: 2,
    photo: "",
    about2:
      "Merhaba, ben Mert Ergezer. 14 Ocak 2014 ‘te Ankara’da doğdum. Ailemle birlikte Ankara’da yaşıyorum. Nesibe Aydın Ümitköy İlkokulu’nda okuyorum, gelecek yıl ilkokul 2 olacağım. Okulumu çok seviyorum. Satranç oynamayı, kitap okumayı , brawl stars oynamayı çok seviyorum. Boş vakitlerimde ailemle  kutu oyunları oynuyorum ve arkadaşlarımla saklambaç oynamayı çok seviyorum. Annemle kurabiye yapmayı, babamla futbol oynamayı, ablamla da kör saklambaç (ikimizin uydurduğu bir oyun) oynamayı, arada sırada ablama takılmayı çok seviyorum. İlkokula giden arkadaşlarıma, Çıtır Çıtır Felsefe, Poşetto, Kumkurdu ve Şermin Yaşar’ın eğlenceli kitaplarını öneriyorum.  Bazen yaramazlık yapıp eğleniyorum. Yaramazlık yapmak gerçekten çok eğlenceli, hem küçüklere hem büyüklere tavsiye ediyorum ☺ ",
    about:
      "14 Ocak 2014‘te Ankara’da doğdum. Ailemle birlikte Ankara’da yaşıyorum. Nesibe Aydın Ümitköy İlkokulu’nda okuyorum...",
  },
  {
    result: "Minikler Beşincisi",
    name: "Kuzey İnce",
    medal: 5,
    photo: "",
    about2:
      "Adım Kuzey İNCE. 17 Nisan 2013 tarihinde Erzurum/İspir’de doğdum. Sağlık memuru annem, öğretmen babam ve 1 kız kardeşim ile birlikte Aydın Efeler ilçesinde yaşamaya devam ediyoruz. Okulöncesi eğitimimi Yeni Nesil Kreş-Anaokulunda tamamladım. İlkokul eğitimime Yahya Kemal Beyatlı İlkokulunda başladım ve şu an 2/C sınıfında okumaktayım. Kitap okumayı, piyano çalmayı, zekâ oyunlarını ve spor yapmayı çok severim. Büyüdüğüm zaman iyi bir piyanist ve bilim adamı olmayı istiyorum.",
    about:
      "17 Nisan 2013 tarihinde Erzurum/İspir’de doğdum. Sağlık memuru annem, öğretmen babam ve 1 kız kardeşim ile birlikte Aydın...",
  },
  {
    result: "Minikler Altıncısı",
    name: "Mustafa Ayberk Eroğlu",
    medal: 6,
    photo: "",
    about2:
      "1 Mayıs 2012 yılında Eskişehir’de dünyaya geldi. 36-48 aylık iken Özel Yumurcak Anaokuluna, 48-60 aylık arasında Eskişehir-Pilot Binbaşı İlkokulunda bulunan anaokuluna ve 60-72 aylık iken Burdur-Bahçelievler Şehit Sıdkı Kara İlkokulunda bulunan anaokuluna devam etti. Mustafa Ayberk halen Burdur-Bahçelievler Şehit Sıdkı Kara İlkokulunun 3. sınıfında öğretmeni İhsan TİRYAKİOĞLU ile eğitimine devam etmektedir. Mustafa Ayberk piyano, zekâ oyunları ve satranç başta olmak üzere çeşitli okul dışı kurslara katılmış; ayrıca birçok bilim etkinliğinde de eğitim almıştır. Mustafa Ayberk’in, resim yapmak, zekâ oyunları oynamak, bilimsel hikâyeler okumak ve bilgisayar strateji oyunları oynamak gibi hobileri bulunmaktadır. Mustafa Ayberk, büyüdüğünde robot teknolojileri alanında çalışan bir bilim insanı olmak istemektedir. Mustafa Ayberk, 2 çocuklu bir ailenin büyük çocuğudur.",
    about:
      "1 Mayıs 2012 yılında Eskişehir’de dünyaya geldi. 36-48 aylık iken Özel Yumurcak Anaokuluna, 48-60 aylık arasında Eskişehir...",
  },
  {
    result: "Minikler Yedincisi",
    name: "Kuzey Aras Atalay",
    medal: 7,
    photo: "",
    about2:
      "Adım Kuzey Aras ATALAY. 11/03/2012, Ankara doğumluyum. 9 yaşındayım ve Zonguldak’ta yaşıyorum. Prof. Dr. Şaban Teoman Duralı Bilim ve Sanat Merkezi 3. Sınıf öğrencisiyim. Lisanslı satranç oyuncusuyum ve UKD puanım bulunmaktadır. Matematik, robotik-kodlama ve akıl-zeka oyunlarıyla ilgileniyorum.  2020 yılında Hollanda’da Designathon Works ve Türkiye’de İnformel Eğitim Çocuk İstanbul’un aynı anda düzenlediği “Korona İle Karşı Karşıya (Corona Challenge) adlı yarışmada hazırlamış olduğum proje, toplam 300 fikir arasından birinci seçildi. 2021 yılında düzenlenen 6.Ulusal Tales Bilim Yayınları Matematik Yarışması’nda Türkiye 10. su oldum. TZV’nin düzenlediği yarışmaları çok beğeniyorum ve ileriki yıllarda da katılmayı düşünüyorum.",
    about:
      "11/03/2012, Ankara doğumluyum. 9 yaşındayım ve Zonguldak’ta yaşıyorum. Prof. Dr. Şaban Teoman Duralı Bilim ve Sanat...",
  },
  {
    result: "Minikler Dokuzuncusu",
    name: "Elif Ceylin Demir",
    medal: 9,
    photo: "",
    about2:
      "2013 yılı Haziran ayında Sivas/Suşehri ilçesinde dünyaya gelmiştir. 4 yaşında Antalya/Korkuteli Nadide Ahmet BALABAN Anaokulunda eğitim öğretim hayatına başladı. Akıl zekâ oyunlarına ilgisinin olduğu ilk olarak burada öğretmeni tarafından fark edildi. Daha sonra Osmaniye/Kadirli Özel Çukurova Okullarında Anaokuluna devam etti. Burada gerek bireysel olarak gerekse öğretmenlerinin yardım ile zekâ oyunlarında gelişimini devam ettirdi. Aynı zamanda anaokuluna giderken özel piyano eğitimi almaya başladı. Halen piyano eğitimine devam etmektedir. İlkokula başlamadan okuma yazmayı öğrenen Elif Ceylin DEMİR zekâ oyunları ile oynamayı, kitap okumayı ve piyano çalmayı çok sevmektedir. Şu anda Osmaniye/Kadirli Ergenekon Şehit Mehmetçik İlkokulunda 2. sınıfta eğitim öğretim hayatına devam etmektedir.24.06.2021",
    about:
      "2013 yılı Haziran ayında Sivas/Suşehri ilçesinde dünyaya gelmiştir. 4 yaşında Antalya/Korkuteli Nadide Ahmet BALABAN...",
  },
  {
    result: "Minikler Onuncusu",
    name: "Can Kıvılcım",
    medal: 10,
    photo: "",
    about2:
      "6,5 yaşındayım. Anabilim Ümraniye Kampüs 1.Sınıf öğrencisiyim. Tenis oynamak ve Santranç en büyük zevklerim arasında. Sınavlarınıza katılmaktan çok keyif aldım.",
    about:
      "6,5 yaşındayım. Anabilim Ümraniye Kampüs 1.Sınıf öğrencisiyim. Tenis oynamak ve Santranç en büyük zevklerim arasında...",
  },
];

const cocuklar2021 = [
  {
    result: "Çocuklar Birincisi",
    name: "Fatma Zehra Yıldız",
    medal: 1,
    photo: "",
    about2:
      "21 Ekim 2008 İstanbul doğumluyum. İlkokulu Özel Küçükyalı Tekden İlkokulunda okudum. Küçükyalı Tekden Ortaokulunda 8. Sınıfa geçtim. 2016 yılından beri Kartal Prof. Dr. Şaban Teoman Duralı Bilim ve Sanat Merkezi’nde genel yetenek ve resim alanında eğitim alıyorum. 2018 yılından beri Türkiye Teknoloji Vakfı’nın Deneyap Teknoloji Atölyeleri’nde eğitim görmekteyim. 2018 yılından beri Temel Fikirlerden Temel Bilimlere Projesi’nde matematik olimpiyatlarına hazırlık eğitimi alıyorum. Bu matematik, teknoloji ve bilişim alanlarına yönelik becerimi ve ilgimi Bilsem öğretmenlerimin ve okul öğretmenlerimin yönlendirmeleriyle keşfettim. Akıl ve zeka oyunlarına karşı ilgim ilkokul 2. sınıftayken başladı. Kartal Bilsem’de ve okulumda akıl oyunları eğitimleri aldım. 4 yıldır Oyun TZV’nin finallerinde yarışmaktayım. İlerde Gençler, Yetişkinler ve Emektarlar kategorilerinde de yarışmayı umuyorum. Akıl ve zeka oyunları bulmacaları çözmeyi, piyano çalmayı, kitap okumayı, resim çizmeyi ve maket yapmayı seviyorum. ",
    about:
      "Adım Fatma Zehra Yıldız. 21 Ekim 2008 İstanbul doğumluyum. İlkokulu Özel Küçükyalı Tekden İlkokulunda okudum...",
  },
  {
    result: "Çocuklar İkincisi",
    name: "Kerem Gelir",
    medal: 2,
    photo: "",
    about2:
      "2008 İstanbul doğumluyum. Bağcılar İlmek Koleji'nde eğitim görmekteyim. Samsung Geleceğin Mucitleri Yarışmasında birincilik (2020), Altınbaş Üniversitesi Uluslararası Enformatik Bilimler ve Uygulamaları Öğrenci Proje Yarışmasında üçüncülük, Bağcılar Şiir Okuyor Yarışmasında birincilik derecem vardır. Marmara Üniversitesi İnovasyon Günleri'nde mansiyon ödülü ve Teknofest 2020'de sunum özel ödülüne layık görüldüm.",
    about:
      "2008 İstanbul doğumluyum. Bağcılar İlmek Koleji'nde eğitim görmekteyim. Samsung Geleceğin Mucitleri Yarışmasında birincilik (2020)...",
  },
  {
    result: "Çocuklar Üçüncüsü",
    name: "Mehmet Efe Okumuş",
    medal: 3,
    photo: "",
    about2:
      "2008 yılında Erzincan’da doğdum ve 2014 yılında Fidem İlköğretim Okulunda eğitimime başladım. Şu an Erzincan Mehmetçik Ortaokulunda 8. Sınıfa geçtim.",
    about:
      "Adım Mehmet Efe Okumuş. 2008 yılında Erzincan’da doğdum ve 2014 yılında Fidem İlköğretim Okulunda eğitimime başladım. Şu an Erzincan Mehmetçik...",
  },
  {
    result: "Çocuklar Dördüncüsü",
    name: "İpek Barış",
    medal: 4,
    photo: "",
    about2:
      "2009 yılında Çankaya’da doğdum. Okul öncesi eğitimini Ankara’da tamamladıktan sonra Nevşehir’e taşındık. İlkokul birinci sınıfta BİLSEM genel yeteneği kazandım. İlkokul sonunda yapılan bursluluk sınavında il birincisi oldum ve Nevşehir Altınyıldız Kolejinde okumaya başladım ve şuan itibarıyla 6. Sınıfım.  Hobilerim arasında gezmek, spor yapmak, kitap okumak, akıl oyunları, kodlama ve satrancı sayabilirim. Satrançta kadınlar il birinciliği ve yaş gruplarında İl birinciliği dâhil toplamda 13 madalyam; Kanguru Matematik, Tales, Matbeg/Fenbeg/Okubeg ve Oyuncu Beyinler yarışmalarında ulusal ve/veya il düzeyinde derecelerim bulunmaktadır. ‘Her zaman her yerde en büyük Kartal’ diyerek koyu bir Beşiktaş taraftarı olduğumu söylemek istiyorum. Hayallerim arasında Beşiktaş formasıyla Galatasaray Lisesine kayıt olmaya gitmek, Stanford Üniversitesinde yazılım mühendisliği okumak ve Dünya turu yapmak var. Gelecekte Dünya’ca ünlü bir akademisyen ya da yazar olmayı istiyorum.",
    about:
      "2009 yılında Çankaya’da doğdum. Okul öncesi eğitimini Ankara’da tamamladıktan sonra Nevşehir’e taşındık...",
  },
  {
    result: "Çocuklar Beşincisi",
    name: "Çağlayan Dinçtürk",
    medal: 5,
    photo: "",
    about2:
      "2009 Isparta doğumlu olup şu anda Burdur’ da yaşamaktayım. Şehit Akif Altay Ortaokulu 5. sınıf öğrencisi olup aynı zamanda Alpaslan Alican Bilim ve Sanat Merkezine devam etmekteyim. Kitap okumaya, zeka oyunlarına, futbola, yeni yerleri görmeye ve özellikle matematiğe meraklıyım. İleride dünyanın farklı yerlerini gezmek istiyorum. Ayrıca büyüdüğümde hakim olmak istiyorum. Türkçe’nin doğru kullanılması gerektiği noktasında ve çevre sorunları konusunda çok duyarlıyım. İlk zeka oyunları başarım 2019 yılında düzenlenen Türkiye Okullar Arası Zeka Oyunları Yarışması’nda takım olarak kazandığımız Türkiye birinciliğidir. Sonrasında ise Türkiye Zeka Vakfı tarafından düzenlenen bireysel Zeka Oyunları Yarışmasında Türkiye dördüncülüğüm (2020) ve Türkiye beşinciliğim (2021), Uluslararası Bilim Temelleri Yarışması (Urfodu) Matematik alanı Uluslararası birinciliğim (2021), Türkiye Matematik Yarışması (TMY) Türkiye birinciliğim (2020) bulunmaktadır.",
    about:
      "2009 Isparta doğumlu olup şu anda Burdur’ da yaşamaktayım. Şehit Akif Altay Ortaokulu 5. sınıf öğrencisi olup aynı zamanda Alpaslan...",
  },
  {
    result: "Çocuklar Altıncısı",
    name: "Sinan Gencer",
    medal: 6,
    photo: "",
    about2:
      "12 yaşındayım. Bilfen Ataşehir Ortaokulu ve İstanbul Fuat Sezgin Bilim ve Sanat Merkezi öğrencisiyim. Satrançla başladığım akıl oyunlarına ilkokuldan beri ilgiliyim. 4.sınıfta TBT’nin düzenlediği “Beyin Olimpiyatları”nda ilkokul seviyesinde 1.oldum. Son iki senedir de Bilsem takımıyla TZV’nin “Okullar Arası Zeka Oyunları Şampiyonası”na katılıyorum. Geçen sene ortaokul seviyesinde Türkiye 1.si olduk. Bu sene TAZOF’un düzenlediği “Oyuncu Beyinler” turnuvasına da katıldım. Akıl oyunları dışında matematik alanında olimpiyatlara hazırlanıyorum ve çeşitli matematik yarışmalarında derecelerim var. Akıl oyunlarıyla ilgilenmeye devam edeceğim çünkü stratejik ve çok yönlü düşünmemi geliştiriyor, dikkat ve konsantrasyonumu artırıyor, özellikle şekilli sorular görsel ilişkileri anlamama yardımcı oluyor. Başta matematik olmak üzere diğer dersler için de zihin antremanı yapmış oluyorum. Ayrıca, akıl oyunlarıyla ilgilenen yeni arkadaşlar ediniyorum ve birlikte turnuvalara katılıyoruz.  Boş zamanlarımda piyano çalmayı, basketbol oynamayı, köpeğimle oynamayı ve bilimsel makaleler okumayı seviyorum. GriCeviz, Puzzle Page ve çeşitli kelime oyunu uygulamaları da sevdiklerim arasındadır.",
    about:
      "12 yaşındayım. Bilfen Ataşehir Ortaokulu ve İstanbul Fuat Sezgin Bilim ve Sanat Merkezi öğrencisiyim. Satrançla başladığım...",
  },
  {
    result: "Çocuklar Yedincisi",
    name: "Aras Özkaya",
    medal: 7,
    photo: "",
    about2:
      "21 Mart 2008 İstanbul doğumluyum. Anasınıfından itibaren Özel Kalfayan Ermeni Okulu'nda okudum ve 2021 yılında mezun oldum. Zeka oyunlarına olan ilgim 4.Sınıfta başladı. Zeka oyunları alanındaki ilk başarımı Türk Beyin Takımı'nın Ermeni ilkokulları arasında düzenlediği yarışmada okul kategorisinde 2.lik, bireysel kategoride ise 1.lik dereceleriyle aldım. Ortaokulda ise Türkiye Okullar Arası Zeka Oyunları Şampiyonası'nı Türkiye 26.lığı, Türkiye Geneli Matematik Düşünme Becerileri Yeterlik Belirleme Sınavı - ThinkerMath'i ise Türkiye 10.luğu dereceleri ile tamamladım. Ayrıca Maral Dans ve Müzik Topluluğu'nun 2016 yılında düzenlediği Ortaokullar Arası Dans Yarışması'nda okul olarak 1.lik elde ettik. Bunun yanı sıra 3 senedir futbol ile ilgilenmekte ve halen Validebağ Spor Kulübü'nde futbol oynamaktayım. Bana bu yarışmada destek olan aileme ve okulumuz Matematik ve Zeka Oyunları öğretmeni Sibil Yelegen'e çok teşekkür ederim.",
    about:
      "21 Mart 2008 İstanbul doğumluyum. Anasınıfından itibaren Özel Kalfayan Ermeni Okulu'nda okudum ve 2021 yılında mezun oldum...",
  },
  {
    result: "Çocuklar Onuncusu",
    name: "İrem Efek",
    medal: 10,
    photo: "",
    about2:
      "Yeşilova İmam Hatip Ortaokulu’nda 6.sınıf öğrencisiyim ve aynı zamanda Burdur Alpaslan Ali Can Bilim Sanat Merkezi'nde öğrenim görüyorum. Boş vakitlerimde ise şiir yazmaktan, kitap okumaktan, keman çalmaktan ve evcil hayvanlarımla vakit geçirmekten büyük keyif alıyorum. Ayrıca il genelinde, ulusal ve uluslararası düzeyde düzenlenen edebiyat ve matematik dalında düzenlenen yarışmalara katılıyorum. Zekâ oyunları yarışmalarına ve zekâ oyunları çözmeye büyük bir ilgi duyuyorum. Bu alanda da kendimi geliştirmek için büyük bir çaba sarf ediyorum. Bunun karşılığında da Türkiye Zekâ Vakfı tarafından düzenlenen 26. Akıl ve Zekâ Oyunları Yarışması'nda Türkiye 10.su olmaktan büyük bir mutluluk duyuyorum ve sizlere böyle bir başarı sonucunda kendimi anlatmaktan da onur duyuyorum. Herkese zekâ oyunları oynamayı tavsiye ederek sözlerimi bitirirken; Türkiye 26. Zekâ Oyunları Yarışması’nın düzenlenmesine katkı sağlayan ve yarışmayı düzenleyen Türkiye Zekâ Vakfı’na şükranlarımı sunuyorum. Bu yarışmaya katılmamı teşvik eden matematik öğretmenim Ayşe ŞİMŞEK BATAR'a saygı ve sevgilerimi iletiyorum.",
    about:
      "Ben İrem EFEK, 12 yaşındayım. Yeşilova İmam Hatip Ortaokulu’nda 6.sınıf öğrencisiyim ve aynı zamanda Burdur Alpaslan...",
  },
];

const gencler2021 = [
  {
    result: "Gençler Birincisi",
    name: "Efe Kaan Keremoğlu",
    medal: 1,
    photo: "",
    about2:
      "Adım Efe Kaan Keremoğlu. 24 Temmuz 2005 tarihinde İstanbul'da doğdum fakat aslen Orduluyum. Yalova'nın Altınova ilçesinde yaşıyorum. Fakat okulum servis mesafesinde olmadığı için okul zamanları kocaelide kalıyorum. İlkokulu Piyalepaşa İlköğretim okulunda, ortaokulu ise Yalova Doğa kolejinde okudum. İlkokulda ve ortaokulda başarılı bir öğrenciydim. Küçüklüğümden beri matematiğe ilgi duyuyorum. Bu sebeple çeşitli yarışmalara girdim ve dereceler elde ettim. 5 yıldır, Türkiye Zeka Vakfının düzenlediği oyun yarışmasına katılıyorum. İlk başlarda pek başarılı olamasam da, soru gördükçe başarılarım arttı. Hobilerim arasında futbol oynamak, müzik dinlemek,dizi-film izlemek ve matematikle uğraşmak vardır. Şu an Enka Fen ve Teknoloji lisesinde eğitimimi sürdürüyorum. ",
    about:
      "24 Temmuz 2005 tarihinde İstanbul'da doğdum fakat aslen Orduluyum. Yalova'nın Altınova ilçesinde yaşıyorum...",
  },
  {
    result: "Gençler Birincisi",
    name: "Kadir Kaan Erbaş",
    medal: 1,
    photo: "",
    about2:
      "2005 Ankara doğumluyum. 2008 yılında İzmit'e taşındım, hala da İzmit'te yaşıyorum. Kocaeli Bilnet Ortaokulu'ndan mezun oldum. Dilovası Enka Fen ve Teknoloji Lisesi'nde okuyorum, 9. sınıfı bitirdim. Hobilerim arasında; film izlemek, kitap okumak, spor yapmak, müzik dinlemek ve yabancı dil öğrenmek vardır. Ayrıca matematik, fen ve sosyal bilimler gibi konularda kendimi geliştirmeye istekliyim. Öğrendiğim bilgilerin doğruluğunu teyit etmek, bir konuyu ayrı bakış açılarından gözlemlemek, tek bir düşünceye bağlanmadan farklı görüşlere değer vermek ve öz eleştiri yapabilmek önem verdiğim davranışlar arasındadır.",
    about:
      "2005 Ankara doğumluyum. 2008 yılında İzmit'e taşındım, hala da İzmit'te yaşıyorum. Kocaeli Bilnet Ortaokulu'ndan...",
  },
  {
    result: "Gençler Üçüncüsü",
    name: "Ahmet Eren Gözübenli",
    medal: 3,
    photo: "",
    about2:
      "2004 yılında Kahramanmaraş’ta doğdu. İlkokul ve ortaokulu Hatay’da Nizamettin Özkan İlkokulu ve Beyhan Gençay Ortaokulu’nda okudu. 3. Sınıftan itibaren BİLSEM ’de eğitim almaya başladı. 2018-2019 eğitim öğretim yılında İstanbul-Kartal Anadolu İmam Hatip Lisesi’nde lise öğrenimine başladı. Hazırlık sınıfına devam ederken akıl oyunları ve matematik olimpiyatı eğitimi almaya başladı. 2019 ve 2020 TZV Zeka oyunları yarışmalarında finalde yarıştı. 2019-2020 TZV Türkiye Okullararası Zeka oyunları şampiyonasında okul takımıyla birlikte finalde yarıştı. Akdeniz Üniversitesi tarafından 2021 yılında düzenlenen 25. Ulusal Antalya Matematik Olimpiyatı’nda 3. olarak altın madalya kazandı. 2020-2021 TZV Türkiye Okullararası Zeka oyunları şampiyonasında okul takımıyla 10. sınıflar kategorisinde 1. oldu. TZV Oyun 2021 Türkiye 26. Zeka oyunları yarışmasında gençler kategorisinde 3. oldu.",
    about:
      "2004 yılında Kahramanmaraş’ta doğdu. İlkokul ve ortaokulu Hatay’da Nizamettin Özkan İlkokulu ve Beyhan Gençay...",
  },
  {
    result: "Gençler Dördüncüsü",
    name: "Ege Yiğit Köksal",
    medal: 4,
    photo: "",
    about2:
      "Ben Ege Yiğit Köksal. 9. Sınıfım. Bursa’da doğdum. İlkokulu Yalova Ziya Gökalp İlköğretim Okulu’nda ortaokulu ise Özel Yalova Doğa Koleji’nde okudum. İlkokul ve ortaokulda başarılı ve çalışkan bir öğrenciydim. Bu sayede LGS sınavında iyi bir başarı kazandım. Spor yapmayı severim. 7 yaşımdan beri basketbol ve satranç oynuyorum. Çeşitli satranç ve basketbol müsabakalarında dereceler elde ettim. Ayrıca matematik ve zeka oyunlarına ilgim var. Matematik dersini ilkokuldan beri çok severim. Sayılar bana harflerden hep daha eğlenceli gelmiştir. Oyun 2021’den önce diğer Türk Zeka Vakfı yarışmaları ve çeşitli başka zeka oyunu yarışmalarına katılmıştım. Ayrıca Oyun 2018’de 7.lik derecesini elde etmiştim. Şu anda Özel Enka Fen ve Teknoloji Lisesinde öğrenimime devam etmekteyim.",
    about:
      "9. Sınıfım. Bursa’da doğdum. İlkokulu Yalova Ziya Gökalp İlköğretim Okulu’nda ortaokulu ise Özel Yalova Doğa...",
  },
  {
    result: "Gençler Altıncısı",
    name: "Mete Gökhan Coşkun",
    medal: 6,
    photo: "",
    about2:
      "16.02.2005'te Kartal'da doğdum. Kocaeli'nin Gebze ilçesinde yaşıyorum. İlkokulu 50. Chrysler İlköğretim Okulu'nda, ortaokulu burs kazanarak Özel Pelikan Koleji'nde okudum. İlkokulda katıldığım bir yarışmayla matematiğe olan ilgim arttı. Geçen süreçte çeşitli yarışmalara katıldım ve dereceler elde ettim. İlk olarak TZV Oyun'a matematik öğretmenim sayesinde katıldım. İlk senemde başarılı olamasam da düzenli olarak katılmaya devam ettim ve derece aldım. Hobilerim arasında yüzmek, kitap okumak, basketbol oynamak ve bulmaca çözmek vardır. Başarılı bir ilköğretim hayatı ve LGS sınavından sonra hedeflediğim Özel Enka Fen ve Teknoloji Lisesi'ne girdim. Şuan öğrenimime bu lisede devam etmekteyim. ",
    about:
      "16.02.2005'te Kartal'da doğdum. Kocaeli'nin Gebze ilçesinde yaşıyorum. İlkokulu 50. Chrysler İlköğretim Okulu'nda...",
  },
  {
    result: "Gençler Yedincisi",
    name: "Melisa Karataş",
    medal: 7,
    photo: "",
    about2:
      "20 Nisan 2005 yılında Ankara'da doğdum. Şuan eğitimime İzmir Takev Fen Lisesinde devam etmekteyim ve 10. sınıfı bitirdim. Boş zamanlarımda piyano çalarım, bulmaca çözerim, yazılım ve programlama çalışırım, basketbol oynarım ve bisiklet sürerim. Yaklaşık 2,5 yıldır akıl oyunları ile uğraşmaktayım ve TZV Oyun, TBT Beyin Olimpiyatları, TAZOF Oyuncu Beyinler gibi yarışmalara katıldım. Ayrıca Matematik ve Bilgisayar olimpiyatları, Kanguru, Urfodu ve türevi olan sınavlara da katıldım. Gelecekte Bilgisayar Mühendisi olmak istiyorum bu sebeple bilim, matematik ve bilgisayar alanında yapabileceğim çalışmaların faydalı olduğunu düşünüyorum. Bu sebeple IBM Quantum Computing eğitimi ve Türkiye'deki bazı üniversitelerin liseye yönelik eğitimlerine de katıldım. Hedefime yönelik olarak da Python ve C programlama dillerini biliyorum ve Arduino, App Inventor gibi platformlarda da çalışmalar yaptım.",
    about:
      "20 Nisan 2005 yılında Ankara'da doğdum. Şuan eğitimime İzmir Takev Fen Lisesinde devam etmekteyim ve 10. sınıfı...",
  },
  {
    result: "Gençler Dokuzuncusu",
    name: "Tarık Yıldız",
    medal: 9,
    photo: "",
    about2:
      "Ben Tarık YILDIZ. 27 Ocak 2004 yılında İstanbul’da dünyaya geldim. Öğrenim hayatıma Kartal Doğa Kolejinde başladım. Daha sonra ortaokul kısmını Küçükyalı Tekden Kolejinde tamamladım. 2018 yılında Maltepe Fen Lisesini kazandım ve halen aynı okulda 12.sınıf öğrencisiyim.  Öğretmenlerimin ve ailemin etkin yönlendirmeleriyle 2015 yılından beri Kartal Prof. Dr. Şaban Teoman DURALI Bilim ve Sanat Merkezi’nde genel yetenek alanında eğitim alıyor ve projelerde aktif rol almaya çalışıyorum. Bilimsel alanlar ve projeler dışında basketbola da ilgi duyuyorum.  Yeteneklerim doğrultusunda U12, U13, U14 basketbol liginde de yer aldım. Akıl oyunlarına ilgim küçük yaşlarda başladı. Hedefim ileride yetişkinler ve emektarlar kategorilerinde de yarışmak. 4 yıldır Oyun TZV finallerinde yarışıyorum. Ayrıca Maltepe Fen Lisesi olarak Türkiye Okullar Arası Akıl ve Zekâ Oyunları Şampiyonası’nda takım olarak Türkiye birincisi olduk. Satranç oynamayı, akıl ve zekâ oyunlarını, bulmaca çözmeyi , basketbol oynamayı ve kitap okumayı çok seviyorum. ",
    about:
      "27 Ocak 2004 yılında İstanbul’da dünyaya geldim. Öğrenim hayatıma Kartal Doğa Kolejinde başladım. Daha sonra...",
  },
  {
    result: "Gençler Dokuzuncusu",
    name: "Aleyna Nur Temel",
    medal: 9,
    photo: "",
    about2:
      "Merhabalar, ben Aleyna Nur Temel. Maltepe Fen Lisesinde iki senedir eğitim görmekteyim. Liseye kadar daha çok fiziksel sporlarla ilgilenen birisi olsam da son zamanlarda akıl ve zeka oyunlarıyla beraber daha çok zihinsel sporlar yapmaya başladım.Akıl ve Zeka Oyunları ile çok uzun bir geçmişim yok fakat başladığım günden beri istekle bu işe devam ediyorum.Bunun öncesinde ortaokul zamanlarımda beyin ve matematik olimpiyatları sınavlarına girerken liseye başladığımda şans eseri okulumuz Akıl ve Zeka Oyunları Takımı'nda kendime yer edindim ve benzer yarışmalara hem okul takımıyla birlikte hem de bireysel katılarak kendimi kısa sürede de olsa geliştirdiğimi düşünüyorum.Halen daha bu tarz yarışmalara katılmaya devam ediyorum.Bundan farklı olarak yaklaşık bir senedir 'İstanbul Gençlik Platformu' adı altında gönüllülük projelerinde yer alıyorum ve farklı türde eğitimler alıyorum.",
    about:
      "Maltepe Fen Lisesinde iki senedir eğitim görmekteyim. Liseye kadar daha çok fiziksel sporlarla ilgilenen birisi...",
  },
  {
    result: "Gençler Dokuzuncusu",
    name: "Ezgi Ergüney",
    medal: 9,
    photo: "",
    about2:
      "Ben Ezgi Ergüney. 2003 İstanbul doğumluyum. Ortaokul hayatımda yüzme sporuyla ilgilendim.  2017 yılında Maltepe Anadolu Lisesinde okumaya başladım ve bu sene mezun oldum. Liseye başladığımda robotiğe olan ilgimi fark ettim ve okulumuzun Qubits Robotics takımının bir üyesi oldum. Küçüklüğümden beri akıl, zeka ve mantık oyunlarıyla ilgileniyorum. 2019 yılında okulumuzun akıl oyunları takımına katıldım ve o günden beri yarışmalara katılmaktayım. 2021 yılı TZV Okullar Arası Şampiyonasın’da birinci olduk.",
    about:
      "2003 İstanbul doğumluyum. Ortaokul hayatımda yüzme sporuyla ilgilendim.  2017 yılında Maltepe Anadolu Lisesinde...",
  },
  {
    result: "Gençler Dokuzuncusu",
    name: "Miray Akgün",
    medal: 9,
    photo: "",
    about2:
      "Ben Miray Akgün. Maltepe Fen Lisesi 10. sınıf öğrencisiyim. Yaklaşık olarak iki yıldır akıl ve zeka oyunlarıyla ilgileniyorum. Ortaokul yıllarımda birçok fiziksel aktiviteyle ilgilendim. Liseye geçtikten sonra fiziksel aktivitelerdense teknoloji ve akıl oyunlarıyla ilgilenmeyi tercih ettim. 9.sınıfta FRC takımında PR, yazılım ve sosyal medya içerik sorumlusu olarak görev aldım. 10. sınıfa geçtiğimde okulumuzun teknofest takımına katıldım. Teknofest takımında proje geliştirme ve mekanik çizim alanlarında çalışmaktayım. Akıl ve zeka oyunlarıyla 9. sınıfta şans eseri tanıştım. Geç tanışmış olmama rağmen takım arkadaşlarımın ve yaptığım çalışmaların sayesinde geliştiğimi düşünüyorum. Şimdiye kadar bireysel veya takımca birçok akıl ve zeka oyunu yarışmasına katıldım. Katıldığım yarışmalardan Türkiye Okullar Arası Zeka Oyunları Yarışmasında takım arkadaşım ile 6.lık elde ettik. Önümüzdeki yıllarda da akıl ve zeka oyunları alanında kendimi geliştirmeye devam edeceğim. ",
    about:
      "Maltepe Fen Lisesi 10. sınıf öğrencisiyim. Yaklaşık olarak iki yıldır akıl ve zeka oyunlarıyla ilgileniyorum...",
  },
  {
    result: "Gençler Dokuzuncusu",
    name: "Onur Alp Güngör",
    medal: 9,
    photo: "",
    about2:
      "Benim adım Onur Alp Güngör. Maltepe Fen Lisesi 11.sınıf öğrencisiyim ve ayrıca okulumun akıl oyunları takımındayım. Daha öncesinde de akıl oyunlarına ilgim vardı ama yarışmalara katılmamıştım. Ortaokulda Nazmi Arıkan Fen Bilimleri Okullarındaydım ve akıl oyunları kulübündeydim. Ancak o yıllarda daha çok fiziksel aktivitelerle ilgileniyordum. Okulumun akıl oyunları kulübüne 10.sınıfta katıldım ve o zamandan beri takım olarak ve bireysel olarak birçok akıl ve zeka oyunları yarışmasına katıldım. Katıldığım yarışmalardan Türkiye Okullar Arası Zeka Oyunları yarışmasından takım arkadaşım ile birlikte 1.lik elde ettik. Önümüzdeki yılda da yarışmalara devam ederek kendimi geliştirmeye çalışacağım.",
    about:
      "Maltepe Fen Lisesi 11.sınıf öğrencisiyim ve ayrıca okulumun akıl oyunları takımındayım. Daha öncesinde de akıl...",
  },
];

const yetiskinler2021 = [
  {
    result: "Yetişkinler Birincisi",
    name: "Mert Şahinkoç",
    medal: 1,
    photo: "",
    about2:
      "1990 İzmir doğumluyum. Lise eğitimimi İzmir Fen Lisesi’nde tamamladıktan sonra lisans, yüksek lisans ve doktora derecelerimi Boğaziçi Üniversitesi Endüstri Mühendisliği’nden elde ettim. Üniversitede sekiz yıl araştırma görevlisi olarak çalıştıktan sonra şu anda sağlık ekonomisi alanında danışman ve veri bilimcisi olarak çalışmaktayım. Mesleğimde görev aldığım tüm pozisyonlarda ve projelerde sahip olduğum bilimsel bilgi ve teknik tecrübe ile toplumun ihtiyaçlarına akılcı çözümler sunabilmeyi hedeflemekteyim. Evliyim ve İstanbul’da ikamet ediyorum. Başta tenis, yüzme ve futbol olmak üzere spora; seyahat edip dünyayı ve zenginliklerini keşfetmeye büyük ilgili duymaktayım. Matematiğe, zekâ oyunlarına ve bulmacalara küçüklüğümden beri ilgi duymaktayım. Oyun’a üç yıldır katılıyorum. Düzenlediği bu ve diğer etkinliklerden dolayı TZV’ye minnettarlığımı ifade etmek isterim. Yeni nesillere analitik ve bilimsel düşüncenin önemini göstermesi ve matematik sevgisini aşılaması açısından yapılan bu çalışmaları çok değerli buluyorum. Önümüzdeki yıllarda tekrar görüşmek dileğiyle.",
    about:
      "1990 İzmir doğumluyum. Lise eğitimimi İzmir Fen Lisesi’nde tamamladıktan sonra lisans, yüksek lisans ve doktora...",
  },
  {
    result: "Yetişkinler Birincisi",
    name: "Ulaş Torun",
    medal: 1,
    photo: "",
    about2:
      "1989 Bursada doğdu. İlköğretimi İzmir Ödemiş’te tamamladıktan sonra yatılı olarak İzmir Atatürk Lisesinde eğitimine devam etti. İtü İmalat Mühendisliği mezuniyeti sonrası otomotiv sektöründe çalışmaya başladı. Satın alma şefi olarak Honda Türkiye fabrikasında çalışmaya devam ediyor. Futbol, tenis, satranç oynamayı seviyor. Motor sporları ve futbol başta olmak üzere sporun her türünü izlemekten keyif alıyor. Hayatın her alanında çözüm odaklı olmanın önemli olduğuna inanıyor. Türkiye Zeka Vakfı ve düzenlenen yarışmaların bu anlamda çok faydalı olduğunu düşünüyor. 4 yıldır aktif olarak yarışmalara katılım gösteriyor.",
    about:
      "1989 Bursada doğdu. İlköğretimi İzmir Ödemiş’te tamamladıktan sonra yatılı olarak İzmir Atatürk Lisesinde eğitimine...",
  },
  {
    result: "Yetişkinler Birincisi",
    name: "Devran Torun",
    medal: 1,
    photo: "",
    about2:
      "19 Nisan 1989 tarihinde Bursa’da dünyaya geldim. 1994 yılında aile büyüklerimizin yaşadığı İzmir’in Ödemiş ilçesine taşındık ve ilköğretim eğitimimi burada tamamladım. İzmir Atatürk Lisesi’nde geçen dört yıllık lise eğitimimin ardından üniversite eğitimim için İstanbul’a gittim. 2013 yılında İstanbul Teknik Üniversitesi Gemi İnşaatı ve Deniz Bilimler Fakültesi Gemi ve Deniz Teknoloji Mühendisliği Bölümü’nden mezun oldum. 2013-2014 yıllarında askerlik görevimi yerine getirdikten sonra Jotun Boya firmasında çalışmaya başladım. Halen Jotun Boya firmasında Yat Boyaları Sorumlusu olarak çalışıyorum. Çalışma hayatım dışında kalan zamanı, eşim, ailem ve arkadaşlarımla değerlendirmeyi tercih ediyorum. Doğa sporları ve müzik hobilerimin büyük kısmını oluşturuyor. Genel başlık olarak sosyoloji, psikoloji ve felsefeyi ilgimi çeken konular arasında sayabilirim. Toplumsal değerleri geliştirmeye yönelik organizasyonlarda yer almaya çalışıyorum. Türkiye Zeka Vakfı’nın düzenlediği Zeka Oyunları Yarışması’nın da kişilerin algı ve farkındalığını arttırdığını bu nedenle toplumsal katkı sağladığını düşünüyorum. ",
    about:
      "19 Nisan 1989 tarihinde Bursa’da dünyaya geldim. 1994 yılında aile büyüklerimizin yaşadığı İzmir’in Ödemiş ilçesine...",
  },
  {
    result: "Yetişkinler Dördüncüsü",
    name: "Türkmen Göksel",
    medal: 4,
    photo: "",
    about2:
      "26 Kasım 1979 tarihinde Mersin’de doğdu. İlkokul eğitimini Mersin’de, Ortaokul ve Lise eğitimini Tarsus Amerikan Koleji’nde tamamladı. Ankara Üniversitesi Siyasal Bilgiler Fakültesi (Mülkiye) İktisat Bölümü’nü 2002 yılında dereceyle bitirdi. Branşında birincilikle kazandığı Yükseköğretim Kurulu Yurt Dışı Lisansüstü Bursuyla 2004-2009 yılları arasında ABD’de Minnesota Üniversitesi’nden Yüksek Lisans ve Doktora dereceleri aldı. 2002 yılının Kasım ayında Ankara Üniversitesi Siyasal Bilgiler Fakültesi İktisat Bölümü’nde araştırma görevlisi olarak akademik hayata adım attı. 2011 yılında (eski adıyla) Yardımcı Doçent, 2013 yılında Doçent ve 2020 yılında Profesör ünvanlarını aldı. Aynı fakültede 2019 yılında Politika ve Ekonomi Bölümü’nün kuruculuğuna öncülük etti ve halen bu bölümde kurucu bölüm başkanlığı görevini sürdürmektedir. Minnesota, Ankara ve Boğaziçi Üniversite’lerinde çeşitli düzeylerde dersler verdi. Farklı dönemlerde Türkiye Ekonomi Kurumu Yönetim Kurulu üyeliği görevlerinde bulunmuştur. Yurt içi ve yurt dışında yayımlanmış çok sayıda bilimsel makale ve kitabı bulunmaktadır. Akademik ilgi alanları matematiksel modelleme, hesaplamalı iktisat, yapay zekâ uygulamaları ve seçim sistemleri analizidir. Akademi dışındaki ilgi alanlarının başında ise futbol oynamak, izlemek ve zekâ oyunları çözmek gelmektedir. Sıkı bir Beşiktaş taraftarıdır. 2001 yılında TZV’nin düzenlediği Soru Maratonu yarışmasında (ortak) birincilik derecesi elde etmiştir. Ressam olan Senem Göksel ile 2013 yılından beri evli olup, 2016 yılında dünyaya gelen Yusuf Alp Göksel’in babasıdır. ",
    about:
      "26 Kasım 1979 tarihinde Mersin’de doğdu. İlkokul eğitimini Mersin’de, Ortaokul ve Lise eğitimini Tarsus Amerikan...",
  },
  {
    result: "Yetişkinler Beşincisi",
    name: "Gürkan İnal",
    medal: 5,
    photo: "",
    about2:
      "Ben Gürkan İnal. TED Ankara Koleji Lisesi mezunuyum, şu anda Koç Üniversitesi Bilgisayar Mühendisliği bölümünde okuyorum. Bu yarışmaya 2016 yılından beri katılıyorum ve katıldığım her sene de çeşitli dereceler elde ettim. Gençler kategorisinde 2017 birincisi, 2018 ikincisi oldum. Bu yıl da ilk kez katıldığım yetişkinler kategorisinde beşinci oldum. Aynı zamanda 2019 senesinde Türkiye Zeka Vakfı’nın ilk kez düzenlediği Türkiye Okullar Arası Zeka Oyunları Şampiyonasında takım olarak birinciliği elde ettik. TZV’nin düzenlediği bu yarışmalar dışında küçüklüğümden beri akıl oyunlarına karşı hep ilgim vardı ve sürekli bu tarz yarışmalara katıldım. Kısacası akıl oyunlarına ve bununla ilgili yarışmalara oldukça ilgiliyim, önümüzdeki yıllarda da bu yarışmalara tekrar katılmayı düşünüyorum. Yarışmaya katılmış, dereceye girmiş herkesi tebrik ederim; gelecekteki yarışmalar için de tüm yarışmacılara başarılar dilerim.",
    about:
      "TED Ankara Koleji Lisesi mezunuyum, şu anda Koç Üniversitesi Bilgisayar Mühendisliği bölümünde okuyorum...",
  },
  {
    result: "Yetişkinler Altıncısı",
    name: "Salih Alan",
    medal: 6,
    photo: "",
    about2:
      "1984 Kızılcahamam doğumluyum. Zeka oyunlarına ilgim ortaokul yıllarında başladı. O yıllardan beri Oyun yarışmalarını takip ediyorum. Bu yarışmalarda üç kez birinciliğim ve bunlarla birlikte çok sayıda ilk 10 derecem var. 2007 yılından beri Türk Beyin Takımı ve Türk Sudoku Takımı üyesi olarak, Dünya Zeka Oyunları Şampiyonası’nda ve Dünya Sudoku Şampiyonası’nda 13’er kez ülkemizi temsil ettim. 2010 yılındaki Dünya Sudoku Şampiyonası’nda bireysel sıralamada 7. oldum. 2008 yılındaki Dünya Sudoku Şampiyonası’nda ve 2010 yılındaki Dünya Zeka Oyunları Şampiyonası’nda 5. olan takımların üyesiydim. Zeka oyunları alanında; dergi ve kitap gibi yayınların hazırlanması, öğretmen eğitimleri, yarışma düzenlenmesi gibi görevlerde bulundum. Sudoku ve mantığa dayalı zeka oyunları, kelime oyunları, bilmeceler, mekanik oyunlar gibi tüm zeka oyunlarını çözmeyi seviyorum. ODTÜ makine mühendisliği lisans, yüksek lisans ve doktora mezunuyum. Şu anda havacılık sektöründe faaliyet gösteren bir şirkette çalışıyorum. ",
    about:
      "1984 Kızılcahamam doğumluyum. Zeka oyunlarına ilgim ortaokul yıllarında başladı. O yıllardan beri Oyun yarışma...",
  },
  {
    result: "Yetişkinler Yedincisi",
    name: "Arman Özcan",
    medal: 7,
    photo: "",
    about2: "",
    about: "",
  },
  {
    result: "Yetişkinler Yedincisi",
    name: "Okan Tezcan",
    medal: 7,
    photo: "",
    about2:
      "2002 yılında İstanbul’da doğdum. İlkokulu Alkev’de, ortaokulu Bilfen’de ve liseyi Tevitöl’de okudum ve bu sene mezun oldum. Şu an cebimde IB ve MEB diplomam ve üç dil ile Almanya’da bir üniversite okuma hedefim var. Üniversitede elektrik elektronik mühendisliği bölümünü bitirip enerji üzerine yüksek lisans yapmayı planlıyorum.",
    about:
      "2002 yılında İstanbul’da doğdum. İlkokulu Alkev’de, ortaokulu Bilfen’de ve liseyi Tevitöl’de okudum ve bu sene mezun...",
  },
  {
    result: "Yetişkinler Onuncusu",
    name: "Yiğit Şahinkoç",
    medal: 10,
    photo: "",
    about2:
      "Ben Yiğit Şahinkoç. Aralık 1996 İzmir doğumluyum. Üniversiteye kadar eğitim-öğretim hayatımı İzmir’de geçirdim. 2015 senesinde Boğaziçi Üniversitesi İnşaat Mühendisliği bölümüne başladım. Ağustos 2020’de ise bu bölümden mezun oldum. Şu an hayatıma İstanbul’da devam ediyorum. Küçüklüğümden beri zekâ oyunlarına ve yarışmalarına bayılmışımdır. Ayrıca matematik, geometri ve satranç gibi alanlarla da hep ilgili olmuşumdur. Bunların dışında futbol, tenis gibi sporların yanı sıra başta yelken olmak üzere su sporlarıyla ilgileniyorum. İlk defa katıldığım Türkiye Zekâ Vakfı (TZV) yarışmasında da ilk 10’a girip derece elde etmek beni çok mutlu etti. Gerçekten çok heyecan verici ve eğlenceli bir yarışmaydı. Önümüzdeki senelerde de bu yarışmaya katılarak bu heyecan ve eğlenceyi devam ettirmek istiyorum.",
    about:
      "Ben Yiğit Şahinkoç. Aralık 1996 İzmir doğumluyum. Üniversiteye kadar eğitim-öğretim hayatımı İzmir’de geçirdim...",
  },
];

const emektarlar2021 = [
  {
    result: "Emektarlar Birincisi",
    name: "Ali Kılıç",
    medal: 1,
    photo: "",
    about2:
      "1971 yılında Gaziantep'in Nizip ilçesinde doğdu. İlk, orta ve lise eğitimini Nizip'te tamamladı. 1994 yılında Gazi Üniversitesi Teknik Eğitim Fakültesinden mezun olup aynı yıl Nizip Mesleki ve TAL'de Elektrik-Elektronik Teknolojisi Alanı öğretmeni olarak görevine başlamıştır. Halen aynı okulda görevine devam etmektedir. 15 yıldır zeka oyunlarıyla ilgilenmektedir. MEB'in Akıl ve Zeka Oyunları Eğitmeni olarak öğretmen ve öğrencilere zeka oyunları kursları vermektedir. Satranç ve dama antrenörüdür. 2010-2014 yılları arasında Gaziantep il genelinde ZEUGMA ZEKA OYUNLARI yarışmalarını düzenlemiştir. 2017 yılında ünlü matematik ve zeka oyunları yazarlarıyla birlikte PUZZLE BOX (BULMACA KUTUSU) isminde kitapları yayınlanmıştır. 2019 yılında Yunanistan'ın Meis Adası'nda düzenlenen Megistian Aenigma Agon isimli uluslararası bulmaca yarışmasında 1.olmuştur. 2020 yılında zeka oyunlarında göstermiş olduğu yüksek performansından dolayı Nizip Kaymakamlığı tarafından ÜSTÜN BAŞARI BELGESİ  verilmiştir. Bazı yabancı internet sitelerinde zeka soruları yayınlanmıştır. Türkiye'de yayınlanmak üzere kitaplar hazırlamaktadır. Evli ve 3 çocuk babasıdır.",
    about:
      "1971 yılında Gaziantep'in Nizip ilçesinde doğdu. İlk, orta ve lise eğitimini Nizip'te tamamladı. 1994 yılında Gazi...",
  },
  {
    result: "Emektarlar İkincisi",
    name: "Hakan Çetin",
    medal: 2,
    photo: "",
    about2:
      "1971 Erzincan Refahiye doğumluyum. K.T.Ü Biyoloji öğretmenliğinden mezunum.  Eğitim yönetimi ve denetimi alanında yüksek lisansım var. Meslekte 27 yılımı tamamladım. Son olarak  Çayırova Güzeltepe Ortaokulunda Fen Bilimleri öğretmeni olarak çalışmaktayım. Evli iki çocuk babasıyım. Okumayı,doğa yürüyüşlerini severim. Çevre konusunda oldukça duyarlıyım.",
    about:
      "1971 Erzincan Refahiye doğumluyum. K.T.Ü Biyoloji öğretmenliğinden mezunum.  Eğitim yönetimi ve denetimi alanında...",
  },
  {
    result: "Emektarlar Üçüncüsü",
    name: "Hakan Koparal",
    medal: 3,
    photo: "",
    about2:
      "19.08.1970 Erzincan doğumluyum, babamın memuriyeti nedeniyle ilkokulu 5 ayrı şehirde, ortaokul ve liseyi İzmir Karşıyaka’da okudum. Anadolu Üniversitesi Tıp Fakültesi’nden mezun olduktan sonra Ankara Üniversitesi Tıp Fakültesi Nükleer Tıp bölümünü bitirerek Nükleer Tıp Uzmanı oldum. Halen İzmir Dr. Suat Seren Göğüs Hastalıkları ve Cerrahisi Eğitim ve Araştırma Hastanesinde görev yapmaktayım. Her zaman sonuca ulaşamasam da düşündüren, uğraştıran her türlü soruyu çözmekten keyif alırım. İlk olarak iki yıl önce kızım sayesinde öğrendiğim Zeka Oyunları Yarışması’na bu yıl ilk kez katıldım, her kademesinde büyük keyif aldım ve koşullarım elverdiği ve düzenlendiği sürece katılmayı düşünüyorum. Çok sevdiğim, hayatımı paylaştığım sevgili eşim Özlem ile birlikte Defne ve Melisa adında iki kızımız var.",
    about:
      "19.08.1970 Erzincan doğumluyum, babamın memuriyeti nedeniyle ilkokulu 5 ayrı şehirde, ortaokul ve liseyi İzmir...",
  },
  {
    result: "Emektarlar Dördüncüsü",
    name: "Metin Örsel",
    medal: 4,
    photo: "",
    about2:
      "1969 İstanbul doğumlu, Boğaziçi Üniversitesi mezunu, Bilgisayar Mühendisi. 1984’ten beri; akademik, genel kültür, zeka oyunu, bulmaca vb., yurtiçi ve yurtdışı, her bulduğu yarışmaya giren, 6 defa Türkiye Beyin Takımı üyesi, TZV’de 2 derecesi var. Genelde Bilgi Teknolojileri sektöründe olmak üzere, 25 yıl; yerel, bölgesel ve global rollerde çalıştıktan sonra, şimdi de hukuk alanında Yapay Zeka uygulamaları ile ilgili araştırma projeleri yönetiyor. Bulmaca merakı, 1982 yılında bir arkadaşının evinde gördüğü ilk Rubik küpü ile başlamış ve o günden beri artarak devam etmiştir. NxNxN Rubik küpünü çözen bilgisayar programını yaptığında, renkli bir monitörü dahi yoktu!... Özellikle 3 boyutlu topoloji sorularını sever. Soru hazırlayıcılarına çok saygı duyar. Alternatif cevaplar bulmak için uğraşır! 2010 yılında Budapeşte’de yapılan 24 saatlik yarışmanın arasına sıkıştırılmış biçimde TZV yarı finalini de uzaktan yaparak, ‘En uzun süre aralıksız zeka yarışmaları soruları çözen’ kişi unvanı kazanmışsa da, resmi delegasyon olmadığı için Guinness’e girememiştir.",
    about:
      "1969 İstanbul doğumlu, Boğaziçi Üniversitesi mezunu, Bilgisayar Mühendisi. 1984’ten beri; akademik, genel kültür...",
  },
  {
    result: "Emektarlar Beşincisi",
    name: "Aliye Yalçın",
    medal: 5,
    photo: "",
    about2:
      "1966 yılında Antakya’da doğdum. İlk ve orta öğretimimi Antakya’da tamamladım. 1988 yılında Hacettepe Üniversitesi Ekonomi Bölümünden mezun oldum. Bir yazılım firmasında eğitim-destek uzmanı olarak çalıştım. Daha sonra uzun süre bir inşaat firmasında Personel Müdürü olarak çalıştım ve oradan emekli oldum. Evliyim, bir kızım var. Güne akıl oyunlarından birkaç soru çözerek başlarım. Okumak, bulmaca çözmek, klasik müzik dinlemek, spor yapmak ve yeni yerler tanımak özel ilgi alanlarımdır. Çocukluğumda başladığım yüzmeye Ankara Üniversitesi Spor Kulübü bünyesinde lisanslı sporcu olarak devam ediyorum. Kendi yaş grubumda Kurbağalama stilinde derecelerim vardır. Ayrıca Ankara Tenis Kulübü üyesi olarak aktif bir şekilde tenis oynamaktayım. Çeşitli turnuvalarda kupalar kazandım. Bir süre gönüllü olarak çalıştığım Çağdaş Yaşamı Destekleme Derneği üyesiyim. 1989 yılında bir mizah öyküm efsane Gırgır’da yayınlandı. TV’de Kelime Oyunu programına katıldım. Gün şampiyonu oldum. Muhteşem bir ailem ve harika arkadaşlarım olduğu için şanslı bir insanım.",
    about:
      "1966 yılında Antakya’da doğdum. İlk ve orta öğretimimi Antakya’da tamamladım. 1988 yılında Hacettepe Üniversitesi...",
  },
  {
    result: "Emektarlar Altınıcısı",
    name: "Latif Haliloğlu",
    medal: 6,
    photo: "",
    about2:
      "20 Şubat 67 doğumlu olmama rağmen, okul yaşımdan önce okumayı evde sökünce, dönem ortasında okula kayıt olabilmek için o yaşıma kadar çıkartılmamış olan nüfus kaydım 25 Aralık 66 olarak yapılmıştır. Böylece Etiler Behçet Kemal ilkokulu ile başlayan eğitim hayatım Beşiktaş Atatürk (sonra Anadolu eklendi) Lisesi ve Boğaziçi Üniversitesi ile devam etti. 1988 de Elektrik-Elektronik Müh. ve Matematik Bölümlerinden lisans diplomalarını aldım. Aynı yıl BÜ Matematik Bölümünde Araştırma Görevlisi olarak, BÜ Sistem ve Kontrol Müh. yüksek lisans öğrencisi olarak başladım. 1991 de aldığım MS diplomasıyla BÜ Elektrik-Elektronik Müh. Doktora programına kabul edildim. 1996’da programı bitirmeden üniversitedeki görevimden ve öğrencilikten ayrıldım. 2004 yılına kadar bazı butik dershanelerde matematik hocası ve kurucu ortak olarak çalıştıktan sonra 2004 yılında THY’de çalışmaya başladım. Çeşitli kademelerden çalıştıktan sonra 2011 de BT GMY bağlı Kargo Çözümleri Müdürü olarak vazifeye başladım. 2019’da THY Teknik ve 2021’de THY Teknoloji firmalarında proje yöneticisi olarak görev yaptım ve halen THY Teknolojide çalışmaya devam ediyorum. Evli iki çocuk babasıyım. 2016 yılında Parkinsons teşhisiyle birlikte 6 yıldır bu hastalıkla da mücadele etmekteyim. Zeka yarışmalarını seviyorum.",
    about:
      "20 Şubat 67 doğumlu olmama rağmen, okul yaşımdan önce okumayı evde sökünce, dönem ortasında okula kayıt olabilmek...",
  },
  {
    result: "Emektarlar Altıncısı",
    name: "Veli Çıracı",
    medal: 6,
    photo: "",
    about2:
      "Aksaray ilinin Eskil ilçesinde 1962’ de doğdum. İlk, orta ve lise öğrenimini Konya’ da tamamladıktan sonra, Konya Selçuk Üniversitesi Mühendislik Fakültesinden Kimya Mühendisi olarak mezun oldum. Kısa bir süre Konya’da özel bir süt fabrikasında Kimya Mühendisi olarak çalıştım. Türkiye’nin çeşitli yerlerinde 34 yıldır kimya öğretmenliği yapıyorum. 1987-1992 arası Erzincan Çayırlı Lisesinde, 1992-2007 arasını Muğla Fethiye Lisesinde 2007-2012 arasını Antalya Alanya Türkler Anadolu Lisesinde, 2012-2017 arasını Antalya Kemer Göynük Fen Lisesinde, 2017 sonrasına da Antalya Alanya Hüseyin Girenes Fen Lisesinde devam ediyorum. 2015 yılında yayımlamış olduğum Öğretmeye Doyamam Ben adında bir şiir kitabım vardır. Türk Halk edebiyatı tarzında şiir yazmak, matematikte hızlı işlem yapmak ilgi alanlarım arasındadır. Mantık ve zekâ sorularını da çözmeye ilgim olup 2018 yılındaki Zeka Vakfının düzenlediği yarışmada 7.lik derecem vardır.",
    about:
      "Aksaray ilinin Eskil ilçesinde 1962’ de doğdum. İlk, orta ve lise öğrenimini Konya’ da tamamladıktan sonra, Konya...",
  },
  {
    result: "Emektarlar Sekizincisi",
    name: "Zekeriya İnal",
    medal: 8,
    photo: "",
    about2:
      "Merhabalar, Ben Zekeriya İnal. Maden mühendisiyim, bir kamu kuruluşunda çalışıyorum. Evli ve iki çocuk babasıyım. Bu tür akıl oyunları yarışmalarına ilgi duyuyor ve zaman zaman katılıyorum. İlk kez bu yıl dereceye girdim. Bu tür yarışmalara ilgi duyan çevremdeki tüm gençlere ve kendine güvenen dostlarıma katılmaları konusunda teşvik ediyorum. Yarışmaya katılan herkesi kutluyorum. Sonraki yarışmalarda buluşmak üzere…",
    about:
      "Maden mühendisiyim, bir kamu kuruluşunda çalışıyorum. Evli ve iki çocuk babasıyım. Bu tür akıl oyunları yarışmalarına...",
  },
  {
    result: "Emektarlar Dokuzuncusu",
    name: "Sülün Turgay",
    medal: 9,
    photo: "",
    about2:
      "Yarışmaya İstanbul’dan katıldım. 64 yaşındayım. Yüksek Elektrik mühendisiyim ve Boğaziçi Üniversitesi Fizik Bölümünden emekliyim. Emeklilik dönemimde matematik ve fizikten hiç kopmadım. 5 yıl önce TZV sınavları ile tanıştıktan sonra, TZV’ye ek olarak TBT Sudoku ve Ötesi ile de uğraşıp TBT eğitmenlik sertifikası aldım ama psikolojik formasyonum olmadığı için kullanma fırsatı bulamadım. Akademik hayatımda ise 1976’da ROBERT KOLEJ’den mezun olduktan sonra, 1980’de BOĞAZİÇİ ÜNİVERSİTESİ  Elektrik Bölümü’nü 3.90 ortalama ile bitirdim. Sonrasında burslu olarak Amerika’da University of California SANTA BARBARA’da masterımı 3.98 notla tamamladım. Tüm eğitim hayatım boyunca TÜBİTAK’tan matematik dalında ödül ve teşvik bursu aldım. Mezuniyette Matematik ödülü ve MAA( Mathematical Association of America)den de başarı ödülü aldım. Amerika’da Masterımı tamamladıktan sonra 6 ay Silikon Vadisi’nde çalışıp Çalışma Belgem çıkmasına rağmen ülkeme döndüm. Özel sektörde bir süre çalıştıktan sonra matematik aşkım beni geri akademik hayata çağırdı. Boğaziçi Üniversitesinde 1992 yılında Öğretim Görevlisi oldum ve 20 yıla yakın full time ders verdim. Emeklilik günlerimde tzv,TBT soruları çözüyor ve çevremdeki gençlere elimden geldiğince ileri matematik ve fen konularında yardımcı olmaya çalışıyorum. EMREHAN  HALICI Bey’e emekli ligini açtığı  ve bize bu imkanı verdiği için çok teşekkür ediyorum.",
    about:
      "Yarışmaya İstanbul’dan katıldım. 64 yaşındayım. Yüksek Elektrik mühendisiyim ve Boğaziçi Üniversitesi Fizik Bölümünden...",
  },
];

const allUsers = [
  { title: "MİNİKLER", users: minikler2021 },
  { title: "ÇOCUKLAR", users: cocuklar2021 },
  { title: "GENÇLER", users: gencler2021 },
  { title: "YETİŞKİNLER", users: yetiskinler2021 },
  { title: "EMEKTARLAR", users: emektarlar2021 },
];

function HallOfFame2021({ selectedUser, onShowFull, onModalClose }) {
  return (
    <div className="hallOfFame">
      {header}
      {allUsers.map((category) => (
        <HallOfFameCategoryBox
          key={"category_box" + category.title}
          title={category.title}
          users={category.users}
          onShowFull={onShowFull}
        />
      ))}
      {selectedUser && (
        <HallOfFameUserModal
          selectedUser={selectedUser}
          onModalClose={onModalClose}
        />
      )}
    </div>
  );
}

export default HallOfFame2021;
