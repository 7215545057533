import React from "react";
import AppContext from "../../AppContext";
import { SuccessDocument2023 } from "../../components/organisms/pdfs/2023/successDocument";
import { AttendanceDocument2023 } from "../../components/organisms/pdfs/2023/attendanceDocument";
import { FinalSebfDocument2023 } from "../../components/organisms/pdfs/2023/05_finalSebfDocument";
import { ElemeSebfDocument2023 } from "../../components/organisms/pdfs/2023/04_elemeSebfDocument";
import { YFSuccessDocument2023 } from "../../components/organisms/pdfs/2023/yf_successDocument";

const Year2023 = () => {
  const { user } = React.useContext(AppContext);

  const userProductData = user?.products;
  const product2023 = userProductData?.oyun2023;

  const elemeKatilim = product2023?.eleme_sonuc === 2 && (
    <AttendanceDocument2023 userName={user.name} />
  );

  const elemeBasari = product2023?.eleme_sonuc === 1 && (
    <SuccessDocument2023 userName={user.name} />
  );

  const elemeSebf =
    product2023?.eleme_sonuc === 2 && (user?.birth_year || 0) > 2004 ? (
      <ElemeSebfDocument2023
        userName={user.name}
        fileNumber={product2023.file_number}
      />
    ) : null;

  const yfBasari = product2023?.yf_sonuc === 1 && (
    <YFSuccessDocument2023 userName={user.name} />
  );

  const finalSebf = product2023?.final_derecesi &&
    (user?.birth_year || 0) > 2004 && (
      <FinalSebfDocument2023
        userName={user.name}
        fileNumber={user.products?.oyun2023?.file_number}
      />
    );

  const noDocuments =
    !elemeKatilim && !elemeBasari && !yfBasari && !elemeSebf && !finalSebf ? (
      <p> Bu kategoride belgeniz bulunmamaktadır.</p>
    ) : null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {elemeKatilim}
        {elemeBasari}
        {yfBasari}
        {elemeSebf}
        {finalSebf}
        {noDocuments}
      </div>
    </div>
  );
};

export default Year2023;
