import "./aboutPage.css";

export default function AboutPage() {
  return (
    <div className="aboutPageContainer">
      <h2 style={{ color: "#35387b" }}>TÜRKİYE ZEKA OYUNLARI YARIŞMASI </h2>
      <div className="subtitleText">
        ’Yaşama farklı gözlerle bakmayı teşvik eden bir oyun. Değişimin,
        dönüşümün başlangıcı. Bilim, sanat ve teknolojiyi tüketen değil, üreten
        bir toplum olma yolunda bir adım. Düşünmeye, cevap aramaya; dahası, soru
        sormaya bir çağrı.’
      </div>

      <div className="dispRow">
        <img
          style={{ maxWidth: 340, marginBottom: 10 }}
          src={"/assets/images/yazi.jpg"}
          alt="hakkımızda"
        />

        <div className="innerColumn">
          <div
            style={{ fontSize: "large", textAlign: "center", fontWeight: 600 }}
          >
            Türkiye Zeka Vakfı her yıl, her yaştan, her kesimden, düşünen ve
            sorgulayan bireyleri bu oyunla bir araya getiriyor.
          </div>

          <p style={{ textAlign: "justify" }}>
            Türkiye Zekâ Oyunları Yarışması, 1996 yılından beri, Milli Eğitim
            Bakanlığı, ODTÜ ve TÜBİTAK’ın da katkılarıyla Türkiye Zeka Vakfı
            tarafından düzenlenmekte ve büyük ilgi görmektedir. Yarışma,
            etkinliğin yurt çapında duyurulması ile başlar. Ulusal gazete ve
            dergilerde, üniversitelerde, ilköğretim okullarında, Gençlik ve Spor
            İl Müdürlüklerinde ve İnternette duyurulan yarışmaya, yaş ve eğitim
            sınırlaması olmadan dileyen herkes katılabilir. Türkiye Zeka Vakfı
            tarafından belirlenen ve duyurulan süre içerisinde yarışmaya
            katılanlardan başarılı olanlar Yarı Final Sınavına girmeye hak
            kazanırlar. Bu aşamayı da başarıyla geçen yarışmacılar, Ankara’da
            düzenlenen Final Sınavına katılırlar. Final Sınavı ile aynı gün
            düzenlenen Ödül Töreni’nde derece alan yarışmacılara ödüller
            verilir. İnsanlarımızın düşünme becerilerini geliştirmeyi,
            çevrelerini daha iyi algılayabilmelerini ve sorunlar karşısında daha
            nitelikli çözümler üretme yeteneklerini arttırmayı hedefleyen
            Türkiye Zekâ Oyunları Yarışması, düşünen ve üreten bireylerle “Bilgi
            Toplumu” olma yolunda yapılan önemli bir etkinliktir.
          </p>
        </div>
      </div>

      <h2 className="kurulHeader"> TÜRKİYE ZEKA VAKFI SORU KURULU</h2>

      <ul className="list-items">
        <li>
          Emrehan Halıcı - Kurul Başkanı, Soruların Hazırlayıcısı Türkiye Zeka
          Vakfı Başkanı
        </li>
        <li>
          Aziz Ateş – Kurul Üyesi, İtiraz Sorumlusu Matematikçi, Oyun 2008 ve
          Oyun 2012 Birincisi, Oyun 2004 İkincisi, Oyun 2009 Dördüncüsü
        </li>
        <li>
          Doç. Dr. Özgür Kişisel – Kurul Üyesi Akademisyen (Matematik Bölümü,
          ODTÜ)
        </li>
        <li>
          Doç. Dr. Fatih Sulak – Kurul Üyesi Akademisyen (Matematik Bölümü,
          Atılım Üniversitesi), Türkiye Go Şampiyonu (6 defa), Oyun 1999
          İkincisi, Oyun 1997 Üçüncüsü
        </li>
      </ul>
    </div>
  );
}
