import React, { useEffect, useState, useCallback, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import "./adminPage.css";
import {
  createAuthUser,
  fbLogin,
  requestPasswordReset,
  searchUsers,
  adminChangesUserData,
  pullUserData,
  doSmth,
  readStats,
  verifyEmailManually,
  exportAllUsersToExcel,
  exportyfResultUsersToExcel,
  getElemeSuccessfulEmailsYf,
} from "../../db/firebaseMethods";
import AppContext from "../../AppContext";
import LoadingBlock from "../../components/organisms/loadingBlock/loadingBlock";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

export default function AdminPage() {
  const [state, setStateValues] = useState({
    searchString: "",
    users: [],
    selectedUser: null,
    newPass: null,
    newEmail: null,
    isLoadingVisible: false,
    selectedTabIndex: 0,
  });

  const setState = (obj) => {
    setStateValues({
      ...state,
      ...obj,
    });
  };

  const { setCentralState, user } = React.useContext(AppContext);
  // if (!user && user.) return <Navigate replace to="/profile" />;

  const onRowSelected = async (u) => {
    let selectedUser = await pullUserData(u.objectID);
    setState({ selectedUser });
    console.log("selectedUser", selectedUser);
  };

  const searchOnAlgolia = async () => {
    if (!user.algolia_search_api_key)
      alert("User tablonuzda Api key yok. Yavuza bilgi verin, eklsin :)");
    else {
      const users = await searchUsers(
        user.algolia_search_api_key,
        state.searchString
      );
      console.log("users", users);
      setState({ users, selectedUser: null });
    }
  };

  // const buttonForDeveloper = user?.email === "yavuz@halici.com.tr" && (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "flex-start",
  //       marginLeft: 10,
  //     }}
  //   >
  //     <Button
  //       style={{ marginTop: 20, marginBottom: 50, width: 120 }}
  //       onClick={doSmth}
  //       color="secondary"
  //       variant="contained"
  //     >
  //       BASMA SAKIN!
  //     </Button>
  //   </div>
  // );
  const buttonForElemeEmails = (
    <Button
      style={{
        marginTop: 20,
        marginBottom: 50,
        marginLeft: 20,
        width: 240,
      }}
      onClick={getElemeSuccessfulEmailsYf}
      color="secondary"
      variant="contained"
    >
      Eleme Başarılı Olanları İndir
    </Button>
  );

  const buttonForAdmin = (
    <Button
      style={{
        marginTop: 20,
        marginBottom: 50,
        marginLeft: 20,
        width: 160,
      }}
      onClick={exportAllUsersToExcel}
      color="secondary"
      variant="contained"
    >
      TÜM KULLANICILARI İNDİR
    </Button>
  );

  const userListBox = (
    <div className="userListBox">
      <div style={{ display: "flex" }}>
        {/* {buttonForDeveloper} */}
        {buttonForAdmin}
        {/* {buttonForElemeEmails} */}
      </div>
      <div className="searchRow">
        <TextField
          id="searchString"
          label="Ad-Soyad-Email-Telefon"
          // name="searchString"
          // defaultValue={newName}
          value={state.searchString}
          onChange={(e) => setState({ searchString: e.target.value })}
          autoComplete={"disabled"}
          style={{ width: 400, marginRight: 5 }}
          size="small"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchOnAlgolia();
            }
          }}
        />
        <Button
          variant="contained"
          disabled={state.searchString.length < 4}
          onClick={searchOnAlgolia}
        >
          ARA
        </Button>
      </div>

      {state.users.length > 0 && generateUserTable(state.users, onRowSelected)}
    </div>
  );

  return (
    <div className="pageBox">
      <Tabs
        value={state.selectedTabIndex}
        onChange={(event, newValue) => {
          setState({ selectedTabIndex: newValue });
        }}
        aria-label="basic tabs example"
      >
        <Tab label="KULLANICILAR" />
        <Tab label="İSTATİSTİKLER" />
      </Tabs>

      <TabPanel value={state.selectedTabIndex} index={0}>
        <div className="usersTab">
          {userListBox}
          {state.selectedUser && (
            <SingleUserBox selectedUser={state.selectedUser} />
          )}
        </div>
      </TabPanel>

      <TabPanel value={state.selectedTabIndex} index={1}>
        <StatsTab />
      </TabPanel>
    </div>
  );
}

const generateUserTable = (users, onRowSelected) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: "bold" }}>E-mail</TableCell>
            <TableCell style={{ fontWeight: "bold" }}>Ad-Soyad</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((u) => (
            <TableRow
              hover
              key={u.email}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="tableRow"
              onClick={() => {
                onRowSelected(u);
              }}
            >
              <TableCell component="th" scope="row">
                {u.email}
              </TableCell>
              <TableCell>{u.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SingleUserBox = (props) => {
  const { selectedUser } = props;
  const [state, setStateValues] = useState({
    newPass: "",
    newEmail: "",
    isLoadingVisible: false,
  });

  const setState = (obj) => {
    setStateValues({
      ...state,
      ...obj,
    });
  };

  const changePass = async () => {
    setState({ isLoadingVisible: true });
    const res = await adminChangesUserData({
      userId: selectedUser.uid,
      competitorNewPass: state.newPass,
    });
    alert(res);
    setState({ isLoadingVisible: false, newPass: "" });
  };

  const changeEmail = async () => {
    const newEmail = state.newEmail.trim();
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newEmail)) {
      alert("Geçerli bir e-posta adresi yazınız!");
    } else {
      setState({ isLoadingVisible: true });
      const res = await adminChangesUserData({
        userId: selectedUser.uid,
        newEmail,
      });
      alert(res);
      setState({ isLoadingVisible: false, newEmail: "" });
    }
  };

  const setEmailAsVerified = async () => {
    setState({ isLoadingVisible: true });
    const res = await verifyEmailManually(selectedUser.uid);
    alert(res);
    setState({ isLoadingVisible: false, newPass: "" });
  };

  let paidProducts = [];
  if (selectedUser.products) {
    Object.keys(selectedUser.products).forEach((productName) => {
      const isPaid = selectedUser.products[productName].is_paid;
      if (isPaid) paidProducts.push(productName);
    });
  }

  return (
    <div className="singleUserBox">
      {state.isLoadingVisible && <LoadingBlock />}
      <div style={{ marginBottom: 15, width: "100%" }}>
        --- SEÇİLEN KULLANICI ---
      </div>
      <div className="singleUserDataRow">{selectedUser.uid}</div>
      <div className="singleUserDataRow">
        {selectedUser.name || "Henüz Ad-Soyad Kaydedilmemiş!"}
      </div>
      <div className="singleUserDataRow">{selectedUser.email}</div>
      <div className="singleUserDataRow">
        <span className="lbl">Tel</span>
        {selectedUser.phone || ""}
      </div>
      <div className="singleUserDataRow">
        <span className="lbl">Doğum Yılı</span>
        {selectedUser.birth_year || ""}
      </div>
      {/* <div className="singleUserDataRow">
        <span className="lbl">Cinsiyet</span>
        {selectedUser.gender || ""}
      </div>
      <div className="singleUserDataRow">
        <span className="lbl">Eğitim</span>
        {selectedUser.education || ""}
      </div>
      <div className="singleUserDataRow">
        <span className="lbl">Meslek</span>
        {selectedUser.occupation || ""}
      </div>
      <div className="singleUserDataRow">
        <span className="lbl">Şehir</span>
        {selectedUser.city || ""}
      </div> */}
      {paidProducts.map((p) => (
        <div
          key={p + "users_productss"}
          style={{ display: "flex", alignItems: "center" }}
        >
          {p + " ödemesi: "}{" "}
          <CheckCircleOutlineIcon style={{ color: "green" }} />
        </div>
      ))}

      <div style={{ marginTop: 20, display: "flex" }}>
        <TextField
          id="newPass"
          label="Yeni Şifre"
          value={state.newPass}
          onChange={(e) => setState({ newPass: e.target.value })}
          autoComplete={"disabled"}
          style={{ width: 200, marginRight: 5 }}
          size="small"
        />
        {state.newPass && state.newPass.length > 5 && (
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            variant="contained"
            onClick={changePass}
          >
            Değiştir
          </Button>
        )}
      </div>

      <div style={{ marginTop: 20, display: "flex" }}>
        <TextField
          id="newEmail"
          label="Yeni Email"
          value={state.newEmail}
          onChange={(e) => setState({ newEmail: e.target.value })}
          autoComplete={"disabled"}
          style={{ width: 200, marginRight: 5 }}
          size="small"
        />
        {state.newEmail && state.newEmail.length > 5 && (
          <Button
            style={{ textTransform: "none" }}
            color="secondary"
            variant="contained"
            onClick={changeEmail}
          >
            Değiştir
          </Button>
        )}
      </div>

      <Button
        variant="contained"
        style={{ textTransform: "none", marginTop: 20 }}
        onClick={setEmailAsVerified}
      >
        Eposta Adresini Doğrula
      </Button>
    </div>
  );
};

const StatsTab = () => {
  const [userCountsDaily, setUserCountsDaily] = useState([]);
  const [latestCounts, setLatestCounts] = useState([0, 0]);

  useEffect(async () => {
    const stats = await readStats();
    const userCounts = stats.user_counts;
    setLatestCounts([
      userCounts[userCounts.length - 1].total_users,
      userCounts[userCounts.length - 1].total_payments,
    ]);

    const userCountsDaily = userCounts.map((u, i) => {
      if (i === 0) return u;

      const previous_u = userCounts[i - 1];
      return {
        date_and_time: u.date_and_time,
        total_users: u.total_users - previous_u.total_users,
        total_payments: u.total_payments - previous_u.total_payments,
      };
    });
    setUserCountsDaily(userCountsDaily);
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      // title: {
      //     display: true,
      //     text: 'Günlük Sayılar',
      // },
    },
  };

  const data2 = {
    labels: userCountsDaily.map((u) =>
      u.date_and_time.toDate().toLocaleDateString("tr-TR", {
        timeZone: "Europe/Istanbul",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    ),
    datasets: [
      {
        label: "Kayıt",
        data: userCountsDaily.map((u) => u.total_users),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Ödeme",
        data: userCountsDaily.map((u) => u.total_payments),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <div className="statsTab">
      <div>{"Kayıtlı kullanıcılar: " + latestCounts[0]}</div>
      <div>{"Ödeme yapanlar: " + latestCounts[1]}</div>
      <div>{"(Sayılar her gün saat 17:00'de güncellenmektedir.)"}</div>
      <Bar options={options} data={data2} />
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}
