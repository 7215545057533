import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";

export default function Year2018() {
  const studentData = [
    {
      title: "Minikler",
      rows: [
        ["1. Ayşe Umay Bahçivan", 67],
        ["2. Utku Kayra Sarıtaş", "50+11"],
        ["3. Nuri Deniz Durucu", 50],
        ["4. Poyraz Aksakal", 47],
        ["5. Duru Yıldırım", 44],
        ["6. Ömer Ufuk", 43],
        ["7. Elif Müge Arslantürk", "41+6"],
        ["8. Baha Oğuz", 41],
        ["8. Ömer Akhan", 41],
        ["8. Mustafa Efe Vural", 41],
      ],
    },
    {
      title: "Çocuklar",
      rows: [
        ["1. Arda Ege Erdoğan", 80],
        ["2. Ahmet Emir Kaya", 78],
        ["3. Habil Cabbar Sağlam", 73],
        ["4. Muhammed Anıl Gülaz", "68+13"],
        ["5. Ege Göknur", 68],
        ["6. Ege Yiğit Köksal", "66+6+7"],
        ["7. Safa Yalçın", "66+6"],
        ["7. Zafer Yalçın", "66+6"],
        ["9. İrem Gülce Yazgan", 65],
        ["10. Efe Özbal", 62],
      ],
    },
    {
      title: "Gençler",
      rows: [
        ["1. Ozan Kaymak", 95],
        ["2. Gürkan İnal", 87],
        ["3. Mehmet Tarık Doğanç", 82],
        ["4. Servet Hakan Altay", 81],
        ["5. Yusuf Katırcı", 78],
        ["5. Kerem Adem", 76],
        ["7. Vahit Alp Hıdıroğlu", 73],
        ["8. Hakan Karakuş", 68],
        ["9. İbrahim Suat Evren", "67+11"],
        ["10. Ahmet Ayberk Durak", 67],
      ],
    },
    {
      title: "Yetişkinler",
      rows: [
        ["1. Hatice Esra Aydemir", 86],
        ["2. Yılmaz Onat Köylüoğlu", 82],
        ["3. Taha Alp Kaymak", "78+5+20"],
        ["4. Baran Kirdar", "78+4+18"],
        ["5. Fatih Kamer Anda", "78+3+9"],
        ["6. Hasan Büyükadam", "77+5+4"],
        ["7. Türker Berk Dönmez", "77+4+14"],
        ["8. Kasım Kucur", "77+4+3"],
        ["9. Nejat Cingi", 77],
        ["10. Ezgi Kantarci Oguz", 75],
      ],
    },
    {
      title: "Emektarlar",
      rows: [
        ["1. Murat Koz", 100],
        ["2. Mustafa Basak", 75],
        ["3. Ergün Akgöz", 66],
        ["3. Kazim Pars", 56],
        ["5. Fulya Oral", 53],
        ["6. Ünal Adnan Güler", 53],
        ["6.Derya Uztürk", 51],
        ["6. Ismail Üşümezoğlu", 51],
        ["6.  Orhan Nişancı", 51],
        ["9. Ünal Adnan Güler", 50.0],
        ["9.Ali Ihsan Taş ", "47+4"],
        ["10.Hakan gur ", 47],
      ],
    },
  ];

  return (
    <div className="history2021">
      <div>
        <h3>Türkiye 23. Zeka Oyunları Yarışması</h3>
        <h1>
          <strong>OYUN 2018'in Birincileri Belli Oldu...</strong>
        </h1>
        <p>
          <strong>
            Yarışmada derece alanlar toplam 30.000 TL’lik ödülün sahibi oldu.
          </strong>
        </p>
        <div>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            Türkiye Zeka Vakfı tarafından bu yıl 23.sü düzenlenen “Türkiye Zeka
            Oyunları Yarışması <strong>OYUN 2018</strong>'nin final sınavı ve
            ödül töreni 13 Mayıs 2018 Pazar Günü Ankara Orta Doğu Teknik
            Üniversitesi’nde gerçekleştirildi.
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            <strong>
              Her ilden her yaştan toplam 38131 yarışmacı adayı...
            </strong>
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            <strong>Türkiye İş Bankası</strong> ana sponsorluğunda{" "}
            <strong>ODTÜ, TÜBİTAK ve MEB</strong> destekleriyle gerçekleştirilen
            Oyun 2018; Minikler, Çocuklar, Gençler, Yetişkinler ve Emekliler
            kategorilerinde üç aşamada yapıldı. Her ilden başvurunun olduğu
            yarışmaya 38268 kişi kayıt oldu. Online olan ilk iki aşamada yarışan
            6864 kişiden final sınavına katılmaya hak kazanan yarışmacı sayısı
            1294'tü.
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            Türkiye Zeka Oyunları Yarışması <strong>OYUN 2018</strong>'in yazılı
            olarak yapılan ve 90 dakika süren Final Sınavında yarışmacılara 2’si
            eşitlik bozmak üzere toplam 12 soru yöneltildi.
          </p>
          <p style={{ textAlign: "left" }}>
            <strong>
              Birbirinden zor soruları çözerek birinci olan yarışmacılar şöyle;
            </strong>
          </p>
          <p style={{ textAlign: "left" }}>
            <strong>Minikler’de</strong> 67 puanla{" "}
            <strong>Ayşe Umay Bahçivan,</strong> <br></br>
            <strong>Çocuklar’da</strong> 80 puanla 
            <strong>Arda Ege Erdoğan,</strong> <br></br>
            <strong>Gençler’de</strong> 95 puanla <strong>Ozan Kaymak,</strong> 
            <br></br>
            <strong>Yetişkinler’de</strong> 100 puan ve ek sorudan aldığı 21
            puanla <strong>Ufuk Demiray,</strong>
            <br></br>
            <strong>Emekliler’de</strong> 78 puanla <strong>Murat Koz,</strong> 
            <br></br>
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            Zeka Oyunları Yarışması’nın tüm soruları yurt içinde ve yurt dışında
            binlerce zeka sorusunun yazarı olan Türkiye Zeka Vakfı Başkanı
            Emrehan Halıcı tarafından hazırlanıyor.
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            <strong>
              Oyun 2018'in en zorlu aşaması olan Final Sınavı’nda, yarışmaya
              dair dikkat çeken istatistiklere de yer verildi;
            </strong>
          </p>
          <ul style={{ paddingTop: "20px", textAlign: "left" }}>
            <li>En küçük yarışmacı 6, en büyük yarışmacı 72 yaşında.</li>
            <li>
              En çok katılımın sağlandığı ilk 5 şehir; İstanbul, Ankara, İzmir,
              Antalya, Kocaeli.
            </li>
            <li>
              En çok katılım sağlayan meslek grupları sırasıyla; Öğrenci,
              Mühendis, Öğretmen, Bankacı ve Doktor.
            </li>
          </ul>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            <strong>
              OYUN 2018 kapsamında sınav sonrası renkli etkinlikler de yapıldı;
            </strong>
          </p>
          <div>
            <div>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2018%2Fseref-oguz.5d4d8fcb.jpg?alt=media&token=07ae1a5e-9969-44d2-9737-7871f0d0a0aa
"
                alt=""
                style={{ marginTop: "20px" }}
              />
            </div>
            <div>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2018%2Fziya-selcuk.ea121702.jpg?alt=media&token=e340afde-973f-4848-b657-20b1dfb5961e"
                alt=""
                style={{ marginTop: "20px" }}
              />
            </div>
          </div>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            Final sınavının yanı sıra davetli konuşmacılar ve çeşitli atölye
            çalışmalarıyla gün boyu süren etkinliklerde; TZV Yönetim Kurulu
            Üyesi, Yazar Dr. Şeref Oğuz{" "}
            <strong>&quot;Kolay Demedik, Mümkün Dedik&quot;</strong>; TZV
            Yönetim Kurulu Üyesi, Maya Okulları Kurucusu Prof. Dr. Ziya Selçuk{" "}
            <strong>&quot;Hepimiz Zekiyiz.&quot;</strong> başlığıyla konuşurken,
            Rubik Küp Eğitmeni Selçuk Soner Akgül{" "}
            <strong>&quot;Rubik Küp Gizemi&quot;</strong>, Nefes Kolaylaştırıcı
            Yeşim Yaşar <strong>&quot;Yaşam ve Nefes&quot;</strong>{" "}
            atölyelerinde katılımcılarla interaktif buluşmalar gerçekleştirdi.
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            Her yıl binlerce kişinin yarıştığı Türkiye Zeka Oyunları
            Yarışması’na; yaş, cinsiyet ve eğitim gibi sınırlamalar olmadan
            zekâsına güvenen herkes katılabiliyor. Amaç; insanların düşünme
            alışkanları ve yeteneklerini geliştirmeye katkıda bulunmak...
          </p>
          <p style={{ paddingTop: "20px", textAlign: "left" }}>
            Yarışmanın başarı sağlayan isimlerine dair diğer detaylar ise şöyle;
          </p>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {studentData.map((group) => {
              return <LeaderBoard key={group.title} data={group} />;
            })}
          </div>

          <div>
            <a
              href="
https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2018%2Foyun2018-finalcevaplar.pdf?alt=media&token=bd76f519-3a8b-40b9-8661-df41372a2924"
              target="_blank"
            >
              <h3 style={{ textAlign: "center" }}> FİNAL SINAVI CEVAPLARI</h3>
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2018%2Foyun2018-finalliste.pdf?alt=media&token=b33f72d6-7db0-487c-be0a-44b96acd3398"
              target="_blank"
              rel="noreferrer"
            >
              <h3 style={{ textAlign: "center" }}>FİNAL SINAVI PUAN LİSTESİ</h3>
            </a>
            <a
              href="http://oyundergisi.tzv.org.tr/oyun2018galeri/"
              target="_blank"
              rel="noreferrer"
            >
              <h3 style={{ textAlign: "center" }}>FİNAL SINAVI RESİMLERİ</h3>
            </a>

            <a
              href="http://oyundergisi.tzv.org.tr/img/2018/bulten/oyun2018final.html"
              target="_blank"
              rel="noreferrer"
            >
              <h3 style={{ textAlign: "center" }}>BÜLTEN</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
