import React, { useContext, useEffect, useState } from "react";
import HallOfFameCategoryBox from "./hallOfFameCategoryBox";
import HallOfFameUserModal from "./hallOfFameUserModal";
import {
  getTopRankHolders,
  generateHallOfFames,
  getHallOfFameUsers,
} from "../../db/firebaseMethods";
import AppContext from "../../AppContext";
import { Button } from "@mui/material";

const HallOfFame2023 = ({ selectedUser, onShowFull, onModalClose }) => {
  const season = "oyun2023";
  const [allUsers, setAllUsers] = useState([]);
  const { user } = useContext(AppContext);

  useEffect(() => {
    getHallOfFameUsers(season).then((users) => {
      const organizedUsers = [
        {
          title: "MİNİKLER",
          users: users.filter((u) => u.category === "Minikler"),
        },
        {
          title: "ÇOCUKLAR",
          users: users.filter((u) => u.category === "Çocuklar"),
        },
        {
          title: "GENÇLER",
          users: users.filter((u) => u.category === "Gençler"),
        },
        {
          title: "YETİŞKİNLER",
          users: users.filter((u) => u.category === "Yetişkinler"),
        },
        {
          title: "EMEKTARLAR",
          users: users.filter((u) => u.category === "Emektarlar"),
        },
      ];
      setAllUsers(organizedUsers);
    });
  }, []);
  const handleGenerateHallOfFames = async () => {
    try {
      await generateHallOfFames(season);
      alert("Şeref Kürsüsü Oluşturuldu!");
    } catch (error) {
      alert("Şeref Kürsüsü Oluşturulamadı!", error.message);
    }
  };

  const header = (
    <div
      className="header"
      style={{ background: "radial-gradient(#e1e1e1,#b4aad3)" }}
    >
      OYUN 2023 Şeref Kürsüsü
    </div>
  );
  return (
    <div className="hallOfFame">
      {header}
      {user && user.role === "admin" && (
        <div style={{ margin: 10 }}>
          <div style={{ marginBottom: 10 }}>
            Her dönem sonunda kullanıcıların dereceleri database girildikten
            sonra bu butonu kullanmalısınız!
          </div>
          <Button variant="contained" onClick={handleGenerateHallOfFames}>
            Şeref Kürsüsü Oluştur
          </Button>
        </div>
      )}

      {allUsers.map((category) => (
        <HallOfFameCategoryBox
          key={"category_box" + category.title}
          title={category.title}
          users={category.users}
          onShowFull={onShowFull}
        />
      ))}
      {selectedUser && (
        <HallOfFameUserModal
          selectedUser={selectedUser}
          onModalClose={onModalClose}
        />
      )}
    </div>
  );
};

export default HallOfFame2023;
