import "./paymentResultPage.css";
import AppContext from "../../AppContext";
import { useParams, Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { getPayment, saveUserData } from "../../db/firebaseMethods";

export default function PaymentResultPage() {
  const { setCentralState, user, refreshUserData } =
    React.useContext(AppContext);

  let [searchParams, setSearchParams] = useSearchParams();
  const [isControlsDone, setIsControlsDone] = useState(false);
  const params = useParams();

  if (!user) {
    return <div className="pageContainer">Litfen oturum açınız...</div>;
  } else return <CheckResultComponent />;
}

const CheckResultComponent = () => {
  const { refreshUserData, setCentralState, user } =
    React.useContext(AppContext);
  const [isControlsDone, setIsControlsDone] = useState(false);

  useEffect(() => {
    controlPayment();
  }, []);

  const controlPayment = async () => {
    const productId = "oyun2024"; //TODO: Bunu düzelt.
    const token = user?.products[productId]?.payment_token;
    if (token) {
      const pymnt = await getPayment(token);
      const isPaid = pymnt?.status === "successful";
      if (isPaid) {
        alert("Ödemeniz başarılı bir şekilde gerçekleştirilmiştir.");
        const newData = { products: { [productId]: { is_paid: true } } };
        await saveUserData(user.uid, newData);
        await refreshUserData();
      } else alert("Ödeme başarısız.");
      setIsControlsDone(true);
    } else {
      alert(
        "Ödeme kontrolü esnasında bir hata meydana geldi. (Hata Kodu: 9KRV217)"
      );
    }
  };

  if (isControlsDone) return <Navigate replace to="/profile" />;

  return (
    <div className="pageContainer">
      Bilgileriniz kontrol ediliyor. Lütfen bekleyiniz...
    </div>
  );
};
