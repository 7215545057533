import "./App.css";
import { Routes, Route, Link, BrowserRouter as Router } from "react-router-dom";
// import { CssBaseline } from "@material-ui/core";
import React, { Component } from "react";
import MainPage from "./pages/mainPage/mainPage";
import SignUpPage from "./pages/signupPage/signupPage";
import AppBar from "./components/organisms/appBar/appBar";
import { AppProvider } from "./AppContext";
import LoginPage from "./pages/loginPage/loginPage";
import ProfilePage from "./pages/profilePage/profilPage";
import NotFoundPage from "./pages/404/notFoundPage";
import PaymentResultPage from "./pages/paymentResultPage/paymentResultPage";
import AdminPage from "./pages/adminPage/adminPage";
import HistoryPage from "./pages/historyPage/historyPage";
import HallOfFamePage from "./pages/hallOfFamePage/hallOfFamePage";
import AboutPage from "./pages/aboutPage/aboutPage";
import Help from "./pages/help";
import Button from "@mui/material/Button";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { DocumentsPage } from "./pages/documents/documentsPage";

// import { FeedbackSnack } from "./components/organisms/feedbackTab/FeedbackSnack";

const headerLogos = (
  <div className="headerRow">
    <Link to="/">
      <img className="left-logo" src="/assets/images/main-logo2024.png" />
    </Link>
    <h1 className="header-text">Türkiye 29. Zeka Oyunları Yarışması</h1>

    <img style={{}} className="right-logo" src="/assets/images/29yil.png"></img>
  </div>
);

const advertisementBox = (
  <div
    style={{
      padding: "15px 15px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }}
    className="addBox"
  >
    <a href="https://akiloyunlari.griceviz.com/" target="blank">
      <img
        style={{ maxWidth: 700, width: "100%", marginTop: 10 }}
        src="/assets/images/zekaoyunlari-griceviz.png"
      />
    </a>
    {/* <a href="https://oyun.tzv.org.tr/" target="blank">
      <img style={{ marginTop: 10 }} src="/assets/images/TOSOS.png" />
    </a> */}
    {/* <a href="https://www.kongre.tzv.org.tr/2023/" target="blank">
      <img
        style={{ marginTop: 10, marginLeft: 10 }}
        src="/assets/images/kongre.png"
      />
    </a> */}
    <a href="https://market.tzv.org.tr/#/products/1065" target="blank">
      <img
        style={{ marginTop: 10, marginLeft: 10 }}
        src="/assets/images/yesbox-nedir.png"
      />
    </a>
  </div>
);

const mediaLink = (mediaIcon, url) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <Button
        style={{
          marginTop: 7,
          padding: 5,
          width: 50,
          height: 50,
          minWidth: "unset",
          backgroundColor: "#F2F2F2",
          borderRadius: 7,
          display: "flex",
          alignItems: "center",
        }}
        variant="contained"
      >
        {mediaIcon}
      </Button>
    </a>
  );
};

const footer = (
  <div
    style={{
      backgroundColor: "white",
      padding: 30,
      marginTop: 20,
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <div style={{ borderTop: "1px solid #cccccc", width: 400 }}>
      <div
        style={{
          margin: "-28px auto 0 auto",
          display: "table",
          padding: "0 10px",
          color: " #cccccc",
          backgroundColor: "white",
        }}
      >
        <p>düzenleyen</p>
      </div>
    </div>
    <a href="https://www.tzv.org.tr" target="_blank" rel="noreferrer">
      <img
        src="/assets/images/footer_logo.png"
        style={{
          marginTop: 16,
          maxWidth: 296,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
        }}
      />
    </a>

    <div
      style={{
        padding: 30,
        marginTop: 20,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ borderTop: "1px solid #cccccc", width: 400 }}>
        <div
          style={{
            margin: "-28px auto 0 auto",
            display: "table",
            padding: "0 10px",
            color: " #cccccc",
            backgroundColor: "white",
          }}
        >
          <p>destekleyen</p>
        </div>
      </div>
      <div>
        <a
          href="https://www.yamanturkvakfi.org.tr/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/images/yamanturk_vakfi.png"
            style={{ maxWidth: 500 }}
          />
        </a>
        <a href="https://www.posta.com.tr/" target="_blank" rel="noreferrer">
          <img
            src="/assets/images/posta-logo.png"
            style={{ maxWidth: 300, marginLeft: 20 }}
          />
        </a>
      </div>
      <div
        style={{ borderTop: "1px solid #cccccc", width: 400, marginTop: 30 }}
      >
        <div
          style={{
            margin: "-28px auto 0 auto",
            display: "table",
            padding: "0 10px",
            color: " #cccccc",
            backgroundColor: "white",
          }}
        >
          <p>sponsor</p>
        </div>
      </div>
      <a href="https://www.logo.com.tr/" target="_blank" rel="noreferrer">
        <img
          src="/assets/images/logo-yazilim.png"
          style={{
            marginTop: 10,
            maxWidth: 290,
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        />
      </a>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "4vh",
          }}
        >
          {mediaLink(
            <InstagramIcon
              style={{ color: "#DD3E79", fontSize: "calc(1rem + 0.4vw)" }}
            />,
            "https://www.instagram.com/zekavakfi"
          )}
          <div style={{ marginRight: "14px", marginLeft: "14px" }}>
            {mediaLink(
              <TwitterIcon
                style={{ color: "#2CA5DA", fontSize: "calc(1rem + 0.4vw)" }}
              />,
              "https://twitter.com/ZekaVakfi"
            )}
          </div>
          {mediaLink(
            <FacebookIcon
              style={{ color: "#2468AD", fontSize: "calc(1rem + 0.4vw)" }}
            />,
            "https://tr-tr.facebook.com/TRZekaVakfi"
          )}
        </div>
      </div>
    </div>
  </div>
);

class App extends Component {
  componentDidMount() {}
  render() {
    return (
      <AppProvider>
        <Router>
          <div className="App">
            {headerLogos}
            {<AppBar />}
            {/* <CssBaseline /> */}
            <div className="content">
              <Routes>
                <Route exact path="/" element={<MainPage />} />
                <Route exact path="signup" element={<SignUpPage />} />
                <Route exact path="login" element={<LoginPage />} />
                <Route exact path="profile" element={<ProfilePage />} />
                <Route
                  exact
                  path="payment_result"
                  element={<PaymentResultPage />}
                />
                <Route exact path="admin" element={<AdminPage />} />
                <Route exact path="history" element={<HistoryPage />} />
                <Route exact path="hall_of_fame" element={<HallOfFamePage />} />
                <Route exact path="about" element={<AboutPage />} />
                <Route exact path="help" element={<Help />} />
                <Route exact path="history" element={<HistoryPage />} />
                <Route exact path="documents" element={<DocumentsPage />} />

                {/* <Route
                  exact
                  path="reset-password"
                  element={<ResetPassword />}
                /> */}

                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>

            {/* <FeedbackSnack /> */}
            {advertisementBox}
            {footer}
          </div>
        </Router>
      </AppProvider>
    );
  }
}

export default App;
