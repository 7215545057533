import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./leaderBoard.css";

export default function LeaderBoard(props) {
  const data = props.data;
  return (
    <TableContainer component={Paper} className="scoreTableContainer">
      <div className="groupTitle">{data.title}</div>
      <img className="leaderBoardPhoto" src={data.photo} />
      <Table
        size="small"
        style={{ boxSizing: "border-box" }}
        aria-label="a dense table"
      >
        <TableBody>
          {data.rows.map((row) => {
            const name = row[0];
            const isFirstDegree = name.substring(0, 2) === "1.";
            return (
              <TableRow key={data.title + row[0] + row[1]}>
                <TableCell
                  component="th"
                  scope="row"
                  className={isFirstDegree ? "firstDegree" : ""}
                >
                  {row[0]}
                </TableCell>
                <TableCell style={{ padding: 0 }} align="right">
                  <div className="leaderBoardScore">{row[1]}</div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
