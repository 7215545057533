import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";

export default function Year2019() {
  const studentData = [
    {
      title: "Minikler",
      rows: [
        ["1. Eyüp Ensar Altuntaş", 52],
        ["2. Ömer Kerem Doman", 41.2],
        ["3. Noyan Önder49", 38.2],
        ["4. Hasan Buğra Kaya", 38 + 5],
        ["5. Bilginur Konut", 38],
        ["6. Kerem Ulaş", 30],
        ["7. Ecrin Seda Deniz", 29],
        ["8. Gülce Kaçar", 27],
        ["9. Mehmet Selim Bölgen", 25],
        ["10. Mehmet Rauf Atakan", 24],
      ],
    },
    {
      title: "Çocuklar",
      rows: [
        ["1. Doruk Açıkgöz", 100],
        ["2. Mehmet Durmaz", 95],
        ["3. Barış Nişancı", 94 + 7],
        ["4. Ömer Hasanov", 94 + 6],
        ["5. İlhan Pala89", 89 + 17],
        ["6. İhsan Baydar", 89 + 1],
        ["7. Oğuz Kağan Temirtaş", 86],
        ["8. Su Ece Boztepe", 82],
        ["9. Ahmet Utku Şahiner", 81],
        ["10. Berat Çinar Berktaş", 80],
      ],
    },
    {
      title: "Gençler",
      rows: [
        ["1. Hakan Karakuş", 86 + 19],
        ["2. Simay Pala", 86 + 14],
        ["3. Yusuf Katirci", 86 + 6],
        ["4. Servet Hakan Altay", 80],
        ["5. Vahit Alp Hıdıroğlu", 79],
        ["6. Pelin Tarhan", 77 + 6],
        ["7. Hüseyin Ege Olcay", 77 + 2],
        ["8. Kerem Adem", 75 + 25],
        ["9. Kaan Pide", 75 + 16],
        ["10. Gürkan Inal", 75 + 14],
      ],
    },
    {
      title: "Yetişkinler",
      rows: [
        ["1. Hatice Esra Aydemir", 86],
        ["2. Yılmaz Onat Köylüoğlu", 82],
        ["3. Taha Alp Kaymak", 78 + 5 + 20],
        ["4. Baran Kirdar", 78 + 4 + 18],
        ["5. Fatih Kamer Anda", 78 + 3 + 9],
        ["6. Hasan Büyükadam", 77 + 5 + 4],
        ["7. Türker Berk Dönmez", 77 + 4 + 14],
        ["8. Kasım Kucur", 77 + 4 + 3],
        ["9. Nejat Cingi", 77],
        ["10. Ezgi Kantarci Oguz", 75],
      ],
    },
    {
      title: "Emektarlar",
      rows: [
        ["1. Murat Koz", 100],
        ["2. Mustafa Basak", 75],
        ["3. Ergün Akgöz", 66],
        ["3. Kazim Pars", 56],
        ["5. Fulya Oral", 53],
        ["6. Ünal Adnan Güler", 53],
        ["6.Derya Uztürk", 51],
        ["6. Ismail Üşümezoğlu", 51],
        ["6.  Orhan Nişancı", 51],
        ["9. Ünal Adnan Güler", 50.0],
        ["9.Ali Ihsan Taş ", 47 + 4],
        ["10.Hakan gur ", 47],
      ],
    },
  ];

  return (
    <div className="history2021">
      <div>
        <h3>Türkiye 24. Zeka Oyunları Yarışması</h3>

        <p>
          <strong>
            OYUN 2019’un Ödül Töreni Milli Eğitim Bakanlığı’nda Yapıldı.
          </strong>
        </p>
        <p>
          <strong>
            Sonuçların ilanı, pandemi koşulları nedeniyle sanal törenle
            gerçekleştirildi.
          </strong>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye Zeka Vakfı tarafından bu yıl 24.sü düzenlenen Türkiye Zeka
          Oyunları Yarışması <strong>OYUN 2019</strong>’un final sınavı 21 Nisan
          2019 Pazar Günü Orta Doğu Teknik Üniversitesi Ankara’da
          gerçekleştirildi.
        </p>

        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/8HO7k652A-g"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>

        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>Türk Hava Yolları, Türkiye İş Bankası ve GriCeviz</strong> ana
          sponsorluklarında <strong>ODTÜ, TÜBİTAK ve MEB</strong> destekleriyle
          gerçekleştirilen Oyun 2019; Minikler, Çocuklar, Gençler, Yetişkinler
          ve Emektarlar kategorilerinde üç aşamada yapıldı. Her ilden başvurunun
          olduğu yarışmaya 56303 kişi kayıt oldu. Online olan ilk iki aşamada
          yarışan 7784 kişiden final sınavına katılmaya hak kazanan yarışmacı
          sayısı 1577&apos;ydi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye Zeka Oyunları Yarışması <strong>OYUN 2019</strong>&apos;un
          yazılı olarak yapılan ve 90 dakika süren Final Sınavında yarışmacılara
          2’si eşitlik bozmak üzere toplam 12 soru yöneltildi.
        </p>

        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>
            Birbirinden zor soruları çözerek birinci olan yarışmacılar şöyle;
          </strong>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>Minikler’de</strong> 52 puanla
          <strong>Eyüp Ensar Altuntaş,</strong> <br></br>
          <strong>Çocuklar’da</strong> 100 puanla
          <strong>Doruk Açıkgöz,</strong> <br></br>
          <strong>Gençler’de</strong> 86 puan ve ek sorulardan aldığı 19 puanla
          <strong>Hakan Karakuş,</strong> <br></br>
          <strong>Yetişkinler’de</strong> 86 puanla
          <strong>Hatice Esra Aydemir,</strong>
          <br></br>
          <strong>Emektarlar&apos;da</strong> 100 puanla
          <strong>Murat Koz,</strong> <br></br>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye Zeka Oyunları Yarışması’nın tüm soruları yurt içinde ve yurt
          dışında binlerce zeka sorusunun yazarı olan Türkiye Zeka Vakfı Başkanı
          Emrehan Halıcı tarafından hazırlanıyor.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>
            Oyun 2019&apos;un en zorlu aşaması olan Final Sınavı’nda, yarışmaya
            dair dikkat çeken istatistiklere de yer verildi;
          </strong>
        </p>
        <ul style={{ textAlign: "left" }}>
          <li>En küçük yarışmacı 5, en büyük yarışmacı 78 yaşında.</li>
          <li>
            En çok katılımın sağlandığı ilk 5 şehir; İstanbul, Ankara, İzmir,
            Antalya, Kocaeli.
          </li>
          <li>
            En çok katılım sağlayan meslek grupları sırasıyla; Öğrenci,
            Matematik Öğretmeni, Elektrik-Elektronik, Makina ve Bilgisayar
            Mühendisleri.
          </li>
        </ul>

        <img
          src="http://oyundergisi.tzv.org.tr/img/2019/ziyaselcuk.png"
          alt="Milli Eğitim Bakanı Sn. Ziya Selçuk"
          style={{ marginTop: "20px" }}
        />
      </div>

      <img
        src="http://oyundergisi.tzv.org.tr/img/2019/emrehanhalici.jpg"
        alt=""
        style={{ marginTop: "20px" }}
      />

      <div>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>
            Çocuklarımızın ilgilerini, yeteneklerini, zekalarını mümkün
            olduğunca erken fark edelim, anlayalım. Onların soru sormalarını,
            hayal kurmalarını destekleyelim…
          </strong>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Halıcı, 2023 Eğitim Vizyon Belgesi’nin umut verdiğine değinerek,
          Cumhuriyetimizin Kurucusu Mustafa Kemal Atatürk’ün göstermiş olduğu
          çağdaş uygarlık düzeyini yakalama ve geçme hedefini çocuklarımızın
          gerçekleştirebilmesi için ailelere, okullara, devletimize ortak
          görevler düştüğünü söyledi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Çocuklarımıza düşünmeyi, öğrenmeyi, üretmeyi, paylaşmayı, uzlaşmayı,
          sevmeyi öğretelim diyen Halıcı, onların sadece bilgi sahibi değil aynı
          zamanda akıl sahibi ve vicdan sahibi olmalarını hedeflersek geleceğe
          ümitle bakabiliriz, dedi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>
            Milli Eğitim Bakanı Sayın Prof. Dr. Ziya Selçuk konuşmasında bu tür
            çalışmaları uzun yıllardır takip ettiğini ve tüm toplumda bu
            çalışmaların yaygınlaştırılması gerektiğini belirtti.
          </strong>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Prof. Dr. Ziya Selçuk, Milli Eğitim Bakanlığı olarak, zekânın ve
          yeteneğin tespit edilmesi, bu konularla ilgili araç gereçlerin
          geliştirilmesinde ilerlemeler olduğunu söyledi. Selçuk, ilgili eğitim
          merkezlerinin oluşturulması ve öğretmen eğitimlerinin
          yaygınlaştırılması gibi hazırlıklarının olduğunu ifade etti. Bakan
          Selçuk yarışmada derece alan isimlere ödüllerini Türkiye Zeka Vakfı
          Başkanı Emrehan Halıcı ile beraber verdi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Her yıl binlerce kişinin yarıştığı Türkiye Zeka Oyunları Yarışması’na;
          yaş, cinsiyet ve eğitim gibi sınırlamalar olmadan zekâsına güvenen
          herkes katılabiliyor. Amaç; insanların düşünme alışkanları ve
          yeteneklerini geliştirmeye katkıda bulunmak...
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>Her kategoride dereceye giren ilk 10 yarışmacı;</strong>
        </p>
      </div>

      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {studentData.map((group) => {
          return <LeaderBoard key={group.title} data={group} />;
        })}
      </div>

      <a
        href="http://oyundergisi.tzv.org.tr/img/2019/oyun2019-finalpuan.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAVI PUAN LİSTESİ
      </a>

      <br></br>

      <a
        href="http://oyundergisi.tzv.org.tr/oyun2019galeri/"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAVI RESİMLERİ
      </a>
      <br></br>

      <a
        href="http://oyundergisi.tzv.org.tr/img/2019/oyun2019-cevapanahtari.pdf"
        target="_blank"
        rel="noreferrer"
      >
        FİNAL SINAVI CEVAPLARI
      </a>
      <br></br>
      <a
        href="http://oyundergisi.tzv.org.tr/img/2019/oyun2019final.html"
        target="_blank"
        rel="noreferrer"
      >
        BÜLTEN
      </a>
      <br></br>
      <a
        href="http://oyundergisi.tzv.org.tr/oyun19_eleme_ortak.pdf"
        target="_blank"
        rel="noreferrer"
      >
        ELEME SINAVI ORTAK SORULARI
      </a>
    </div>
  );
}
