import React, { Component } from "react";
import {
  db,
  fbLogout,
  fetchActiveProduct,
  listenEmailVerificationStatus,
  listenUserAuthState,
  pullUserData,
} from "./db/firebaseMethods";
import { doc, onSnapshot } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

// import {  listenAuthenticatedUserChanges, pullUsers, pullReportsOfWriter, pullAllReports } from "./firestoreMethods";
// import {
//   pullQuestions,
//   pullAnswers,
//   listenAuthenticatedUserChanges,
// } from "./db/FirebaseMethods";
// import { getServerDate } from "./db/FirebaseMethods";
// import { convertStringToDate, convertDateToString } from "./HelperFunctions";

const AppContext = React.createContext();

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      activeProduct: null,
      isLoggedInByOriginalPass: false,
    };
  }

  componentDidMount() {
    listenUserAuthState(this.userSetter);
    fetchActiveProduct().then((res) => this.setState({ activeProduct: res }));
  }

  componentDidUpdate(prevProps, prevState) {
    let unsub;
    if (
      this.state.user !== prevState.user &&
      this.state.user !== null &&
      this.state.isLoggedInByOriginalPass
    ) {
      unsub = onSnapshot(doc(db, "users", this.state.user?.uid), (doc) => {
        if (
          this.state.user?.stsTokenManager?.refreshToken.slice(-10) !==
            doc.data()?.token_for_concurrent_logins &&
          doc.data()?.token_for_concurrent_logins !== 0
        ) {
          const auth = getAuth();
          signOut(auth);
        }
      });
    }
    if (!this.state.user && typeof unsub === "function") {
      unsub();
    }
  }
  userSetter = async (u) => {
    if (this.state.user === null && u !== null) {
      const data = await pullUserData(u.uid);

      u = { ...u, ...data };

      // if(!u.emailVerified){// Login olduk ama email verified değilmiş, bunu da dinlemeye başlayalım, verify olduğu anda gerekli güncellemeyi yapalım:
      //   listenEmailVerificationStatus(async(verifiedUser)=>{
      //     console.log("id token sonrası setatate çalışacak: ", verifiedUser);
      //     this.setState({user: {...this.state.user, ...verifiedUser}});
      //   })
      // }
    }
    this.setState({ user: u });
    if (u?.role === "developer") {
      fetchActiveProduct("developer").then((res) =>
        this.setState({ activeProduct: res })
      );
    }
  };

  isLoggedInByOriginalPassFunc = (bool) => {
    this.setState({ isLoggedInByOriginalPass: bool });
  };

  refreshUserData = async () => {
    const userId = this.state.user?.uid;
    if (userId) {
      const newData = await pullUserData(userId);
      const newUser = { ...this.state.user, ...newData };
      this.setState({ user: newUser });
    }
  };

  setCentralState = (obj) => {
    this.setState(obj);
  };

  render() {
    const {
      setCentralState,
      refreshUserData,
      userSetter,
      isLoggedInByOriginalPassFunc,
    } = this;

    return (
      <AppContext.Provider
        value={{
          ...this.state,
          setCentralState,
          refreshUserData,
          userSetter,
          isLoggedInByOriginalPassFunc,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppContext;

export { AppProvider };
