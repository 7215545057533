import { Button } from "@mui/material";

export default function Year2023() {
  return (
    <div className="history2021">
      <div>
        <h1>Türkiye 28. Zeka Oyunları Yarışması</h1>
        <h2>
          <strong>OYUN 2023’ün Sonuçları Açıklandı!</strong>
        </h2>
      </div>

      <div>
        <img
          style={{ maxWidth: 800, width: "100%" }}
          src="/assets/images/oyun-2023-final.jpg"
        />
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye 28. Zeka Oyunları Yarışması “Oyun 2023” final sınavı ODTÜ'de
          gerçekleşti.
        </h3>
        <p style={{ textAlign: "left" }}>
          Türkiye Zeka Vakfı tarafından düzenlenen ve her sene heyecanla
          beklenen Zeka Oyunları Yarışması, bu sene yirmi sekizinci kez
          gerçekleşti. OYUN 2023 Eleme ve Yarı Final Sınavlarını geçerek Final
          Sınavı’na katılmaya hak kazanan her yaştan yarışmacı, 81 ilden gelerek
          ODTÜ’de buluştu.
        </p>
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>
          Okuma-yazma ve dört işlem becerileri ile geçen bir cumartesi
        </h3>
        <p style={{ textAlign: "left" }}>
          OYUN’a 28 senedir katılan yarışmacılar için “Cumartesi’, keyifli zaman
          geçirmenin ötesine de geçebilmek demek. Okuma-yazma ve dört işlem
          becerilerine sahip olan herkes için OYUN, sınırlarını zorlamak,
          problem çözme becerilerini geliştirmek, belirli süre içerisinde farklı
          bakış açıları üretebilmek demek. Minikler, Çocuklar, Gençler,
          Yetişkinler ve Emektarlar olarak 5 kategoriden oluşan OYUN 2023’e
          yakından bakalım.
        </p>
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>
          Yediden yetmişe değil, beşten yetmiş sekize
        </h3>
        <p style={{ textAlign: "left" }}>
          Her sene olduğu gibi bu sene de sorular, Türkiye Zeka Vakfı Başkanı
          Emrehan Halıcı tarafından hazırlanan OYUN 2023’ün en genci 5, en
          kıdemlisi 78 yaşında olan katılımcıları, kendi yaş gruplarına göre
          hazırlanmış özgün soruları çözerken sonuçtan daha çok sürecin önemli
          olduğunu deneyimledi. Emrehan Halıcı’nın 2021 yılında “Dünyanın En
          Uzun Süre Zeka Soruları Hazırlayan Köşe Yazarı” olarak Guinness
          Rekorlar Kitabı’na girdiğini biliyor muydunuz?
        </p>
        <p style={{ textAlign: "left" }}>
          Türkiye’nin 81 ilinden gelen yarışmacıları ODTÜ’de buluşturan OYUN
          2023’e en çok katılım sırasıyla{" "}
          <b> İstanbul, Ankara, İzmir, Antalya ve Denizli</b> illerinden
          sağlandı. Geçtiğimiz sene ödül töreni için ODTÜ’ye gelen gençlerden
          bazılarının, takip eden üniversite sınavında ODTÜ’yü tercih ettiğini
          biliyor muydunuz?
        </p>
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>
          Heyecanlı bekleyiş sürerken
        </h3>
        <p style={{ textAlign: "left" }}>
          Final Sınavı tamamlandıktan sonraki süreç eğitim alanında farklı
          uzmanlıkları bulunan üç konuşmacının bilgilendirici sunumları ile
          devam etti. Zeki Çatal, “Bulmacadan Matematiğe Matematikten
          Bulmacaya”, Prof. Dr. Feyza Doyran “Yaşam Becerileri ve Etkili Zaman
          Yönetimi” ve Prof. Dr. Hakan Tüzün “Zekâ ve Yetenek Üzerine Konuşma”
          başlıklı sunumları ile katılımcıların heyecanlı bekleyişini verimli,
          bilgilendirici ve aynı zamanda da keyifli bir sürece dönüştürdü.
        </p>
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>Ödül töreni</h3>
        <p style={{ textAlign: "left" }}>
          Ödül töreninde Vakıf Başkanı Emrehan Halıcı ve yarışma sponsorlarından
          LOGO Yazılım CTO’su İsmail Duran konuşmalarını yaptı. Bu konuşmalarda
          yirmi sekiz yıldır heyecanla, emekle, özenle gerçekleştirilen OYUN’un
          önemi vurgulandı, tüm finalistler tebrik edildi.
        </p>
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>
          Dereceye giren yarışmacılar
        </h3>
        <p style={{ textAlign: "left" }}>
          5 kategoride dereceye giren yarışmacıların fotoğrafları ve kısa
          özgeçmişleri şeref kürsüsü sayfasında yayınlanmıştır.
        </p>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Button
          color="secondary"
          style={{
            marginLeft: 3,
            marginRight: 3,
            marginBottom: 6,
          }}
          variant="contained"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://halici.pixieset.com/oyun2023/"
            style={{ color: "white", textDecoration: "none" }}
          >
            Fotoğraf Galerisi
          </a>
        </Button>
        <Button
          color="secondary"
          style={{
            marginLeft: 3,
            marginRight: 3,
            marginBottom: 6,
          }}
          variant="contained"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://oyun.tzv.org.tr/hall_of_fame"
            style={{ color: "white", textDecoration: "none" }}
          >
            Dereceye Giren Yarışmacılar
          </a>
        </Button>
      </div>
    </div>
  );
}
