import React from "react";
import "./help.css";
import { AccordionSummary } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const helpItems = [
  {
    helpTitle: "1:Yarışma kaç aşamadan oluşuyor?",
    helpText:
      "Yarışma 3 aşamadan oluşuyor. Eleme Sınavı, Yarı Final Sınavı, Final Sınavı",
  },
  {
    helpTitle: "2: Yarışma nerede olacak?",
    helpText:
      "Yarışmanın Eleme ve Yarı Final Sınavları oyun.tzv.org.tr web sayfası üzerinden çevrimiçi olarak, Final Sınavı Ankara'da yazılı olarak yapılacaktır.",
  },

  {
    helpTitle: "3: Yarışma kaç kategoride yapılıyor?",
    helpText: (
      <ul className="list-items">
        <li>
          <strong>MİNİKLER</strong> 6-7-8 yaş (2017, 2016 ve 2015 yıllarında
          doğanlar. Okuma, yazma ve matematikte dört işlem becerisine sahip
          olmak gereklidir.)
        </li>
        <li>
          <strong>ÇOCUKLAR</strong> 9-10-11-12-13 yaş (2014, 2013, 2012, 2011,
          2010 yıllarında doğanlar.)
        </li>
        <li>
          <strong>GENÇLER</strong> 14-15-16-17-18 yaş (2009, 2008, 2007, 2006,
          2005 yıllarında doğanlar.)
        </li>
        <li>
          <strong>YETİŞKİNLER</strong> 19-49 yaş arası (2004, …,1974 yıllarında
          doğanlar.)
        </li>
        <li>
          <strong>EMEKTARLAR</strong> 50 yaş ve üstü (1973 ve önceki yıllarda
          doğanlar.)
        </li>
      </ul>
    ),
  },
  {
    helpTitle: "4: Yarışmaya kimler katılabilir?",
    helpText:
      "Yarışmaya dileyen herkes katılabilir. MİNİKLER kategorisinde okuma, yazma ve matematikte dört işlem becerisine sahip olmak gereklidir.",
  },
  {
    helpTitle: "5: Yarışmaya nasıl kayıt olabilirim?",
    helpText: "oyun.tzv.org.tr girerek kaydol butonuna tıklamalısınız",
  },
  {
    helpTitle: "6: Yarışma ücretli midir?",
    helpText:
      "Yarışmaya kayıt ücreti 100 TL’dir. Bu ücret Eleme Sınavı’na girişte ödenir; bunun dışında başka bir ödeme yoktur.",
  },

  {
    helpTitle: "7: Yarışma ücretini nasıl yatıracağım?",
    helpText:
      "Yarışma sayfasına kayıt olduktan sonra kredi kartı veya banka kartı ile işlemi gerçekleştirebilirsiniz. Kredi/banka kartı ile ödeme yapabilmeniz için kartınızın internetten alışverişe (mail-order) açık olması gerekmektedir. Aldığınız hatalar için öncelikle bankanızla iletişime geçmelisiniz.",
  },
  {
    helpTitle: "8: Sınav sonunda cevaplar yayınlanacak mı?",
    helpText:
      "Cevaplar yayınlanır, yarışmacılar kontrollerini ve varsa itirazlarını yapabilirler Soruların çözümleri yayınlanmaz.",
  },
  {
    helpTitle:
      "9: Eleme Sınavına girmeyen birisi yarı final sınavına katılabilir mi?",
    helpText:
      "Hayır. Yarışma üç aşamalı olduğundan bir önceki aşamada gerekli puanı alamayan yarışmacılar sonraki aşamada yer alamazlar.",
  },
  {
    helpTitle:
      "10: Yarışmaya katılan ancak herhangi bir sebeple cevapları giremeyen kişiler için telafi sınavı yapılacak mı?",
    helpText:
      "Hayır. Yarışma tüm yarışmacılar için aynı gün ve saatte başlayacak ve bitecektir. Cevaplarını girmemiş yarışmacılar için bir telafi söz konusu değildir.",
  },
  {
    helpTitle:
      "11: Bir e-postayla birden fazla kişinin kayıt işlemini sağlayabilir miyim?",
    helpText:
      "Hayır. Her bir katılımcı için ayrı bir e-posta adresi gereklidir.",
  },
  {
    helpTitle: "12: Okul veya kurum olarak katılım sağlanabilir mi?",
    helpText:
      "Okul ya da kurum olarak toplu kayıt sağlamak için info@tzv.org.tr üzerinden iletişime geçebilirsiniz.",
  },
];

export default function Help() {
  return (
    <div className="helpPage">
      <h2 className="faq">Sık Sorulan Sorular</h2>
      {helpItems.map((i) => {
        return (
          <Accordion key={i.helpTitle}>
            <AccordionSummary className="accordionEdit">
              {i.helpTitle}
            </AccordionSummary>
            <AccordionDetails style={{ fontSize: "0.9rem" }}>
              {i.helpText}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <div className="editContent">
        <h2>İletişim Bilgilerimiz</h2>
      </div>
      <p>Her türlü sorun, öneri ve şikayetleriniz için bize ulaşabilirsiniz.</p>
      <h4 style={{ display: "flex", alignItems: "center" }}>
        <LocationOnIcon size="0.8rem" />
        ADRES
      </h4>
      <p>
        ODTÜ-Halıcı Yazılımevi <br></br>
        İnönü Bulvarı 06531 <br></br>
        ODTÜ-Teknokent / ANKARA
      </p>
      <h4 style={{ display: "flex", alignItems: "center" }}>
        <EmailIcon style={{ marginRight: 7 }} />
        E-POSTA
      </h4>
      <a href="mailto:info@tzv.org.tr?Subject:Oyun" className="editContent">
        info@tzv.org.tr
      </a>
    </div>
  );
}
