import { Button } from "@mui/material";
import React, { useState } from "react";
import "./historyPage.css";
import Year2023 from "./year2023";
import Year2022 from "./year2022";
import Year2021 from "./year2021";
import Year2020 from "./year2020";
import Year2019 from "./year2019";
import Year2018 from "./year2018";
import Year2017 from "./year2017";
import Year2016 from "./year2016";
import Year2015 from "./year2015";

const years = [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015];
const pages = {
  2023: <Year2023 />,
  2022: <Year2022 />,
  2021: <Year2021 />,
  2020: <Year2020 />,
  2019: <Year2019 />,
  2018: <Year2018 />,
  2017: <Year2017 />,
  2016: <Year2016 />,
  2015: <Year2015 />,
};

const generateYearsButtons = (selectYear) => {
  return (
    <div className="yearSelectionBox">
      {years.map((y) => (
        <Button
          color="secondary"
          style={{ marginLeft: 3, marginRight: 3, marginBottom: 6 }}
          variant="outlined"
          key={"year_button" + y}
          onClick={() => selectYear(y)}
        >
          {"Oyun " + y}
        </Button>
      ))}
    </div>
  );
};

export default function HistoryPage() {
  const [state, setStateValues] = useState({
    selectedYear: 2022,
  });

  const setState = (obj) => {
    setStateValues({
      ...state,
      ...obj,
    });
  };
  return (
    <div>
      {generateYearsButtons((y) => setState({ selectedYear: y }))}
      {pages[state.selectedYear]}
    </div>
  );
}
