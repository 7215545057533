import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";
export default function Year2015() {
  const studentData = [
    {
      title: "14 Yaş Altı",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2015%2Fa1.3998c91d.jpg?alt=media&token=ab9b434f-1845-4f5d-beff-0d5e7a9bf61b",
      rows: [
        ["1. Baran YELALDI", 167],
        ["2. Baran Boloğur", 140],
        ["3. Kerem Adem", 132],
        ["4. Yusuf Kağan Çiçekdağ", 118],
        ["5. Alp Aytemur", 104],
        ["6. Hakan Karakuş", 104],
        ["7. Sadık Çağrı Aksoy", 103],
        ["8. Cemal Efe", 97],
        ["9. Kerem Üçok", 93],
        ["10. Batuhan Kuleci", 93],
      ],
    },
    {
      title: "14-21 Yaş Arası",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2015%2Fb1.0e50c120.jpg?alt=media&token=c8f2a8ed-e653-4379-bb8b-1575e6649f3f",
      rows: [
        ["1. Hatice E. AYDEMIR", 165],
        ["2. Ufuk Demiray", 165],
        ["3. Enes Suyabatmaz", 165],
        ["4. Umut Yalçınkaya", 156],
        ["5. Buğra Doğan", 148],
        ["6. Nadir Yurdaay", 146],
        ["7. Metin Azmaz", 137],
        ["8. Baran Kırdar", 137],
        ["9. Cihat Keçeci", 137],
        ["10. Mehmet Eren Durlanık", 132],
      ],
    },

    {
      title: "21 Yaş Üstü",
      photo:
        "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2015%2Fc1.799ace10.jpg?alt=media&token=f20f7ebb-e353-4245-8a8e-de57143cf042",
      rows: [
        ["1. Murat KOZ", 190],
        ["2. Selçuk Saraç", 165],
        ["3. Kerem Eyice", 164],
        ["4.Barış Aygören", 164],
        ["5. Emre Öztürk", 148],
        ["6.Yusuf Sertan Çabuk", 147],
        ["7. Emre Can Tansu", 146],
        ["8. Coşar Erünlü", 145],
        ["9. Fatih Kamer Anda", 145],
        ["10. Salih Alan", 145],
      ],
    },
  ];

  return (
    <div>
      <h3>Türkiye 20.Zeka Oyunları Yarışması</h3>
      <h1 style={{ color: "#603083" }}>
        <strong>OYUN 2015’in Birincileri Belli Oldu...</strong>
      </h1>
      <p style={{ textAlign: "center" }}>
        Kendi kategorilerinde en yüksek puanı alan üç yarışmacı toplam{" "}
        <strong>15.000 TL</strong> ödülün sahibi oldu.
      </p>

      <div style={{ display: "flex", flexWrap: "nowrap" }}>
        {studentData.map((group) => {
          return <LeaderBoard key={group.title} data={group} />;
        })}
      </div>

      <p style={{ textAlign: "left" }}>
        <strong>Türkiye Zeka Vakfı</strong>&nbsp;tarafından&nbsp;
        <strong>20 yıldır</strong>&nbsp;düzenlenen,
        <strong>&nbsp;İş Bankası</strong>&rsquo;nın sponsorluğunda{" "}
        <strong>ODTÜ</strong>, <strong>TÜBİTAK</strong> ve{" "}
        <strong>Milli Eğitim Bakanlığı</strong> destekleriyle
        gerçekleştirilen&nbsp;Türkiye Zeka Oyunları Yarışması OYUN 2015&rsquo;in
        final sınavı hafta sonu (19 Aralık 2015, Cumartesi) ODTÜ İşletme
        Bölümünde gerçekleştirildi.{" "}
      </p>
      <p style={{ textAlign: "left" }}>
        Geçtiğimiz aylarda ilk aşama olan Eleme Sınavı ve elektronik
        gerçekleştirilen Yarı Final sınavını başarıyla geçen{" "}
        <strong>413</strong> yarışmacı en zorlu sınavda; final aşamasında
        yarışmaya hak kazandı. Yarışmacılara 11 soru yöneltildi. Zeka Oyunları
        Yarışması&rsquo;nın tüm soruları Türkiye Zeka Vakfı Başkanı{" "}
        <strong>Emrehan Halıcı</strong> tarafından hazırlanıyor.{" "}
      </p>
      <p style={{ textAlign: "left" }}>
        <strong>
          Birinciler günlük yaşamlarında da zeka oyunlarına ilgili…
        </strong>
      </p>
      <p style={{ textAlign: "left" }}>
        <strong>14 yaş altı</strong>&nbsp;kategoride&nbsp;167&nbsp;puanla{" "}
        <strong>Baran Yeladı</strong>, <strong>14-21&nbsp;yaş</strong>{" "}
        kategorisinde&nbsp;165&nbsp;puanla&nbsp;
        <strong>Hatice Esra Aydemir</strong>,&nbsp;<strong>21 yaş üstü</strong>
        &nbsp;kategorisinde&nbsp;190&nbsp;puanla&nbsp;<strong>Murat Koz</strong>
        &nbsp;birinci oldular.
        <br />
        <br />
        <strong>&ldquo;En küçük birinci&rdquo;</strong> unvanının sahibi Baran
        Yeladı <strong>14</strong> yaşında, puzzle çözmenin yanı sıra satranç ve
        matematiğe de ilgi duyuyor.{" "}
      </p>
      <p style={{ textAlign: "left" }}>
        <strong>
          Yarışmanın 20 yıllık tarihinde ikinci kez bir bayan yarışmacı birinci
          oluyor…{" "}
        </strong>
      </p>
      <p style={{ textAlign: "left" }}>
        Bundan önce iki kez daha Türkiye Zeka Vakfı&rsquo;nın düzenlediği Zeka
        Oyunları Yarışmalarında final heyecanını yaşayan Hatice Esra Aydemir bu
        yıl en iyilerin arasına girmeyi başardı. Boğaziçi Üniversitesi
        Bilgisayar Mühendisliği Bölümü 2.sınıf öğrencisi olan Aydemir,
        genellikle erkeklerin en başarılı olduğu yarışmada öne geçti ve Zeka
        Oyunları Yarışmalarında yıllar sonra birinci olan ikinci bayan yarışmacı
        unvanının sahibi oldu.
      </p>
      <p style={{ textAlign: "left" }}>
        <strong>
          20 yıldır aralıksız her yıl yarışmaya katılan Murat Koz bu yıl son
          noktayı koydu, birinci oldu.
        </strong>
        <br />
        <br />
        İlk Zeka Oyunları Yarışmasından beri tam 20 kez Zeka Oyunları
        Yarışmalarının finallerine kalmayı başaran Koz, bu yıl 21 yaş üstü
        kategoride birinciliği kazandı. Özel bir şirkette yazılım uzmanı olan
        Murat Koz, bulmacalarla uzun yıllardır ilgileniyor.
        <br />
        <br />
        Birincilere <strong>5&rsquo;er bin TL</strong>, ilk 10&rsquo;a
        birbirinden renkli hediyeler…
        <br />
        <br />
        En zorlu aşama olan Final Sınavı&rsquo;nda ilk 10&rsquo;a giren
        yarışmacılara şilt ve Başarı Belgelerinin yanı sıra kitap hediyeleri
        verildi. Kategori birincileri ise büyük ödül olan 5&rsquo;er bin
        TL&rsquo;lik ödüllerini İş Bankası Kurumsal İletişim Bölümü Müdür
        Yardımcısı Kıvılcım Eraydın ile Türkiye Zeka Vakfı Başkanı Emrehan
        Halıcı&rsquo;dan aldılar…
        <br />
        <br />
        Yarışmacıların mantık yürütme ve problem çözme becerilerini sınayan
        Türkiye Zeka Oyunları Yarışması OYUN 2015&rsquo;in final sınavında
        sayısal ve sözel alandan yazılı 11 soru yöneltildi. Yarışmacılara 11
        soru için verilen süre 100 dakikaydı. Her yıl binlerce kişinin yarıştığı
        Türkiye Zeka Oyunları Yarışması&rsquo;na; yaş, cinsiyet ve eğitim gibi
        sınırlamalar olmadan zekâsına güvenen herkes katılabiliyor. Amaç;
        insanların düşünme alışkanları ve yeteneklerini geliştirmeye katkıda
        bulunmak...
      </p>

      <div>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2015%2FOYUN2015-FinalCevaplar.pdf?alt=media&token=9aec3414-0ca3-4d90-9d09-2428b13d1948"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>Final Sinavlari</h3>
        </a>

        <a
          href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2015%2FOYUN2015-FinalPuanlar.pdf?alt=media&token=55bc7300-729a-4065-a1d6-2f90354fb516"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>Puanlar</h3>
        </a>

        <a
          href="http://oyundergisi.tzv.org.tr/oyun2015galeri/"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>Resimler</h3>
        </a>
      </div>
    </div>
  );
}
