import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";
import { Button } from "@mui/material";

const pdfFiles = [
  {
    category: "Minikler",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FMinikler%20Kategorisi.pdf?alt=media&token=526ebaa8-7ab8-4fc6-8ca7-fd85264293c0",
  },
  {
    category: "Çocuklar",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2F%C3%87ocuklar%20Kategorisi.pdf?alt=media&token=1550520d-4bac-49ad-9925-d41a90415475",
  },
  {
    category: "Gençler",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FGen%C3%A7ler%20Kategorisi.pdf?alt=media&token=9e3a2e3c-8090-4258-8beb-d68407a4d320",
  },
  {
    category: "Yetişkinler",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FYeti%C5%9Fkinler%20Kategorisi.pdf?alt=media&token=d1269f3a-a2cb-4fae-99d0-a007a6ec7054",
  },
  {
    category: "Emektarlar",
    pdfUrl:
      "https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2022%2Fresult_pdfs%2FEmektarlar%20Kategorisi.pdf?alt=media&token=89a5df78-8cd1-4682-a1de-dd3c212d2e7c",
  },
];

export default function Year2022() {
  return (
    <div className="history2021">
      <div>
        <h1>Türkiye 27. Zeka Oyunları Yarışması</h1>
        <h2>
          <strong>OYUN 2022’nin Sonuçları Açıklandı!</strong>
        </h2>
      </div>

      <div>
        <div className="iframeContainerContainer">
          <div className="iframeContainer">
            <iframe
              className="responsive-iframe"
              src="https://www.youtube.com/embed/o3Bo7FrR8Is"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <h3 style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye 27. Zeka Oyunları Yarışması “Oyun 2022” için farklı yaş
          gruplarından yarışmacılar bu sene de OYUN'daydılar.
        </h3>
        <p style={{ textAlign: "left" }}>
          Sözel, sayısal, görsel ve mantık alanlarında orijinal zekâ sorularının
          yer aldığı Oyun 2022; “Emektarlar”, “Yetişkinler”, “Gençler”,
          “Çocuklar” ve “Minikler” olmak üzere beş kategoride gerçekleşti.
        </p>
        <p style={{ textAlign: "left" }}>
          Yarışmaya, okuma yazma ve dört işlem becerisine sahip, zeka sorularına
          meraklı dileyen herkes oyun.tzv.org.tr adresi üzerinden katılım
          sağladı.
        </p>
        <p style={{ textAlign: "left" }}>
          30 Mayıs'a kadar süren Eleme Sınavı’ndan sonra yarışmacılar, 2 Temmuz
          2022'de Yarı Final Sınavı'na, 13 Ağustos 2022'de ise Final Sınavı'na
          oyun.tzv.org.tr adresi üzerinden katıldı.
        </p>
        <p style={{ textAlign: "left" }}>
          Ödül Töreni; 4 Ekim 2022 Salı günü, Türkiye Zeka Vakfı Başkanı Emrehan
          Halıcı'nın konuşması ve tüm kategorilerde derece alan yarışmacılardan
          gelen videoların yayınlanması ile sanal bir tören olarak Youtube
          kanalında gerçekleştirildi. Emrehan Halıcı konuşmasında yarışmaya
          katılan kişileri tebrik ederken; sağlıklı beyinlere sahip olmak için
          tıpkı sağlıklı bedenlerde olduğu gibi egzersiz yapmaya ihtiyaç
          olduğuna değindi ve yarışmanın 27 yıldır bu amaca hizmet ettiğini
          belirtti. Ödül Töreni videosunda, yarışmada dereceye giren
          katılımcılardan gelen, yarışma ile ilgili duygu ve düşüncelerini
          anlattıkları videolar da yayınlandı.
        </p>

        <p style={{ textAlign: "left" }}>
          Her kategoride ilk 5 dereceye giren toplam 37 adaya, Türkiye Zeka
          Vakfı tarafından "Başarı Madalyası" verildi. Ayrıca, ilk 10'a giren
          toplam 71 kişi de TZV yarışma sayfasında “Şeref Kürsüsü”nde
          fotoğrafları ve kısa özgeçmişleri ile birlikte ilan edildi.
        </p>
      </div>

      <h3>FİNAL SINAVI SONUÇLARI</h3>
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {pdfFiles.map((p) => {
          return (
            <a
              style={{ textDecoration: "none" }}
              key={p.category + "_pdf_link"}
              href={p.pdfUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                style={{
                  backgroundColor: "white",
                  textTransform: "none",
                  width: 110,
                }}
                variant="outlined"
              >
                {p.category}
              </Button>
            </a>
          );
        })}
      </div>
    </div>
  );
}
