import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";

export default function Year2016() {
  const studentData = [
    {
      title: "14 Yaş Altı",
      photo: "https://i.hizliresim.com/bkZjzZ.jpg",

      rows: [
        ["1.Sabri Berkay Başar", 90],
        ["2.Kerem Adem", 89],
        ["3.Ayla Tataş", 88],
        ["4. Baturalp Taha Yilmaz", 77],
        ["4.Cumhur Aygar", 77],
        ["6.Osman Gülen", 77],
        ["7. Ramazan Yayla", 77],
        ["8.Çınar Kanbur", 77],
        ["9.Yağız Toprak Işık", 75],
        ["10.Taylan Aslan", 67],
      ],
    },
    {
      title: "14-21 Yaş Arası",
      photo: "https://i.hizliresim.com/VP8AmZ.jpg",
      rows: [
        ["1.Efe Alan", 100 + 91],
        ["1.Hakan Karakuş", 100 + 91],
        ["1.Mehmet Durmuş", 100 + 91],
        ["1.Muhammed Furkan Meydan", 100 + 91],
        ["1.Utku Gündoğan", 100 + 91],
        ["6. Edip Çiftçi", 100 + 64],
        ["7.Zeynep Eyiyol", 100 + 51],
        ["8.Mustafa Yetikcan", 100 + 14],
        ["9.Hatice Esra Aydemir", 89],
        ["10.Ekin Akoğuz", 89],
        ["10.Ufuk Demiray", 89],
      ],
    },
    {
      title: "21 Yaş Üstü",
      photo: "https://i.hizliresim.com/d8loyD.jpg",
      rows: [
        ["1.Nuri Yılmaz", 100 + 91],
        ["1.Salih Alan", 100 + 91],
        ["3.Fatih Kamer Anda", 90 + 91],
        ["4.Murat Koz", 89 + 91],
        ["5.Emre Öztürk", 89 + 12],
        ["6.Nejat Cingi", 89],
        ["7.Adil Yaşar Enis", 89],
        ["8.Hasan Uyar", 88],
        ["9.Baykar Silahlı", 88],
        ["10.Övül Arslan", 88],
      ],
    },
  ];

  return (
    <div>
      <h3>Türkiye 21.Zeka Oyunları Yarışması</h3>
      <h1 style={{ textAlign: "center", color: "#603083" }}>
        <strong>OYUN 2016’nın Birincileri Belli Oldu...</strong>
      </h1>
      <p style={{ textAlign: "center" }}>
        Kendi kategorilerinde en yüksek puanı alan yarışmacılar toplam{" "}
        <strong>24.000 TL</strong> ödülün sahibi oldu.
      </p>
      <div>
        <img
          src="https://i.hizliresim.com/Jq8pLW.jpg"
          width={890}
          alt="Ödül Töreninden görüntüler"
        />
        <p style={{ paddingTop: "40px", textAlign: "left" }}>
          Türkiye Zeka Vakfı tarafından; <strong>İş Bankası</strong>{" "}
          sponsorluğunda{" "}
          <strong>ODTÜ, TÜBİTAK ve Milli Eğitim Bakanlığı</strong> destekleriyle
          gerçekleştirilen Türkiye 21. Zeka Oyunları Yarışması{" "}
          <strong>OYUN 2016</strong>’nın final sınavı ve ödül töreni hafta sonu
          (25 Aralık 2016 Pazar) Ankara ODTÜ’de gerçekleştirildi.
        </p>

        <p style={{ textAlign: "left" }}>
          Ağustos ayında ön eleme sınavı ile başlayan maratonun son ayağı olan
          Final Sınavı’nda 554 finalist yarıştı. 100 dakika süren yazılı sınav 3
          kategoride değerlendirildi. 1’i yedek, 5’i ortak toplam 11 soruyu, her
          yıl olduğu gibi bu yıl da Türkiye Zeka Vakfı Başkanı Emrehan Halıcı
          hazırladı.
        </p>

        <p style={{ textAlign: "left" }}>
          OYUN 2016 kapsamında sınav sonrası renkli etkinlikler de yapıldı;
          Türkiye’nin ilk kadın satranç ustası olan Nilüfer Çınar’ın
          gerçekleştirdiği ve 20 kişiye karşı oynadığı simültane satranç
          gösterisi ile uluslararası alanda bir vurmalı çalgılar üstadı olan
          Gerçek Dorman’la Ritmik Zeka atölyesi katılımcıların yoğun ilgisini
          gördü.
        </p>

        <p style={{ textAlign: "left" }}>
          Aynı gün yapılan değerlendirmeyle belirlenen en iyiler için düzenlenen
          Ödül Törenine Türkiye Zeka Vakfı Başkanı Emrehan Halıcı’nın yanısıra
          Milli Eğitim Bakan Yardımcısı Orhan Erdem, Türkiye Satranç Federasyonu
          Başkanı Gülkız Tülay, ODTÜ Rektör Yardımcısı Prof. Dr. Gönül Sayan ve
          İş Bankası Kurumsal İletişim Bölümü Müdür Yardımcısı Müge Nevşehirli
          Veziroğlu katıldı.
        </p>

        <p style={{ textAlign: "left" }}>
          İki kategoride 11 soruya da doğru cevap vererek birinciliği ve 24 bin
          TL ödülü paylaşan yarışmacılar törende renkli görüntüler oluşturdular.
        </p>

        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          {studentData.map((group) => {
            return <LeaderBoard key={group.title} data={group} />;
          })}
        </div>

        <p style={{ textAlign: "left" }}>
          <strong>Türkiye Zeka Vakfı</strong>&nbsp;tarafından&nbsp;
          <strong>21 yıldır</strong>&nbsp;düzenlenen,
          <strong>&nbsp;İş Bankası</strong>&rsquo;nın sponsorluğunda{" "}
          <strong>ODTÜ</strong>, <strong>TÜBİTAK</strong> ve{" "}
          <strong>Milli Eğitim Bakanlığı</strong> destekleriyle
          gerçekleştirilen&nbsp;Türkiye Zeka Oyunları Yarışması OYUN
          2016&rsquo;in final sınavı hafta sonu (25 Aralık 2016, Pazar) ODTÜ
          İşletme Bölümü’nde gerçekleştirildi.{" "}
        </p>
        <p style={{ textAlign: "left" }}>
          Geçtiğimiz aylarda ilk aşama olan Eleme Sınavı ve elektronik ortamda
          gerçekleştirilen Yarı Final sınavını başarıyla geçen{" "}
          <strong>554</strong> yarışmacı en zorlu sınavda; final aşamasında
          yarışmaya hak kazandı. Yarışmacılara 11 soru yöneltildi. Zeka Oyunları
          Yarışması&rsquo;nın tüm soruları Türkiye Zeka Vakfı Başkanı{" "}
          <strong>Emrehan Halıcı</strong> tarafından hazırlanıyor.{" "}
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            Birinciler günlük yaşamlarında da zeka oyunlarına ilgili…
          </strong>
        </p>
        {/* <p><strong>14 yaş altı</strong>&nbsp;kategoride&nbsp;90&nbsp;puanla <strong>Sabri Berkay Başar</strong>, <strong>14-21&nbsp;yaş</strong> kategorisinde&nbsp;100 +91&nbsp;puanla&nbsp;<strong>Efe Alan, Hakan Karakuş, Mehmet Durmuş, Muhammet Furkan Merdan, Utku Gündoğan</strong>,&nbsp;<strong>21 yaş üstü</strong>&nbsp;kategorisinde&nbsp;100 +91&nbsp;puanla&nbsp;<strong>Nuri Yılmaz ve Salih Alan</strong>&nbsp;birinci oldular.<br /><br /> */}
        <p style={{ textAlign: "left" }}>
          <strong>
            Oyun 2016’dan dikkat çeken istatistikler; En küçük yarışmacı 6, en
            yaşlı yarışmacı 71 yaşındaydı.
          </strong>
        </p>
        <p style={{ textAlign: "left" }}>
          Yarışmaya 20126 kişi kayıt oldu. Eleme sınavına 4064 kişi katıldı.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>Kadınlar erkeklerden daha başarılı…</strong>
          <br />
          Kadınların başarı oranı %33 iken, erkeklerde bu oran %28’ydı.
        </p>
        <p style={{ textAlign: "left" }}>
          Birçok ilden kayıt alan yarışmaya en çok katılım İstanbul, Ankara,
          İzmir, Antalya, Kocaeli, Bursa, Gaziantep illerinden oldu. Katılım
          sayılarına göre en yüksek başarı oranını ise Ardahan, Gümüşhane,
          Edirne, Burdur illeri gösterdi. Sınava ağırlıklı katılım gösteren
          meslek grupları ise öğrenciler, mühendisler ve öğretmenler oldu.
          Onları doktorlar ve akademisyenler izledi.
        </p>
        <p style={{ textAlign: "left" }}>
          Her yıl binlerce kişinin yarıştığı Türkiye Zeka Oyunları Yarışması’na;
          yaş, cinsiyet ve eğitim gibi sınırlamalar olmadan zekâsına güvenen
          herkes katılabiliyor. Amaç; insanların düşünme alışkanları ve
          yeteneklerini geliştirmeye katkıda bulunmak...
        </p>
      </div>

      <div>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2016%2Foyun2016-finalpuanlistesi.pdf?alt=media&token=9c00dc9e-5b25-48c0-ba7e-d0b772b816ba"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>FİNAL SINAVI PUAN LİSTESİ</h3>
        </a>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2016%2Foyun2016-finalcevaplar.pdf?alt=media&token=c340566b-ec12-40b7-8d18-eda6354db07d"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>FİNAL SINAVI CEVAPLARI</h3>
        </a>

        <a
          href="http://oyundergisi.tzv.org.tr/oyun2016galeri/"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>FİNAL SINAVI RESİMLERİ</h3>
        </a>
      </div>
    </div>
  );
}
