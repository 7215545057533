import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";

export default function Year2017() {
  const studentData = [
    {
      title: "Minikler",
      rows: [
        ["1. Su Ece Boztepe", 69 + 3 + 1],
        ["2. Yağmur Kamacı", 69],
        ["3. Zeynep Arat", 69],
        ["4. Zeynep Feyza Akça", 59],
        ["5. Hasan Agah Yetkin", 57],
        ["6. Betül Polat", 57],
        ["7. Tarık Karcı", 55],
        ["8. Erdem Akçalı", 52 + 0 + 2],
        ["9. Yiğit Ali Aslan", 52],
        ["10. Eylül Nisa Çetiner", 51],
      ],
    },
    {
      title: "Çocuklar",
      rows: [
        ["1. Tunahan Turgut", 100 + 5 + 4],
        ["2. Kıvanç Toper", 100 + 5 + 3],
        ["3. Gülşen Naz Başer", 100 + 5 + 2],
        ["4. Osman Gülen", 100 + 4 + 5],
        ["5. İren Şerbetçioğlu", 100 + 3 + 2],
        ["6. Kaan Pide", 100 + 2 + 2],
        ["7. Yaman Özdoğan", 100 + 2 + 1],
        ["8. Yusuf Ziya Çetinkaya", 100 + 1 + 0],
        ["9. Eylül Güler", 100 + 0 + 2],
        ["10. Çınar Eren Arslan", 94],
      ],
    },
    {
      title: "Gençler",
      rows: [
        ["1. Gürkan İnal", 89 + 5 + 5],
        ["2. Doğa Deniz Dikici", 89 + 4 + 2],
        ["3. Mesut Melih Akpınar", 85 + 2 + 1],
        ["4. İbrahim Suat Evren", 85 + 1 + 1],
        ["5. Bahar Açılan", 85],
        ["5. Talha Tarı", 85],
        ["7. Mert Eren", 83 + 3 + 3],
        ["8. Mehmet Emin İpekdal", 83 + 3 + 2],
        ["9. Vahit Alp Hıdıroğlu", 79],
        ["10. Baran Kırdar", 77],
      ],
    },
    {
      title: "Yetişkinler",
      rows: [
        ["1. Murat Koz", 100],
        ["2. Cihan Altay", 89],
        ["3. Mustafa Karatutlu", 85 + 0 + 1],
        ["4. Güneş Bayır", 85],
        ["5. Mehmet Eren Durlanık", 83 + 1 + 2],
        ["6. Ekrem Özkan", 83 + 1 + 0],
        ["7. Burak Özer", 80],
        ["8. Merve Kendirci", 79 + 1 + 0],
        ["9. Ilgar Veryeri", 79],
        ["10. Fatih Ceylan ve Salih Alan", 76 + 5 + 3],
      ],
    },
  ];

  return (
    <div>
      <h3>Türkiye 22.Zeka Oyunları Yarışması</h3>
      <h1>
        <strong>OYUN 2017’nin Birincileri Belli Oldu...</strong>
      </h1>
      <p>
        <strong>
          Yarışmada derece alanlar toplam 30.000 TL’lik ödülün sahibi oldu.
        </strong>
      </p>
      <div>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye Zeka Vakfı tarafından bu yıl 22.si düzenlenen “Türkiye Zeka
          Oyunları Yarışması <strong>OYUN 2017</strong>’nin final sınavı ve ödül
          töreni 15 Mayıs 2017 Pazar Günü Ankara ODTÜ İşletme Bölümü’nde
          gerçekleştirildi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>Her ilden her yaştan toplam 29.584 yarışmacı adayı...</strong>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>Türkiye İş Bankası</strong> ana sponsorluğunda{" "}
          <strong>ODTÜ, TÜBİTAK ve MEB</strong> destekleriyle gerçekleştirilen
          Oyun 2017; Minikler, Çocuklar, Gençler ve Yetişkinler kategorilerinde
          üç aşamada yapıldı. Her ilden başvurunun olduğu yarışmaya 29.584 kişi
          kayıt oldu. Online olan ilk iki aşamada yarışan 5541 kişiden final
          sınavına katılmaya hak kazanan yarışmacı sayısı 739’du.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          ’Türkiye Zeka Oyunları Yarışması <strong>OYUN 2017</strong>’nin yazılı
          olarak yapılan ve 90 dakika süren Final Sınavında yarışmacılara 2’si
          eşitlik bozmak üzere toplam 12 soru yöneltildi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Kategori birincileri 4’er bin TL’lik ödüllerini <strong>MEB</strong>{" "}
          Bakan Yardımcısı Orhan Erdem, <strong>Türkiye İş Bankası</strong>{" "}
          Kurumsal İletişim Müdürü Suat Sözen, <strong>ODTÜ</strong> Rektör
          Yardımcısı Gönül Sayan ve <strong>Türkiye Zeka Vakfı</strong> Başkanı
          Emrehan Halıcı’dan alırken, her kategorinin ikincisine 2 bin,
          üçüncüsüne 1.5 biner TL ödül verildi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Törende konuşma yapan Milli Eğitim Bakan Yardımcısı Orhan Erdem,
          <strong>
            &quot;Artık gelecek ve sermaye zekada ve bireyde. Bugün bir
            bilgisayar yazılımı için 100-150 milyar dolarlar konuşuluyor.&quot;
          </strong>{" "}
          dedi.
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>
            Birbirinden zor soruları çözerek birinci olan yarışmacılar şöyle;
          </strong>
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>Minikler’de</strong>; 69 puan ve ek sorulardan aldığı 4 puanla
          <strong>Su Ece Boztepe,</strong> <br></br>
          <strong>Çocuklar’da</strong> 100 +9 puanla
          <strong>Tunahan Turgut,</strong> <br></br>
          <strong>Gençler’de</strong> 89 +10 puanla
          <strong>Gürkan İnal,</strong> <br></br>
          <strong>Yetişkinler’de</strong> 100 puanla <strong>Murat Koz</strong>
          <br></br>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Zeka Oyunları Yarışması’nın tüm soruları yurt içinde ve yurt dışında
          binlerce zeka sorusunun yazarı olan Türkiye Zeka Vakfı Başkanı Emrehan
          Halıcı tarafından hazırlanıyor.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>
            En zorlu aşama olan Final Sınavı’nda Oyun 2017’den dikkat çeken
            istatistikler;
          </strong>
        </p>
        <ul style={{ paddingTop: "20px", textAlign: "left" }}>
          <li>En küçük yarışmacı 5, en büyük yarışmacı 74 yaşında.</li>
          <li>
            En çok katılımın sağlandığı ilk 5 şehir; İstanbul, Ankara, İzmir,
            Antalya, Kocaeli.
          </li>
          <li>
            En çok katılım sağlayan meslek grupları sırasıyla; Öğrenci, Bankacı,
            Mühendis, Öğretmen, Doktor.
          </li>
          <li>
            Eleme sınavına katılanların 3550’sini erkekler oluştururken bu sayı
            kadınlarda 1991.
          </li>
        </ul>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          <strong>
            OYUN 2017 kapsamında sınav sonrası renkli etkinlikler de yapıldı;
          </strong>
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Final sınavının yanı sıra davetli konuşmacılar ve çeşitli atölye
          çalışmalarıyla gün boyu süren etkinliklerde; ODTÜ Matematik Bölümü
          Öğretim Üyesi Doç.Dr. Özgür Kişisel{" "}
          <strong>
            &quot;Zeka Oyunları, Matematik ve Diğer İlginç Şeyler&quot;
          </strong>
          ; Atılım Üniversitesi Öğretim Üyesi Yrd. Doç. Dr. Fatih Sulak{" "}
          <strong>&quot;Bir Strateji Oyunu: Go&quot;</strong> başlığıyla
          konuşurken, JazzBerry Tunes (Çok Sesli Koro){" "}
          <strong>&quot;Mentallegro Atölyesi&quot;</strong>nde katılımcılarla
          interaktif buluşmalar gerçekleştirdi.
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Her yıl binlerce kişinin yarıştığı Türkiye Zeka Oyunları Yarışması’na;
          yaş, cinsiyet ve eğitim gibi sınırlamalar olmadan zekâsına güvenen
          herkes katılabiliyor. Amaç; insanların düşünme alışkanları ve
          yeteneklerini geliştirmeye katkıda bulunmak...
        </p>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Yarışmanın başarı sağlayan isimlerine dair diğer detaylar ise şöyle;
        </p>
      </div>

      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {studentData.map((group) => {
          return <LeaderBoard key={group.title} data={group} />;
        })}
      </div>

      <div>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2017%2Foyun2017-finalliste.pdf?alt=media&token=2c9ee326-2288-47ee-ab4c-1a43e4647e3f
"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>Final Liste</h3>
        </a>

        <a
          href="https://firebasestorage.googleapis.com/v0/b/oyun-5302a.appspot.com/o/2017%2Foyun2017-finalcevaplar.pdf?alt=media&token=4270bcb0-2bc6-4ca7-b4c5-f3e82dbde6bf
 "
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}>Final Sinavlari</h3>
        </a>

        <a
          href="http://oyundergisi.tzv.org.tr/oyun2017galeri/"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}> FİNAL SINAVI RESİMLERİ</h3>
        </a>

        <a
          href="http://oyundergisi.tzv.org.tr/img/oyun2017final.html"
          target="_blank"
          rel="noreferrer"
        >
          <h3 style={{ textAlign: "center" }}> BÜLTEN</h3>
        </a>
      </div>
    </div>
  );
}
