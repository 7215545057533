import { Button, Container } from "@mui/material";

export const KVKK_DOC = ({ setOpenModal }) => {
  return (
    <Container
      sx={{
        textAlign: "left",
        padding: 5,
        backgroundColor: "white",
      }}
    >
      <p>
        6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca,
        Vakfımız tarafından, Veri Sorumlusu sıfatıyla, kişisel verileriniz, iş
        amaçlarıyla bağlı olarak, aşağıda açıklandığı çerçevede kullanılmak,
        kaydedilmek, saklanmak, güncellenmek, aktarılmak ve/veya
        sınıflandırılmak suretiyle işlenecektir. Bu kapsamda Vakfımız tarafından
        başta özel hayatın gizliliği olmak üzere, kişilerin temel hak ve
        özgürlüklerini korumak ve kişisel verilerin korunması amacıyla
        düzenlenen Kanun ve Yönetmelikler gereğince Vakfımız, kişisel
        verilerinizin hukuka aykırı olarak işlenmesini önleme, hukuka aykırı
        olarak erişilmesini önleme ve muhafazasını sağlama amacıyla, uygun
        güvenlik düzeyini temin etmeye yönelik tüm teknik ve idari tedbirleri
        almaktadır. Bu metnin hedef kitlesi Vakfımız tarafından kişisel verileri
        işlenen tüm gerçek kişilerdir. Veri sorumlusu sıfatıyla işlenen kişisel
        verilere, burada belirtilenlerle sınırlı sayıda olmamak üzere aşağıda
        yer verilmektedir;
      </p>
      <p>
        {" "}
        <b>Kişisel verilerin işlenme amaçları ve hukuki sebepleri</b>
      </p>
      <p>Tarafınızca paylaşılan kişisel verileriniz;</p>
      <li>
        Vakfımız tarafından sunulan hizmetlerden sizleri ve/veya temsil
        ettiğiniz kurum ve kuruluşları faydalandırmak için, Vakfımız ticari ve
        iş stratejilerinin belirlenmesi ve uygulanması, pazarlama
        faaliyetlerinin yapılması, iş geliştirme ve planlama faaliyetlerinin
        gerçekleştirilmesi dahil ve fakat bunlarla sınırlı olmamak üzere gerekli
        çalışmaların yürütülmesi,
      </li>
      <li>
        Vakfımız tarafından yürütülen iletişime yönelik idari operasyonların
        yürütülmesi,
      </li>
      <li>Vakfımız internet sayfasının kullanılması</li>
      ve/veya
      <li>
        Vakfımız düzenlediği eğitim, seminer, yarışma, sınav vb.
        organizasyonlara katılım sağlanması amacıyla işlenebilmektedir.{" "}
      </li>
      <br></br>
      <b>Kişisel verilerin toplanma ve saklanma yöntemi</b>
      <p>
        Vakfımızla paylaştığınız kişisel verileriniz, otomatik ya da otomatik
        olmayan yöntemlerle, ofisler, şubeler, çağrı merkezi, internet sitesi,
        sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla sözlü,
        yazılı ya da elektronik olarak toplanabilir. Kişisel verileriniz
        elektronik ve/veya fiziksel ortamlarda saklanacaktır. Vakfımız
        tarafından temin edilen ve saklanan kişisel verilerinizin saklandıkları
        ortamlarda yetkisiz erişime maruz kalmamaları, manipülasyona
        uğramamaları, kaybolmamaları ve zarar görmemeleri amacıyla gereken iş
        süreçlerinin tasarımı ile teknik güvenlik altyapı geliştirmeleri
        uygulanmaktadır.
      </p>
      <p>
        Kişisel verileriniz, size bildirilen amaçlar ve kapsam dışında
        kullanılmamak kaydı ile gerekli tüm bilgi güvenliği tedbirleri de
        alınarak işlenecek ve yasal saklama süresince veya böyle bir süre
        öngörülmemişse işleme amacının gerekli kıldığı süre boyunca saklanacak
        ve işlenecektir. Bu süre sona erdiğinde, kişisel verileriniz silinme,
        yok edilme ya da anonimleştirme yöntemleri ile Vakfımız veri
        akışlarından çıkarılacaktır.{" "}
      </p>
      <b> Kişisel Verilerin aktarılması </b>
      <p>
        Kişisel verileriniz, Kanunlar ve sair mevzuat kapsamında ve açıklanan
        amaçlarla;
      </p>
      <li>
        Yetki vermiş olduğumuz, Vakfımız nam ve hesabına faaliyette bulunan
        temsilcilerimize,
      </li>
      <li>
        Düzenleyici ve denetleyici kurumlara, kişisel verilerinizi tabi olduğu
        kanunlarında açıkça talep etmeye yetkili olan kamu kurum veya
        kuruluşlara,
      </li>
      <li>
        Yasal takip süreçleri ile ilgili zorunlu kişilere, kurum ve kuruluşlara
        ve denetimciler de dâhil olmak üzere danışmanlık aldığımız üçüncü
        kişilere ve bunlarla sınırlı olmaksızın, yurt içinde ve yurt dışında,
        yukarıda belirtilen amaçlarla iş ortakları, hizmet alınan üçüncü kişi,
        yetkilendirilen kişi ve kuruluşlara aktarılabilecektir.
      </li>
      <br></br>
      <b>KVKK’nın 11. maddesi gereği haklarınız</b>
      <p> Vakfımıza başvurarak, kişisel verilerinizin;</p>
      <li>İşlenip işlenmediğini öğrenme,</li>
      <li>İşlenmişse bilgi talep etme,</li>
      <li>
        İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,{" "}
      </li>
      <li>Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,</li>
      <li> Eksik / yanlış işlenmişse düzeltilmesini isteme, </li>
      <li>
        KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok
        edilmesini isteme,
      </li>
      <li>
        Aktarıldığı 3. kişilere yukarıda sayılan (e) ve (f) bentleri uyarınca
        yapılan işlemlerin bildirilmesini isteme,{" "}
      </li>
      <li>
        Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize
        bir sonucun ortaya çıkmasına itiraz etme,{" "}
      </li>
      <li>
        Kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde
        zararın giderilmesini talep etme haklarına sahipsiniz.{" "}
      </li>
      <p>
        KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda belirtilen
        haklarınızı kullanmak ile ilgili talebinizi, yazılı olarak veya Kişisel
        Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle Vakfımıza
        iletebilirsiniz.{" "}
      </p>
      <p>
        Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici
        gerekli bilgiler ile talep dilekçenizi bizzat elden teslim edebilir,
        noter kanalıyla veya Kişisel Verileri Koruma Kurulu tarafından
        belirlenen diğer yöntemler ile gönderebilir veya <b>info@tzv.org.tr </b>{" "}
        adresine güvenli elektronik imzalı olarak iletebilirsiniz.
      </p>
      <Button
        variant="contained"
        sx={{ width: "100%" }}
        color="secondary"
        onClick={() => setOpenModal(false)}
      >
        Anladım
      </Button>
    </Container>
  );
};
