import React from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
function HallOfFameUserModal({ selectedUser, onModalClose }) {
  return (
    <Modal
      scroll="paper"
      open={true}
      onClose={onModalClose}
      className="hallOfFame"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        overflow: "scroll",
      }}
    >
      <div className="modalBackground">
        <div className="modalContainer">
          <img
            src={"/assets/images/medals/md-" + selectedUser.medal + ".png"}
            alt="griceviz"
            className="modalMedal"
          />
          <div
            className="modalImageContainer"
            style={{ height: selectedUser.photo ? "" : 400 }}
          >
            {selectedUser.photo && (
              <img
                src={selectedUser.photo}
                alt={selectedUser.name}
                style={{ borderRadius: 12, width: "100%" }}
              />
            )}
          </div>

          <div style={{ position: "relative" }}>
            <div className="modalResult"> {selectedUser.result} </div>
            <div className="modalName">
              {selectedUser.name.toLocaleLowerCase()}
            </div>
            <div className="modalGhost"></div>
          </div>

          <div
            className="modalAbout"
            style={{ whiteSpace: selectedUser.whiteSpace }}
          >
            {" "}
            {selectedUser.about2}
          </div>
          <div>
            <Button
              style={{ marginTop: 15 }}
              onClick={onModalClose}
              variant="outlined"
              size="small"
            >
              Kapat
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HallOfFameUserModal;
