import "./loadingBlock.css";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingBlock() {
  return (
    <div className="container">
      <CircularProgress />
    </div>
  );
}
