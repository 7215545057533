import LeaderBoard from "../../components/organisms/leaderBoard/leaderBoard";

export default function Year2020() {
  const studentData = [
    {
      title: "Minikler",
      rows: [
        ["1. Ömer Fevzi Büyükadam", 98.5],
        ["2.  Bora Sipahi", 90.6],
        ["3. Sarp Tuna Utalay", 70.0],
        ["4.Ömer Yahya Memişbey", 60.8],
        ["5.Eda Defne Tan", 60.0],
        ["5. Dorukhan Zor", 52.3],
        ["7.Beren Zehra Güllü", 50.0],
        ["8.Ali Kuzey Gülhan", 50.0],
        ["8.Tunç Arda Çankaya", 50.0],
        ["8.Kuzey Akman", 50.0],
      ],
    },
    {
      title: "Çocuklar",
      rows: [
        ["1. Barış Nişancı", 98.5],
        ["2.Tuğra Özbey Eratlı", 98.3],
        ["3. Murat Enes Topaloğlu", 94.5],
        ["4.Çağlayan Dinçtürk", 93.8],
        ["5.Rukiye Defne Özdemir", 93.4],
        ["6.Rahimenur Bayram", 90],
        ["6.Boran Ege Kutanoğlu", 90],
        ["8.Bora Erdogan", 88.5],
        ["9.Ali Alper Bilen", 87.7],
        ["10.Alp Aydın", 87.5],
      ],
    },
    {
      title: "Gençler",
      rows: [
        ["1. Kadir Kaan Erbaş", 88.5],
        ["1. Mehmet Can Baştemir", 88.5],
        ["1. Efe Kaan Keremoğlu", 88.5],
        ["4. Mete Gökhan Coşkun", 88.3],
        ["5. Gürkan İnal", 86.6],
        ["6. Hakan Gökdoğan", 85.3],
        ["6. Ege Tarık Sağıroğlu", 85.3],
        ["8. Salih Gökmen Baykan", 84.9],
        ["8. Melis Aydın", 84.9],
        ["8. Hamza Geldi", 84.9],
        ["8. Alperen Çokyaşar", 84.9],
        ["8. Ceren Evcil", 84.9],
      ],
    },
    {
      title: "Yetişkinler",
      rows: [
        ["1. Muhammed Ali Büyükadam", 108.5],
        ["1. Hasan Yaldız", 108.5],
        ["1. Hasan Büyükadam", 108.5],
        ["1. Yılmaz Onat Köylüoğlu", 108.5],
        ["1. Batuhan Atalay", 108.5],
        ["6. Ufuk Demiray", 104.5],
        ["7. Elvin Ay", 98.5],
        ["7. Muharrem Şekeroğlu", 98.5],
        ["7. Fatih Kamer Anda", 98.5],
        ["7. Mustafa Ege Şeker", 98.5],
        ["7. Beril Ay", 98.5],
        ["7. Tamer Kilan", 98.5],
        ["7. Funda Kartaloğlu", 98.5],
      ],
    },
    {
      title: "Emektarlar",
      rows: [
        ["1. İsmail Üşümezoğlu", 88.5],
        ["2. Erol Akın", 80.0],
        ["3. Aytunç Üşümezoğlu", 78.5],
        ["3. Şenol Toygar", 78.5],
        ["5. Fulya Oral", 70.2],
        ["6. Orhan Nişancı", 68.5],
        ["7. Reyhan Ergün", 60.0],
        ["8. Mustafa Başak", 57.0],
        ["9. Mehmet Zirek", 50.0],
        ["9. Ünal Adnan Güler", 50.0],
        ["9. Murat Koz", 50.0],
        ["9. Metin Örsel", 50.0],
      ],
    },
  ];

  return (
    <div className="history2021">
      <div>
        <h3>Türkiye 26. Zeka Oyunları Yarışması</h3>
        <h1>
          <strong>OYUN 2020’in Sonuçları Açıklandı!</strong>
        </h1>
        <p>
          <strong>
            Bu sene 26.’sı düzenlenen Türkiye Zeka Oyunları Yarışması “Oyun
            2020”in sonuçları, 17 Haziran 2021 Perşembe günü ilan edildi.
          </strong>
        </p>
        <p>
          <strong>
            Sonuçların ilanı, pandemi koşulları nedeniyle sanal törenle
            gerçekleştirildi.
          </strong>
        </p>
      </div>

      <div>
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/moMy0SyqKWQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        >
          {" "}
        </iframe>
        <h2 style={{ paddingTop: "20px", textAlign: "left" }}>
          Her İlden, Beş Kategoriden Katılım
        </h2>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Türkiye 26. Zeka Oyunları Yarışması “Oyun 2021” için, 81 ilden farklı
          yaş gruplarından yarışmacılar “Çık dışarıya oynayalım, dediğimiz
          günler gelene kadar evlerden Oyun’a devam!” dedi. “Emektarlar”,
          “Yetişkinler”, “Gençler”, “Çocuklar” ve “Minikler” olmak üzere beş
          kategoride gerçekleşen Yarışma’ya katılan yaşça en küçük yarışmacı 4;
          en büyük yarışmacı ise 80 yaşında.
        </p>
        <h2 style={{ paddingTop: "20px", textAlign: "left" }}>
          Pandemi ve “Oyun 2020”
        </h2>
        <p style={{ paddingTop: "20px", textAlign: "left" }}>
          Gelenek bozulmadı, “Oyun 2020” Pandemi sürecinin gerektirdiği yasaklar
          dikkate alınarak düzenlendi.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          8 Şubat’tan 21 Mart’a kadar süren Eleme Sınavı’ndan sonra 24 Nisan’da
          Yarı Final Sınavı, 29 Mayıs’ta ise Final Sınav oyun.tzv.org.tr
          adresinde gerçekleşti.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          Ödül Töreni ise 17 Haziran 2020 Perşembe günü, Millî Eğitim Bakanı
          Sayın Ziya Selçuk, Türkiye Zeka Vakfı Başkanı Emrehan Halıcı ve
          Türkiye Zeka Vakfı Yönetim Kurulu Üyesi Dr. Şeref Oğuz’un konuşma
          yaptığı video ile sanal olarak düzenlendi.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          Bakan Selçuk, 26 yıldır yarışma sorularını hazırlayan Emrehan Halıcı
          ve Şeref Oğuz konuşmalarında katılımcıları tebrik ederken yarışmanın
          takip eden yıllarda sağlıklı koşullarda mutlulukla ve yüz yüze
          gerçekleşmesi dileklerinde de bulundular.
        </p>
        <p style={{ paddingTop: "10px", textAlign: "left" }}>
          Ödül Töreni videosunda, dereceye giren katılımcılar ilan edildi.
        </p>

        <h2 style={{ paddingTop: "10px", textAlign: "center" }}>
          Derece Alan Yarışmacılar
        </h2>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        {studentData.map((group) => {
          return <LeaderBoard key={group.title} data={group} />;
        })}
      </div>
    </div>
  );
}
