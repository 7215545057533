export const generateCategory = (birth_year) => {
  if (birth_year >= 2015) {
    return "Minikler";
  }
  if (birth_year >= 2010) {
    return "Çocuklar";
  }
  if (birth_year >= 2005) {
    return "Gençler";
  }
  if (birth_year >= 1974) {
    return "Yetişkinler";
  }
  if (birth_year <= 1973) {
    return "Emektarlar";
  }
};
